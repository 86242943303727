.button_su {
    overflow: hidden;
    position: relative;
    display: inline-block;
    border-radius: 10px;
    width: 100%;
    height: 55px;
}

.su_button_circle {
    background-color: red;
    border-radius: 1000px;
    position: absolute;
    left: 0;
    top: 0;
    width: 0px;
    height: 0px;
    margin-left: 0px;
    margin-top: 0px;
    pointer-events: none;
}

.button_su_inner {
    display: inline-block;
    font-size: 16px;
    font-weight: normal;
    width: 100%;
    min-height: 55px;
    text-align: center;
    border-radius: 10px;
    transition: 400ms;
    text-decoration: none;
    z-index: 100000;
    border: none;
    cursor: pointer;
}

/* Black variant */
.button_su_inner.black {
    background: #000;
    color: #fff;
}

.button_su_inner.black:disabled {
    background: #000;
    cursor: not-allowed;
    opacity: 0.7;
}

/* lightGray variant */
.button_su_inner.lightGray {
    background: #f7f7f7;
    color: #9a9b9b;
}

.button_su_inner.lightGray:disabled {
    background: #f7f7f7;
    cursor: not-allowed;
    opacity: 0.7;
}

/* sidebarVarient varient */
.button_su_inner.sidebarVarient {
    background-color: #fff;
    color: #000;
}

.button_su_inner.sidebarVarient.active {
    background-color: #10b700;
    color: #fff;
}

/* Green variant */
.button_su_inner.green {
    background: #10b700;
    color: #fff;
}

.button_su_inner.green:disabled {
    background: #0a8200;
    cursor: not-allowed;
    opacity: 0.7;
}

/* Red variant */
.button_su_inner.red {
    background: #ff0000;
    color: #fff;
}

.button_su_inner.red:disabled {
    background: #ff0000;
    cursor: not-allowed;
    opacity: 0.7;
}

.button_text_container {
    position: relative;
    z-index: 10000;
    color: #fff;
    font-family: "Graphik Medium", sans-serif;
    font-size: 14px;
}

.button_su_inner.sidebarVarient .button_text_container {
    color: #000;
    font-family: "Graphik Regular";
    transition: 0.3s all ease;
}

.button_su_inner.sidebarVarient:hover .button_text_container {
    color: #fff;
}

/* lightGray variant */
.button_su_inner.lightGray .button_text_container {
    color: #9a9b9b;
    font-family: "Graphik Medium";
    transition: 0.3s all ease;
}

.button_su_inner.lightGray:hover .button_text_container {
    color: #fff;
}

/* Animation classes */
.explode-circle.black {
    animation: explode-black 0.5s forwards;
}

.desplode-circle.black {
    animation: desplode-black 0.5s forwards;
}

.explode-circle.green {
    animation: explode-green 0.5s forwards;
}

.desplode-circle.green {
    animation: desplode-green 0.5s forwards;
}

.explode-circle.red {
    animation: explode-red 0.5s forwards;
}

.desplode-circle.red {
    animation: desplode-red 0.5s forwards;
}

.explode-circle.sidebarVarient {
    animation: explode-sidebarVarient 0.5s forwards;
}

.desplode-circle.sidebarVarient {
    animation: desplode-sidebarVarient 0.5s forwards;
}

.explode-circle.lightGray {
    animation: explode-sidebarVarient 0.5s forwards;
}

.desplode-circle.lightGray {
    animation: desplode-sidebarVarient 0.5s forwards;
}

/* Black variant animations */
@keyframes explode-black {
    0% {
        width: 0px;
        height: 0px;
        margin-left: 0px;
        margin-top: 0px;
        background-color: rgba(42, 53, 80, 0.2);
    }

    100% {
        width: 500px;
        height: 500px;
        margin-left: -250px;
        margin-top: -250px;
        background-color: #10b700;
    }
}

@keyframes desplode-black {
    0% {
        width: 500px;
        height: 500px;
        margin-left: -250px;
        margin-top: -250px;
        background-color: #10b700;
    }

    100% {
        width: 0px;
        height: 0px;
        margin-left: 0px;
        margin-top: 0px;
        background-color: rgba(129, 80, 108, 0.6);
    }
}

/* Green variant animations */
@keyframes explode-green {
    0% {
        width: 0px;
        height: 0px;
        margin-left: 0px;
        margin-top: 0px;
        background-color: rgba(42, 53, 80, 0.2);
    }

    100% {
        width: 500px;
        height: 500px;
        margin-left: -250px;
        margin-top: -250px;
        background-color: #000;
    }
}

@keyframes desplode-green {
    0% {
        width: 500px;
        height: 500px;
        margin-left: -250px;
        margin-top: -250px;
        background-color: #000;
    }

    100% {
        width: 0px;
        height: 0px;
        margin-left: 0px;
        margin-top: 0px;
        background-color: rgba(129, 80, 108, 0.6);
    }
}

/* sidebarVarient variant animations */
@keyframes explode-sidebarVarient {
    0% {
        width: 0px;
        height: 0px;
        margin-left: 0px;
        margin-top: 0px;
        background-color: rgba(42, 53, 80, 0.2);
    }

    100% {
        width: 500px;
        height: 500px;
        margin-left: -250px;
        margin-top: -250px;
        background-color: #11b700;
    }
}

@keyframes desplode-sidebarVarient {
    0% {
        width: 500px;
        height: 500px;
        margin-left: -250px;
        margin-top: -250px;
        background-color: #11b700;
    }

    100% {
        width: 0px;
        height: 0px;
        margin-left: 0px;
        margin-top: 0px;
        background-color: rgba(129, 80, 108, 0.6);
    }
}

/* Red variant animations */
@keyframes explode-red {
    0% {
        width: 0px;
        height: 0px;
        margin-left: 0px;
        margin-top: 0px;
        background-color: rgba(42, 53, 80, 0.2);
    }

    100% {
        width: 500px;
        height: 500px;
        margin-left: -250px;
        margin-top: -250px;
        background-color: #000;
    }
}

@keyframes desplode-red {
    0% {
        width: 500px;
        height: 500px;
        margin-left: -250px;
        margin-top: -250px;
        background-color: #000;
    }

    100% {
        width: 0px;
        height: 0px;
        margin-left: 0px;
        margin-top: 0px;
        background-color: rgba(129, 80, 108, 0.6);
    }
}

.button_content_container {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 22px;
    padding: 19px 30px;
    width: 100%;
    border-radius: 10px;
    /* Space between SVG and text */
    position: relative;
    z-index: 10000;
    font-family: "Graphik Regular";
    font-size: 14px;
    text-align: left;
    transition: 0.3s all ease;
}

.svg_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Optional: Adjust SVG size */
.svg_container svg {
    width: 25px;
    /* Adjust as needed */
    height: 25px;
    /* Adjust as needed */
    transition: 0.3s all ease;
}

.button_su_inner:hover .svg_container svg {
    fill: #fff;
}

/* Add these to your existing CSS */

.button_su_inner.dropdown {
    position: relative;
}

.button_content_container {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 22px;
    padding: 19px 30px;
    width: 100%;
    border-radius: 10px;
    position: relative;
    z-index: 10000;
    font-family: "Graphik Regular";
    font-size: 14px;
    text-align: left;
    transition: 0.3s all ease;
}

/* Dropdown arrow container */
.dropdown_arrow_container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    /* Pushes arrow to the right */
}

/* Dropdown arrow styling */
.dropdownArrow {
    transition: 0.3s all ease;
    opacity: 1;
}

.dropdownArrow.open {
    transform: rotate(180deg);
}

/* Ensure SVG and arrow colors match text */
.button_su_inner.black .dropdown_arrow_container svg,
.button_su_inner.green .dropdown_arrow_container svg,
.button_su_inner.red .dropdown_arrow_container svg {
    fill: #fff;
}

.button_su_inner.sidebarVarient .dropdown_arrow_container svg {
    fill: #000;
    transition: 0.3s all ease;
}

.button_su_inner.sidebarVarient:hover .dropdown_arrow_container svg {
    fill: #fff;
}

.button_su_inner.sidebarVarient .button_content_container:hover .dropdownArrow path {
    fill: #fff !important;
}

.button_su_inner.sidebarVarient .button_content_container:hover .memberSvg,
.button_su_inner.sidebarVarient .button_content_container.active .memberSvg {
    fill: #fff;
    /* stroke: #fff; */
}

.button_su_inner.sidebarVarient .button_content_container .memberInnerBody {
    transition: 0.2s all ease;
}

.button_su_inner.sidebarVarient .button_content_container:hover svg .memberInnerBody,
.button_su_inner.sidebarVarient .button_content_container.active svg .memberInnerBody {
    fill: #10b700;
    transition: 0.2s all ease;
}

.button_su_inner.sidebarVarient .button_content_container:hover .memberSvg path,
.button_su_inner.sidebarVarient .button_content_container.active .memberSvg path {
    stroke: #fff;
}

@media only screen and (max-width: 995px) {
    .button_su_inner.sidebarVarient:hover .dropdown_arrow_container svg {
        fill: #000;
    }
}

@media only screen and (max-width: 995px) {
    .explode-circle.sidebarVarient {
        animation: unset;
    }

    .desplode-circle.sidebarVarient {
        animation: unset;
    }

    .button_su_inner.sidebarVarient:hover .button_text_container {
        color: #000;
    }

    .button_su_inner:hover .svg_container svg {
        fill: #000;
    }
}