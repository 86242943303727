.memberStatBox {
    background-color: #fff;
    border-radius: 10px;
    padding: 44.6px 93.5px 62.6px 46px;
    margin-bottom: 30.2px;
}

.memberStatBox .statItem {
    /* text-align: center; */
    width: fit-content;
}

.memberStatBox .statItem .title {
    font-family: "Graphik Medium";
    font-size: 16px;
    color: #06152b;
    margin-bottom: 0;
    width: fit-content;
}

.memberStatBox .statItem .statNumber {
    font-family: "Graphik Medium";
    font-size: 70px;
    line-height: 70px;
    color: #101428;
    display: flex;
    align-items: end;
    justify-content: start;
    margin-bottom: 0;
    width: fit-content;
}

.memberStatBox .statItem .statNumber span {
    font-family: "Graphik Medium";
    font-size: 16px;
    color: #06152b;
    position: relative;
    bottom: -17px;
    width: fit-content;
}

.memberStatBox .statItem .subText {
    font-family: "Graphik Medium";
    font-size: 14px;
    color: #06152b;
    width: fit-content;
}

.memberStatBox .statItem .subText span {
    font-family: "Graphik Medium";
    font-size: 14px;
    color: #11B700;
}

@media only screen and (min-width: 946px) and (max-width: 1091px) {
    .memberStatBox .statItem .statNumber {
        font-size: 40px;
        line-height: 40px;
    }

    .memberStatBox .statItem .title {
        font-size: 14px;
    }

    .memberStatBox .statItem .statNumber span {
        bottom: -8px;
    }
}

@media only screen and (max-width: 992px) {
    .memberStatBox .statItem {
        margin-bottom: 39.4px;
    }

    .memberStatBox {
        padding-bottom: 83px;
    }

    .memberStatBox .col-lg-3:nth-child(3) .statItem,
    .memberStatBox .col-lg-3:nth-child(4) .statItem {
        margin-bottom: 0 !important;
    }
}

@media only screen and (max-width: 767px) {
    .memberStatBox .statItem {
        margin-bottom: 39.4px;
    }

    .memberStatBox .statItem .statNumber {
        font-size: 40px;
        line-height: 40px;
    }

        .memberStatBox .statItem .statNumber span {
            bottom: -8px;
        }

}

@media only screen and (max-width: 576px) {
    .memberStatBox .statItem .statNumber {
        font-size: 40px;
        font-family: "Graphik Medium";
        line-height: 40px;
    }

    .memberStatBox .statItem .statNumber span {
        font-size: 12px;
    }

    .memberStatBox .statItem .title {
        font-size: 14px;
        margin-bottom: 3.2px;
        font-family: "Graphik Regular";
    }

    .memberStatBox .statItem .subText {
        font-size: 12px;
        font-family: "Graphik Regular";
    }

    .memberStatBox .statItem .subText span {
        font-size: 12px;
        font-family: "Graphik Regular";
    }

}

@media only screen and (min-width: 1440px) {
    .memberStatBox {
        max-height: 220px;
    }
}