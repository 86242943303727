.allMemberBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 36.4px 37.3px 36.4px 46px;
}

.allMemberBox .mb-40px {
  margin-bottom: 40px;
}

.memberFilteDropdown .dropdown .dropdown-btn {
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 10px;
  border: 1px solid transparent;
  display: flex;
  font-family: "Graphik Medium";
  font-size: 14px;
  justify-content: space-between;
  min-height: 55px;
  padding: 10px 20px;
  width: 100%;
}

.memberFilteDropdown .dropdown .dropdown-btn:focus {
  background-color: #fff;
  border-color: #11b700;
}

.memberFilteDropdown .dropdown .dropdown-menu {
  background: #ffffff;
  border-color: #d6d6d6;
  border-style: solid;
  border-width: 0.5px;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
  left: -10px;
  margin: 0;
  padding: 22px 0;
  right: unset;
  top: 60px;
  width: 250px;
}

.memberFilteDropdown .dropdown .dropdown-menu li {
  transition: 0.3s all ease;
}

.memberFilteDropdown .dropdown .dropdown-menu li button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: #06152b;
  display: flex;
  font-family: "Graphik Medium";
  font-size: 14px;
  justify-content: space-between;
  padding: 11px 48.1px 11px 36.1px;
  transition: 0.3s all ease;
  width: 100%;
}

.memberFilteDropdown .dropdown .dropdown-menu li button:hover {
  background-color: #f1f1f1;
  font-family: "Graphik Bold";
  padding: 11px 23.1px 11px 36.1px;
  transition: 0.3s all ease;
}

.memberFilteDropdown .arrow {
  display: inline-block;
  opacity: 0.4;
  transition: transform 0.3s ease-in-out;
}

.memberFilteDropdown .rotate {
  opacity: 1;
  transform: rotate(180deg);
}

.memberSearchBox {
  margin: 0 auto;
}

.memberSearchBox .input-group span {
  background-color: #f7f7f7;
  border-color: #fff;
  border-radius: 10px;
  border-right-color: transparent;
  min-height: 55px;
  padding-left: 16px;
  padding-right: 12px;
}

.memberSearchBox .input-group .form-control {
  background-color: #f7f7f7;
  border-bottom-right-radius: 10px;
  border-color: #fff;
  border-left-color: transparent;
  border-top-right-radius: 10px;
  font-family: "Graphik Medium";
  font-size: 14px;
  min-height: 55px;
  padding-left: 0;
}

.memberMessageBox button {
  align-items: center;
  background-color: #f7f7f7;
  border-color: transparent;
  border-radius: 10px;
  color: #9a9b9b;
  display: flex;
  font-family: "Graphik Medium";
  font-size: 14px;
  gap: 10px;
  justify-content: center;
  min-height: 55px;
  width: 100%;
}

.memberMessageBox button svg {
  min-height: 15px;
}

.memberMessageBox button img {
  position: relative;
  top: -1px;
}

.memberMessageBox button img svg {
  fill: red;
}

.memberMessageBox button.active {
  background-color: #000;
  color: #fff;
}

.memberMessageBox button:hover {
  background-color: #11b700;
  color: #fff;
}

.memberMessageBox button:hover svg path {
  fill: #fff;
}

.membersTable thead tr th {
  font-family: "Graphik Regular" !important;
  font-size: 14px;
  padding: 24px 0;
  text-align: start;
}

.membersTable thead tr th {
  color: #5a6872 !important;
  font-weight: normal;
}

.membersTable tbody tr td {
  color: #000 !important;
}

.membersTable thead tr,
.membersTable tbody tr {
  border-color: #e2e2e2 !important;
}

.copyBtn {
  margin-left: 1rem;
}

.actionDropdown {
  position: relative;
  width: 200px;
}

.actionDropdown button {
  background-color: transparent;
  border: none;
}

.actionDropdown .dropdown-menu {
  background: #000;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  display: block;
  padding: 20px 0;
  position: absolute;
  right: 0;
  top: 60%;
  width: 150px;
  z-index: 1050;
}

.actionDropdown .dropdown-item {
  color: #ffffff;
  cursor: pointer;
  font-family: "Graphik Regular";
  font-size: 12px;
  transition: 0.3s all ease;
}

.actionDropdown .dropdown-item:focus,
.actionDropdown .dropdown-item:hover {
  background-color: #000;
  color: #ffffff;
  font-family: "Graphik Medium";
}

.membersTable .forMobile {
  display: none;
}

.membersTable .forDesktop {
  display: block;
}

.action-th {
  width: 1% !important;
}

.membersTable .forMobile .combieneBox {
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: start;
}

.membersTable .forMobile .title {
  color: #000000;
  font-family: "Graphik Regular";
  font-size: 12px;
  opacity: 0.5;
  text-align: left;
}

.membersTable .forMobile .des {
  color: #000000;
  font-family: "Graphik Regular";
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  text-align: left;
}

.forMobile .editEmailBox .form-control {
  border-radius: 12px;
  color: #000;
  color: #000000;
  font-family: "Graphik Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  min-height: 15px;
  text-align: left;
  width: 200px;
}

.forMobile .editEmailBox .form-control:focus {
  border-color: #11b700;
}

.UpdateButton {
  background-color: #10b700 !important;
  border-radius: 12px;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  height: 40px;
  text-align: center;
  width: 100px;
}

.CancelButton {
  background-color: red !important;
  border-radius: 12px;
  border: none;
  color: #ffffff;
  font-size: 14px;
  font-style: normal;
  height: 40px;
  text-align: center;
  width: 100px;
}

.forDesktop .form-control {
  border-radius: 12px;
  color: #000;
  color: #000000;
  font-family: "Graphik Regular";
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  min-height: 15px;
  text-align: left;
  width: 200px;
}

.forDesktop .form-control:focus {
  border-color: #10b700;
}

.desktopCancle {
  background-color: red !important;
  border-radius: 12px !important;
  border: none !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-style: normal !important;
  height: 45px !important;
  text-align: center !important;
  width: 170px !important;
}

.desktopSave {
  background-color: #10b700 !important;
  border-radius: 12px !important;
  border: none !important;
  color: #ffffff !important;
  font-size: 14px !important;
  font-style: normal !important;
  height: 45px !important;
  margin-left: auto;
  text-align: center !important;
  width: 170px !important;
}

.membersTable tr td {
  height: 90px;
  vertical-align: middle;
}

.membersTable .code-td {
  align-items: center;
}

.membersTable .email-th {
  width: 23% !important;
}

.membersTable .exp-th {
  width: 18% !important;
}

.membersTable .code-th {
  width: 14% !important;
}

.membersTable .statusInnerBox,
.membersTable .actionInnerBox {
  width: 200px;
}

.AllMemberMessageButton .button_content_container {
  gap: 15px;
  padding: 19px 13px;
}
.AllMemberMessageButton .svg_container svg {
  width: 20px;
  height: 15px;
}

@media only screen and (min-width: 1440px) {
  .memberSearchBox {
    width: 464.81px;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1400px) {
  .membersTable .emailTD .forDesktop {
    max-width: 250px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

@media only screen and (min-width: 946px) and (max-width: 1099px) {
  .membersTable .emailTD .forDesktop {
    max-width: 150px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .forDesktop .form-control {
    width: 150px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 946px) {
  .membersTable .emailTD .forDesktop {
    max-width: 150px !important;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .forDesktop .form-control {
    width: 150px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1290px) {
  .membersTable .statusInnerBox,
  .membersTable .actionInnerBox {
    width: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1099px) {
  .membersTable .statusInnerBox,
  .membersTable .actionInnerBox {
    width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .membersTable .statusInnerBox,
  .membersTable .actionInnerBox {
    width: 80px;
  }

  .membersTable .email-th {
    width: 20% !important;
  }
}

@media only screen and (max-width: 768px) {
  .membersTable .statusInnerBox,
  .membersTable .actionInnerBox {
    width: auto;
  }

  .membersTable thead tr th {
    height: 0;
    padding: 0;
  }
}

.exp-td .memberDropDown {
  align-items: center !important;
  background-color: #f7f7f7;
  border-color: transparent;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #000 !important;
  display: flex !important;
  font-family: "Graphik Regular" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  justify-content: start !important;
  line-height: 22px !important;
  min-height: 55px;
  padding: 12px 14px;
  text-align: left !important;
  width: 100% !important;
}

.exp-td .memberDropDown:hover {
  background-color: #f7f7f7;
}

.exp-td .memberDropDown:active,
.exp-td .memberDropDown:focus,
.exp-td .memberDropDown:focus-visible {
  background-color: #f7f7f7 !important;
  border-color: #10b700 !important;
  outline: 0;
}

.exp-td .dropdown .memberDropDown {
  align-items: center !important;
  color: #9a9b9b !important;
  display: flex !important;
  justify-content: space-between !important;
  overflow: hidden;
  width: 100% !important;
}

.exp-td .dropdown .memberDropDown:hover {
  border-color: transparent;
}

.exp-td .dropdown .memberDropDown:disabled,
.exp-td .dropdown .memberDropDown.show {
  background-color: #f7f7f7 !important;
  border-color: transparent;
}

.exp-td .dropdown .memberDropDown:focus-visible {
  box-shadow: none;
}

.exp-td .dropdown .memberDropDown.selected {
  color: #000 !important;
}

.exp-td .dropdown .dropdown-menu {
  left: -10px;
  padding: 10px 0;
  right: unset;
  top: 60px;
  width: 250px;
}

.exp-td .dropdown .dropdown-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 20px 20px;
}

.exp-td .dropdown .dropdown-item span {
  color: #06152b;
  font-family: "Graphik Regular";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  text-align: left;
}

@media only screen and (max-width: 992px) {
  .allMemberBox .mb-40px {
    margin-bottom: 21.1px;
  }
}

@media only screen and (max-width: 768px) {
  .messageModal .selectedEmail,
  .messageModal .subjectInput {
    width: 100% !important;
  }

  .messageModal .SendBtn {
    width: 100% !important;
  }

  .messageModal .modal-body {
    padding: 25px 20px 40px 20px !important;
  }

  .allMemberBox {
    padding: 36px 20px;
  }

  .membersTable .forMobile {
    display: block;
  }

  .membersTable .forDesktop {
    display: none;
  }

  .email-th {
    visibility: hidden;
  }

  .exp-td,
  .code-td,
  .status-td,
  .exp-th,
  .code-th,
  .status-th {
    display: none !important;
  }

  .actionDropdown {
    vertical-align: middle;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1290px) {
  .actionDropdown {
    width: 120px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1099px) {
  .actionDropdown {
    width: 100px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .actionDropdown {
    width: 80px;
  }
}

@media only screen and (max-width: 768px) {
  .actionDropdown {
    width: auto !important;
  }

  .membersTable .combieneBox .dropdown-menu {
    left: -10px;
    padding: 10px 0;
    right: unset;
    width: 250px;
  }

  .membersTable .combieneBox .memberDropDown {
    border: 1px solid #10b700;
  }

  .membersTable .combieneBox .dropdown-toggle {
    position: unset;
  }

  .membersTable .combieneBox .memberDropDown {
    color: #00000079;
    font-size: 10px;
    font-weight: 400;
  }

  .membersTable .combieneBox .dropdown-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 20px 30px 20px 20px;
  }

  .membersTable .combieneBox .dropdown-item span {
    color: #06152b;
    font-family: "Graphik Regular";
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    text-align: left;
  }
}

@media only screen and (max-width: 675px) {
  .membersTable .emailTD .forMobile {
    overflow: hidden;
    width: 200px;
  }

  .membersTable .emailTD .forMobile .des {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
