.howItWorksBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 29.4px 157.2px 61.4px 46px;
  min-height: 280px;
}

.howItWorksBox .qaBox {
  width: 100%;
  margin-bottom: 27.2px;
}

.howItWorksBox .qaBox:last-child {
  margin-bottom: 22px;
}

.qBox {
  align-items: center;
  display: flex;
  gap: 12px;
}

.qBox .question {
  cursor: pointer;
  font-family: "Graphik Bold";
  font-size: 20px;
  font-style: normal;
  line-height: 18px;
}

.qBox button {
  background-color: transparent;
  border: none;
}

.qBox .rotate {
  transform: rotate("180deg");
  transition: transform 0.3s ease;
}

.qaBox .answer {
  color: #06152b;
  font-family: "Graphik Regular";
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
}

@media only screen and (max-width: 768px) {
  .howItWorksBox .qaBox {
    width: 100%;
  }
}