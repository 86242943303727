.accountSecrityBox {
  background-color: #fff;
  border-radius: 10px;
  padding: 28.4px 41px 102px 46px;
  min-height: 300px;
}

.accountSecrityBox .headingBox {
  margin-bottom: 57.2px;
}

.accountSecrityBox .headingBox .title {
  color: #000000;
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.accountSecrityBox .headingBox .subtitle {
  color: #06152b;
  font-family: "Graphik Regular";
  font-size: 16px;
  margin-bottom: 30px;
}

.accountSecrityBox .form-floating {
  margin-bottom: 30px;
}

.accountSecrityBox .form-floating > label {
  width: 100%;
}

.accountSecrityBox .submitbtn {
  align-items: center;
  background-color: #11b700;
  border-radius: 12px;
  border: 1px solid #11b700;
  color: #fff;
  display: flex;
  font-family: "Graphik Medium";
  font-size: 14px;
  height: 55px;
  justify-content: center;
  padding: 10px 30px;
  transition: 0.3s all ease;
  width: 215px;
}

.accountSecrityBox .submitbtn:hover {
  background-color: #000;
  border-color: #000;
}

.accountSecrityBox .customDropdownComponent .dropdownContent {
  padding: 28px 0;
  min-height: 145px;
}

.AccountSettingPage .PageTitle {
  padding-left: 35.5px;
  margin-bottom: 27.6px;
  font-size: 25px;
  font-family: "Graphik Bold";
}

.activeFadeText .dropdown-selected,
.activeFadeText .dropdownArrow {
  opacity: 0.4 !important;
}

@media only screen and (max-width: 768px) {
  .accountSecrityBox {
    padding-bottom: 32px;
  }

  .accountSecrityBox .submitbtn {
    margin-bottom: 30px;
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .accountSecrityBox {
    padding: 32px 20px 32px 20px;
  }

  .AccountSettingPage .PageTitle {
    padding-left: 20px;
  }

  .AccountSettingPage .PageTitle {
    margin-bottom: 28.6px;
    font-size: 20px;
  }

  .accountSecrityBox .headingBox .title {
    font-size: 16px;
    font-family: "Graphik Medium";
  }

  .accountSecrityBox .form-floating {
    margin-bottom: 23px;
  }
}
