.infoPopupContainer {
    position: relative;
    top: -1px;
}

.infoPopupContainer .infoButton {
    background-color: transparent;
    border: none;
}

.infoPopupContainer .infoButton img {
    width: 20.9px;
    height: 20.9px;
    object-fit: contain;
    object-position: center center;
}

.infoPopupContainer .infoPopup {
    padding: 28px 20px 20px 20px;
    border-color: #c6c6c6;
    border-width: 0.20000000298023224px;
    border-style: solid;
    border-radius: 10px;
    width: 233px;
    filter: drop-shadow(-5px 15px 10px rgba(0, 0, 0, 0.2));
    background: #000;
    position: absolute;
    top: 24px;
    left: 100%;
    z-index: 100;
}

.infoPopupContainer .infoPopup button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 7px;
    right: 15px;
}

.infoPopupContainer .infoPopup button img {
    width: 9px;
    height: 9px;
}

.infoPopupContainer .infoPopup p,
.infoPopupContainer .infoPopup p span {
    font-family: "Graphik Regular";
    font-size: 12px;
    line-height: 18px;
    text-align: left;
    color: #ffffff;
}

@media only screen and (max-width: 674px) {

    .infoPopupContainer .infoPopup {
        left: unset;
        right: 0;
    }
}