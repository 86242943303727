.copyUrlBox {
    background-color: #fff;
    padding: 28.4px 37px 54.6px 46px;
    border-radius: 10px;
    margin-bottom: 29.8px;
    height: 165px;
}

.copyUrlBox .title {
    font-family: "Graphik Bold";
    font-size: 20px;
    line-height: normal;
    font-style: normal;
    text-align: left;
    color: #06152b;
    margin-bottom: 8.2px;

}

.copyUrlBox .subTitle {
    font-family: "Graphik Medium";
    font-size: 16px;
    color: #06152b;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
}

.copyUrlBox .UrlBox {
    width: 366px;
    margin-left: auto;
}

.copyUrlBox .UrlBox .UrlBoxInner {
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
}

.copyUrlBox .UrlBox .UrlBoxInner input {
    background: #f7f7f7;
    border-radius: 10px;
    border: none;
    width: 100%;
    height: 56px;
    padding: 0 42px 0 15px;
    font-family: "Graphik Medium";
}

.copyUrlBox .UrlBox .UrlBoxInner .copyIcon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
}

.setformfontsize14{
    font-size: 14px;
}

.copyUrlBox .copyIcon img{
    width: 18px;
    height: 18px;
    object-fit: contain;
    object-position: center center;
}


@media only screen and (max-width: 1185px) {
    .copyUrlBox .UrlBox {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .copyUrlBox {
        padding: 32px 20px 50px 20px;
        height: auto;
    }
}

@media only screen and (max-width: 576px){
    .copyUrlBox{
        padding: 32px 20px 50px 20px;
    }
}