.customDropdownComponent {
  display: inline-block;
  position: relative;
  width: 100%;
}

.customDropdownComponent .dropdownButton {
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 12px;
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  font-family: "Graphik Medium";
  font-size: 14px;
  justify-content: space-between;
  min-height: 55px;
  padding: 12px 14px;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.customDropdownComponent .dropdownButton.active {
  background-color: #fff;
  border: 2px solid #11b700;
}

.customDropdownComponent .dropdownButton.has-value {
  background-color: #f7f7f7;
}

.customDropdownComponent .dropdown-selected.placeholder {
  color: #9a9b9b;
  opacity: 0.5;
}

.customDropdownComponent .dropdown-selected {
  color: #06152b;
  font-family: "Graphik Medium";
  font-size: 14px;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;
  top: 3px;
  white-space: nowrap;
}

.customDropdownComponent .dropdownArrow {
  opacity: 0.4;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.customDropdownComponent .dropdownArrow.rotate {
  opacity: 1;
  transform: rotate(180deg);
}

.customDropdownComponent .floating-label {
  background-color: transparent;
  color: #9a9b9b;
  font-family: "Graphik Medium";
  font-size: 14px;
  left: 14px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease-in-out;
}

.customDropdownComponent .floating-label.active {
  color: #9a9b9b;
  font-size: 10px;
  left: 14px;
  top: 13px;
}

.customDropdownComponent .dropdownContent {
  background-color: #fff;
  border-radius: 10px;
  border: 0.5px solid #d6d6d6;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  display: block;
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.1));
  left: 0;
  padding: 31px 0;
  position: absolute;
  top: 65.7px;
  width: 250px;
  z-index: 10;
}

.customDropdownComponent .dropdown-option {
  align-items: center;
  color: #06152b;
  cursor: pointer;
  display: flex;
  font-family: "Graphik Regular";
  font-size: 14px;
  justify-content: space-between;
  margin: 0;
  padding: 11px 48.1px 11px 31.1px;
  transition: all 0.3s ease;
  width: 100%;
}

.customDropdownComponent .dropdown-option:hover {
  background-color: #f1f1f1;
  padding: 11px 28.1px 11px 31.1px;
}

.customDropdownComponent .dropdown-option .span1 {
  color: #06152b;
  display: block;
  font-family: "Graphik Medium";
  font-size: 14px;
  opacity: 1;
  text-align: left;
  white-space: nowrap;
}

.customDropdownComponent .dropdown-option:hover .span1 {
  font-family: "Graphik Bold";
}

.customDropdownComponent .dropdown-option .arrowspan {
  margin-left: auto;
  opacity: 1;
}