.CopyItCustomInput {
    border-radius: 10px;
    position: relative;
    background-color: #f7f7f7;
    box-shadow: 0 6px 20px 3px #c6c6c642;
    padding: 15px 15px 8px 15px;
    height: 55px;
    overflow: hidden;
    border: 1px solid transparent;
}

.CopyItCustomInput label {
    padding: 0 !important;
    position: absolute;
    top: 3px;
    left: 0;
    z-index: 2;
    height: 100%;
    overflow: hidden;
    text-align: start;
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    border: var(--bs-border-width) solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out, transform .1s ease-in-out;

    font-family: "Graphik Medium";
    font-size: 14px;
    color: #9a9b9b;
}

.CopyItCustomInput label::after {
    content: none !important;
}

.CopyItCustomInput input {
    border: none;
    font-family: "Graphik Medium";
    border-radius: 0;
    width: 100%;
    font-size: 14px !important;
    height: auto !important;
    min-height: auto !important;
    color: #000 !important;
    outline: none;
    padding-top: 12px !important;
    padding-bottom: 5px !important;
    padding-left: 2px !important;
    background-color: transparent;
}

.CopyItCustomInput .form-floating>.form-control-plaintext~label,
.CopyItCustomInput .form-floating>.form-control:focus~label,
.CopyItCustomInput .form-floating>.form-control:not(:placeholder-shown)~label,
.CopyItCustomInput .form-floating>.form-select~label {
    color: #9a9b9b;
    transform: scale(0.7) translateY(-0.89rem) translate(0);
}

.CopyItCustomInput:focus-within {
    background-color: #fff;
    border-color: #11b700;
}


.CopyItCustomInput:has(:disabled) .form-control {
    opacity: 0.4;
}

.CopyItCustomInput.invalid {
    border: 1px solid red !important;
}