/*------------------------------------------------------------------
[Main Stylesheet]

Project     : p1-TradingHtml
Version     : 1.0
Author      : Md Ekramul Hassan Avi
Author URI  : https://www.tigertemplate.com
-------------------------------------------------------------------*/

/*
==========================================
  icon fonts
==========================================
*/
@import url("../src/assets/bootstrap-icons-1.10.5/font/bootstrap-icons.css");
/* @import url("https://fonts.cdnfonts.com/css/graphik"); */

/*
==========================================
  site fonts
==========================================
*/
@font-face {
  font-family: "Graphik Regular";
  src: url("./assets/fonts/Graphik-Regular.woff2") format("woff2"),
    url("./assets/fonts/Graphik-Regular.woff") format("woff"),
    url("./assets/fonts/Graphik-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

.text_GR {
  font-family: "Graphik Regular", sans-serif;
  font-weight: 400;
}

@font-face {
  font-family: "Graphik Medium";
  src: url("./assets/fonts/Graphik-Medium.woff2") format("woff2"),
    url("./assets/fonts/Graphik-Medium.woff") format("woff"),
    url("./assets/fonts/Graphik-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.text_GM {
  font-family: "Graphik Medium", sans-serif;
  font-weight: 500;
}

@font-face {
  font-family: "Graphik Bold";
  src: url("./assets/fonts/Graphik-Bold.woff2") format("woff2"),
    url("./assets/fonts/Graphik-Bold.woff") format("woff"),
    url("./assets/fonts/Graphik-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

.text_GB {
  font-family: "Graphik Bold", sans-serif;
  font-weight: 700;
}

@font-face {
  font-family: "Graphik Black";
  font-style: normal;
  font-weight: 900;
  src: local("Graphik"), url(./assets/fonts/GraphikBlack.otf) format("woff");
}

.text_GBlack {
  font-family: "Graphik Black", sans-serif;
  font-weight: 900;
}

/*------------------------------------------------------------------
[Table of contents]

1.  reset css
2.  common css
3.  home css
0.  footer css

-------------------------------------------------------------------*/

/*
==========================================
1.  reset css
==========================================
*/

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Graphik Regular", sans-serif;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  color: #000;
  background-color: #f8f9fa;
  /* -webkit-appearance: none !important;
  -webkit-transform: translate3d(0, 0, 0);
  overflow: visible !important;
  will-change: transform; */
}

a,
a:active,
a:focus,
a:hover,
button {
  text-decoration: none;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  cursor: pointer;
  display: inline-block;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
hr {
  padding: 0;
  margin: 0;
  font-weight: 400;
}

.site_nav ul li {
  list-style-type: none;
}

/*
==========================================
2.  common css
==========================================
*/

/* button style */
.common_style .btn {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 100%;
  font-family: "Graphik Medium";
}

.common_style .btn:active,
.common_style .btn:focus,
.common_style .btn:hover {
  box-shadow: none !important;
}

/* font style */
.common_style .fs57 {
  font-size: 57px;
}

.common_style .fs40 {
  font-size: 40px !important;
}

.common_style .fs36 {
  font-size: 36px;
}

.common_style .fs35 {
  font-size: 35px;
}

.fs28 {
  font-size: 28px !important;
}

.common_style .fs30 {
  font-size: 30px;
}

.common_style .fs32 {
  font-size: 32px;
}

.common_style .fs25 {
  font-size: 25px !important;
}

.common_style .fs20 {
  font-size: 20px !important;
}

.common_style .fs18 {
  font-size: 18px;
}

.common_style .fs16 {
  font-size: 16px;
}

.common_style .fs14 {
  font-size: 14px !important;
}

.fs13 {
  font-size: 13px !important;
}

.common_style .fs12 {
  font-size: 12px !important;
}

.common_style .text-900 {
  font-weight: 900;
}

.common_style .text-800 {
  font-weight: 800;
}

.common_style .text-700,
.common_style b,
.common_style strong {
  font-weight: 700;
  font-family: "Graphik Bold";
}

.common_style .text-600 {
  font-weight: 600;
}

.common_style .text-500 {
  font-weight: 500;
}

.common_style .text-300 {
  font-weight: 300;
}

.password-toggle {
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.password-toggle img {
  width: 28px;
}

/* radius */
.common_style .radius10 {
  border-radius: 10px;
}

.common_style .radius16 {
  border-radius: 16px;
}

.common_style .radius {
  border-radius: 9999px;
}

/* width height style */
.common_style .min_h57 {
  min-height: 57px;
}

.h_60 {
  height: 60px;
}

.common_style .max_w400 {
  max-width: 400px;
}

.common_style .min_h72 {
  min-height: 72px;
}

.common_style .min_hAuto {
  min-height: auto;
}

/* line height */
.common_style .li_h40 {
  line-height: 40px;
}

.w_261 {
  width: 261px;
  max-width: 100%;
}

.w_260 {
  width: 260px;
  max-width: 100%;
}

.w_226 {
  width: 226px;
  max-width: 100%;
}

.h_74 {
  height: 74px;
}

.h_340 {
  height: 340px;
}

.w_480 {
  width: 480px;
  max-width: 100%;
}

/* color style */
.common_style .text-primary {
  color: #c6e4fa !important;
}

.common_style .text-muted {
  color: #6f6f6f !important;
}

.common_style .text_muted3 {
  color: rgba(123, 119, 119, 0.3) !important;
}

.common_style .text_muted3:hover {
  color: #000 !important;
}

.common_style .text_muted4 {
  color: #9b9b9b !important;
}

.common_style a.text_muted4:hover {
  color: #000 !important;
}

.common_style .text_muted5 {
  color: #7b7777 !important;
}

.common_style .text_dark3 {
  color: rgba(0, 0, 0, 0.3) !important;
}

.common_style .text_dark5 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.common_style .text_dark6 {
  color: rgba(0, 0, 0, 0.6) !important;
}

.common_style .text_dark75 {
  color: rgba(0, 0, 0, 0.75) !important;
}

.common_style .text_dark8 {
  color: rgba(0, 0, 0, 0.8) !important;
}

.common_style .bg-primary {
  background-color: #c6e4fa !important;
}

.common_style .bg-light {
  background-color: #ededed !important;
}

.common_style .bg-dark {
  background-color: #000 !important;
}

.common_style .bg-success {
  background-color: #8bd4b4 !important;
}

/* button */
.common_style .btn-success {
  color: #000;
  background-color: #0fb700;
  border-color: #0fb700;
}

.common_style .btn-success:hover {
  color: #000;
  background-color: #abf5d7;
  border-color: #abf5d7;
}

.common_style .btn-danger {
  color: #fff;
  background-color: #ff0000;
  border-color: #ff0000;
}

.common_style .btn-danger:hover {
  color: #fff;
  background-color: #f82d2d;
  border-color: #f82d2d;
}

.text-dark {
  color: #000 !important;
}

/* border color */
.common_style .border-dark {
  border-color: #707070 !important;
}

.common_style .border_dark75 {
  border-color: rgba(0, 0, 0, 0.75) !important;
}

/* shadow */
.common_style .shadow-lg {
  box-shadow: 0 27px 24px rgba(0, 0, 0, 0.12) !important;
}

/*
==========================================
3.  home css
==========================================
*/

.site_nav>ul>li a {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 0 0 0 50px;
  position: relative;
}

.site_nav>ul>li ul>li a {
  min-height: 30px !important;
}

.submenu-items {
  padding-left: 20px;
  list-style: none;
  /* margin-top: -10px; */
}

.submenu-items li div {
  display: block;
  padding: 0px 0 0 45px !important;
  color: #000;
  text-decoration: none;
  margin-bottom: 5px 0px;
  width: 100%;
}

.submenu-items li {
  display: flex;
  align-items: center;
  height: 25px;
  justify-content: left;
}

.site_nav .arrowD {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translate(-15px, -50%);
  width: 6px;
  height: auto;
}

.site_nav .links:hover,
.site_nav .links.active {
  font-weight: 700;
  font-family: "Graphik Bold";
}

.submenu-items li:hover div {
  background-color: #eee;
  font-weight: 700 !important;
  font-family: "Graphik Bold" !important;
  cursor: pointer;
}

.submenu-items-active {
  background-color: #eee;
  font-weight: 700 !important;
  font-family: "Graphik Bold" !important;
}

.site_nav li ul li:hover a,
.site_nav li ul li.active a {
  font-weight: 700;
  font-family: "Graphik Bold";
}

.site_nav li {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

/* .site_nav li:hover::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -9px;
    width: 20px;
    height: 50px;
    border-radius: 20px;
    background-color: #0FB700;
    font-family: bootstrap-icons !important;
    color: rgba(11, 21, 90, 0.4);
} */
.site_nav_scroll li.active::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -19px;
  width: 20px;
  height: 45px;
  border-radius: 20px;
  background-color: #0fb700;
  font-family: bootstrap-icons !important;
  color: rgba(11, 21, 90, 0.4);
}

.site_nav>ul>li i {
  color: rgba(0, 0, 0, 0.4);
  min-width: 25px;
}

.site_nav>ul>li.submenu {
  position: relative;
  margin-bottom: 18px;
}

.connect_box {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  background-color: black;
}

.connect_box.bg-light:hover {
  background-color: #f6f6f6 !important;
}

.form-select:focus,
.form-control:focus {
  box-shadow: none !important;
  border-color: #000;
}

.form-select,
.form-control {
  min-height: 45px;
  padding: 12px 14px;
  border-radius: 4px;
  border-color: #000;
  color: rgba(50, 58, 97, 0.5);
}

.form-control:disabled,
.form-control[readonly] {
  background-color: transparent;
  opacity: 1;
  cursor: no-drop;
}

.form-control::placeholder {
  color: rgba(50, 58, 97, 0.5);
  opacity: 1;
  /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: rgba(50, 58, 97, 0.5);
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: rgba(50, 58, 97, 0.5);
}

.tabs_list li:not(:last-child) {
  margin-right: 10px;
  padding-right: 10px;
  position: relative;
}

.tabs_list li:not(:last-child):before {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translateY(-50%);
  height: calc(100% - 15px);
  width: 1px;
  background-color: #000;
}

.tabs_list li[aria-expanded="true"] {
  position: relative;
  font-weight: 700;
  text-decoration: underline;
  font-family: "Graphik Bold", sans-serif;
}

.disable_animation_accordion .collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.disable_animation_accordion {
  overflow-x: hidden;
}

.back_link {
  left: 50px;
  top: 2px;
}

/* modal */
.modal-body .position-absolute[aria-label="Close"] {
  top: 9px;
  left: -7px;
}

.modal-body .position-absolute[aria-label="Close"]:hover {
  opacity: 0.9;
}

.modal-dialog.modal-sm .modal-body {
  padding: 60px 50px;
}

.modal-body .cross {
  width: 19px;
  height: 19px;
}

.onboard-modal {
  position: fixed;
  left: 50%;
  top: 33%;
  transform: translate(-53%, -35%) !important;
  padding-top: 6rem;
  display: block;
}

.modal-dialog.modal-sm .onboard-modal-body {
  padding: 30px 0px;
  height: 180px;
  width: 100%;
  background-color: #0fb700;
  font-family: "Graphik Bold";
  font-weight: bold;
  font-size: 16px;
  border-radius: 15px;
}

.modal-dialog.modal-sm .modal-content {
  border-radius: 15px;
  border: 1px solid #e0e0e0;
  box-shadow: 13px 6px 9px -2px rgb(80 80 80 / 28%);
  align-self: flex-start;
  /* margin-top: 40vh; */
}

.modal-radius-6px {
  border-radius: 6px !important;
}

.modal-backdrop {
  background-color: transparent;
}

.modal.show {
  display: block;
  background-color: #ffffff84;
}

.modal.zoomed {
  z-index: 99999;
  transform: scale(1.1);
  /* margin-bottom: 3rem; */
}

.modal.discord {
  z-index: 1;
  transform: scale(1.3);
  height: 90rem;
}

/* modal */

.resize_null {
  resize: none;
}

.modal-content.discord-content {
  transform: translateY(50%);
}

input[type="checkbox"] {
  position: relative;
  border: 1px solid #000;
  border-radius: 4px;
  background: none;
  cursor: pointer;
  line-height: 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 18px;
  width: 19.5px;
  -webkit-appearance: none;
  opacity: 1;
}

input[type="checkbox"]:hover {
  opacity: 0.5;
}

input[type="checkbox"]:before {
  content: "";
  position: absolute;
  right: 50%;
  top: 50%;
  width: 5px;
  height: 10px;
  border: solid transparent;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}

input[type="checkbox"]:checked:before {
  border-color: #000;
}

.toggleReadonly {
  bottom: 0;
  right: 0;
}

.table-light {
  border-color: rgba(73, 126, 204, 0.1);
  --bs-table-bg: rgba(73, 126, 204, 0.01);
}

.common_style table .bg-light {
  background-color: rgba(73, 126, 204, 0.02) !important;
}

.table td {
  padding-top: 0;
  padding-bottom: 0;
}

.table td:nth-child(2) div>p:last-child {
  color: #8c9ba5;
}

.table td:nth-child(2) div>p:first-child {
  color: #152934;
}

.table td:first-child,
.table td:last-child {
  color: #5a6872;
}

.click_action:hover,
.click_action.active {
  font-weight: 700;
  font-family: "Graphik Bold", sans-serif;
  cursor: pointer;
}

.slide_img1 {
  left: -20px;
  top: 30px;
}

.slide_img2 {
  left: -20px;
  top: 10px;
}

.slide_img3 {
  right: 30px;
  top: 120px;
}

.slide_img4 {
  right: -20px;
  top: 120px;
}

.slide_img5 {
  left: -60px;
  top: 30px;
}

.slide_title {
  min-height: 380px;
  padding-bottom: 24px;
}

.slide_img1 img,
.slide_img2 img {
  max-width: 90%;
}

.slider_content p {
  line-height: 26px;
}

.slider_content {
  min-height: 175px;
}

.slider_footer {
  margin-top: 50px;
}

.owl-theme .owl-dots,
.owl-theme .owl-nav {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 105px;
}

.owl-theme .owl-dots .owl-dot span {
  border: 1px solid #707070;
  background: #000;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #000;
}

.owl-theme .owl-dots .owl-dot.active~.owl-dot span {
  background: transparent;
}

/* custom select */
/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  z-index: 0;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

.select-selected {
  background-color: transparent;
}

/*style the arrow inside the select element:*/
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/*point the arrow upwards when the select box is open (active):*/
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  border: 1px solid transparent;
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
}

/*style items (options):*/
.select-items {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #000;
  border-radius: 0 0 16px 16px;
  z-index: -1;
  width: calc(100% + 42px);
  box-shadow: 0 27px 24px rgba(0, 0, 0, 0.05);
  width: 100%;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  border-top: 0;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  font-weight: 700;
  color: #000;
  cursor: pointer;
}

.select-selected {
  background-image: url("../src/assets/images/triangle.svg");
  background-repeat: no-repeat;
  background-position: center right 15px;
  background-size: 1rem;
  padding: 14px 20px;
  border-radius: 4px;
  border-color: #000;
  color: rgba(50, 58, 97, 0.5);
  min-height: 54px;
}

.select-items>div:not(:last-child) {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(112, 112, 112, 0.4);
}

.select-selected.select-arrow-active {
  border-radius: 4px 4px 0 0;
}

/* custom select */

@-moz-keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@-webkit-keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@-o-keyframes growDown {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

.connect_box:hover {
  background-color: #4b4b4b !important;
}

.connect_box.bg-success:hover {
  background-color: #8bd4b4 !important;
}

/* scrollbar */
.os-scrollbar-vertical {
  right: -20px;
}

.os-host-overflow {
  overflow: visible !important;
}

.os-theme-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle {
  background: #000;
  /* border: 1px solid #707070;
  width: 25px;
  height: 18px !important;
  border-radius: 50%;
  left: -10px;
  top: -10px; */
}

.os-theme-round-dark>.os-scrollbar-vertical>.os-scrollbar-track>.os-scrollbar-handle {
  width: 18px;
  min-height: 25px;
  max-height: 25px;
}

.os-theme-round-dark>.os-scrollbar>.os-scrollbar-track>.os-scrollbar-handle::before {
  left: 0;
  width: 18px;
  height: 25px;
  border: 2px solid #707070;
}

.os-theme-round-dark>.os-scrollbar-vertical>.os-scrollbar-track::before {
  margin-left: -3px;
}

.os-theme-round-dark>.os-scrollbar-horizontal>.os-scrollbar-track::before,
.os-theme-round-dark>.os-scrollbar-vertical>.os-scrollbar-track::before {
  background: transparent;
  border: 1px solid #eae7e7;
  width: 4px !important;
}

/* scrollbar */

.slider_owl.owl-carousel .owl-stage-outer {
  padding-bottom: 100px;
}

.slider_owl.owl-carousel .owl-nav button.owl-next {
  background: transparent !important;
  border: 0 !important;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.slider_owl.owl-carousel .owl-nav button.owl-prev {
  display: none;
}

.slider_owl.owl-theme .owl-nav {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.slider_owl .owl-next.disabled {
  opacity: 1 !important;
}

.slider_owl .owl-next.disabled .btn {
  position: relative;
  color: transparent;
}

.slider_owl .owl-next.disabled .btn:before {
  content: "START";
  color: #000;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}

.black-btn {
  padding: 7px 25px;
  border-radius: 6px;
  background: black;
  border: 1px solid black;
  color: white;
  font-size: 13px;
}

.black-btn:focus {
  background-color: #0fb700;
  border-color: #0fb700;
  outline: 0;
}

.black-btn:hover {
  background-color: #0fb700;
  border: 1px solid #0fb700;
  color: white;
}

.copyTrade {
  max-width: 691px;
}

.billinPageMargin {
  max-width: 80%;
  padding-right: 30px;
  padding-top: 82px;
}

.copyTradeTab {
  border: none;
  border-color: #e2e2e2;
  border-width: 1.03px;
  padding: 0 77px;
  justify-content: space-between;
  gap: 30px;
}

.copyTradeTab .nav-link.active {
  /* border: 0; */
  border-bottom: 3px solid #000;
  font-family: "Graphik Bold";
}

.copyTradeTab .nav-link.active:hover {
  border-bottom: 3px solid #000;
}

.copyTradeTab .nav-link {
  border: 0;
  /* border-bottom: 1px solid #e2e2e2; */
  color: black;
  display: flex;
  align-items: center;
  text-wrap: nowrap;
  white-space: nowrap;
  padding: 8px 4px 8px;
  font-family: "Graphik Bold";
  font-size: 17px;
}

.copyTradeTab .nav-link>*:not(:last-child) {
  margin-right: 3px;
}

.copyTradeTab .nav-link:hover {
  border: 0;
  font-family: "Graphik Bold";
  /* border-bottom: 1px solid #e2e2e2; */
}

.copyTrade .tab-content {
  padding: 41px 0;
  position: relative;
}

.subscriberCard {
  box-shadow: 0px 10px 80px rgba(121, 124, 246, 0.12);
  border-radius: 16px;
  padding: 24px 30px;
}

.subscription {
  padding: 0px 82px;
}

.subscription.subscriptionJs {
  padding: 0px 89px;
}

.subscription.introJs {
  padding: 0 77px;
}

.subscribed {
  background-color: #0fb700;
}

.subscription .title h3 {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.tradinghistporyTitle {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.subscription .title p {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.TierSelect,
.TierSelect span {
  font-size: 14px;
  color: #000;
}

.TierSelect span {
  margin-right: 5px;
}

.TierSelect.active,
.TierSelect.active span {
  font-family: "Graphik Bold";
}

.tier {
  margin-top: 22px;
  max-width: 479px;
}

.tier .tier-box-append {
  margin-top: 16px;
}

.tier-bottom-btn-box {
  display: flex;
  align-items: center;
  justify-content: start;
}

.del-btn img,
.edit-btn img {
  width: 20px;
  height: 20px;
}

.tier h3 {
  font-family: "Graphik Bold";
  font-size: 16px;
  line-height: 28px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 12px;
  letter-spacing: 0;
}

.tier .card {
  padding: 29px 20px 17px;
  border-color: #c6c6c66e;
  border-width: 0.30000001192092896px;
  border-style: solid;
  border-radius: 10px;
  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));
  background: #ffffff;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 60px;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .tier .card > *:not(:last-child) {
    margin-right: 30px;
} */
.tier .card.subsCard .block {
  width: 35%;
}

.tier .card.subsCard .edit-button-block {
  width: 15%;
  display: flex;
  align-items: end;
  justify-content: center;
  text-align: end;
}

.tier .card.subsCard .edit-button-block button {
  width: fit-content;
  background-color: transparent;
  border: none;
  margin-top: 7px;
}

.blockedit-hel-onborad {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row !important;
  width: 100%;
}

.onboardingUser .card .edit-button-block {
  width: 15%;
  display: flex;
  align-items: end;
  justify-content: center;
  text-align: end;
}

.onboardingUser .card .edit-button-block button {
  width: fit-content;
  background-color: transparent;
  border: none;
  margin-top: 7px;
}

.addTier {
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: start;
}

.addTier img {
  margin-right: 9px;
  cursor: pointer;
}

.addTier p {
  color: #000000;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 14px;
}

.tier .altCard {
  padding: 10px 20px 25px;
}

.tier .card .block {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.tier .card .block label,
span {
  font-family: "Graphik Regular";
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.tier .card .block input {
  font-family: "Graphik Medium";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #000000;
  border: 0;
  border-bottom: 1px solid #000000;
  outline: 0;
  border-radius: 0 !important;
  width: 125px;
  margin-top: 2px;
}

.tier .card .block span {
  margin-top: 7px;
}

.tier .card .block .form-control {
  font-family: "Graphik Bold";
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #000000;
  border: 0;
  border-bottom: 1px solid #000000;
  outline: 0;
  width: 115px;
  height: 20px;
  min-height: 20px;
  padding: 14px 0 14px;
  border-radius: 0;
  margin-top: 0;
}

.tier .card .block .form-floating>label {
  padding: 1px 0;
  font-size: 14px;
}

.tier .card .block .form-floating>label::after {
  background-color: transparent;
}

.tier .form-floating>.form-control-plaintext~label,
.tier .form-floating>.form-control:focus~label,
.tier .form-floating>.form-control:not(:placeholder-shown)~label,
.tier .form-floating>.form-select~label {
  transform: scale(0.8) translateY(-1.5rem) translate(0);
}

.tier .add {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-top: -40px;
  margin-bottom: 40px;
}

.tier .add span {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  font-style: normal;
  text-align: left;
  color: #000000;
  opacity: 0.5;
}

.tier .tag-input {
  display: flex;
  flex-wrap: wrap;
  min-height: 48px;
  padding: 0;
  border: none;
  border-radius: 4px;
  flex-direction: column;
}

.tier .tag {
  background: transparent;
  border: 1px solid #000000;
}

.tier .tag-close-icon {
  background: black;
  color: white;
}

.layoutTop {
  padding-top: 65px;
}

.article {
  /* border-top: 1px solid #e2e2e2; */
  padding: 30px 55px;
  /* margin-top: 70px; */
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  top: 100%;
}

.article h4 {
  font-family: "Graphik Bold";
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  font-style: normal;
  opacity: 0.51;
  color: #000000;
  text-decoration: underline;
}

.article a {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  font-style: normal;
  opacity: 0.51;
  color: #000000;
  display: block;
  text-decoration: underline;
}

.connection {
  padding: 0px 77px;
}

.connection.connectionJs {
  padding: 0 89px;
}

.connection.autoshareJs {
  padding: 41px 89px 0 89px;
}

.connection.reportsJs {
  padding: 41px 89px 0 89px;
}

.connection.about-app-page {
  padding: 0 77px;
}

.connection .title h3 {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.connection .title p {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.connection .title h2 {
  font-family: "Graphik Bold";
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 16px;
  margin-top: 50px;
}

.connection h2 {
  font-family: "Graphik Bold";
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 16px;
}

.connection .title .sub-inner-heading {
  font-size: 16px;
  font-family: "Graphik bold";
  margin-top: 20px;
}

.connection .accountCard {
  border-color: #c6c6c66e;
  border-width: 0.699999988079071px;
  border-style: solid;
  border-radius: 10px;
  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));
  background: #ffffff;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.connection .accountCard .accountButtonBox {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
}

.connection .accountCard.active {
  border-color: #ff0202;
}

.connection .accountCard img {
  margin-bottom: 10px;
  height: 23px;
}

.connection .accountCard .disabled {
  filter: opacity(0.5);
}

.connection .accountCard .coming-soon {
  filter: opacity(0.5);
}

.connection .accountCard .coming-soon:hover {
  background-color: #000;
  color: white;
  border: 1px solid #000;
  cursor: not-allowed;
}

.connection .accountCard.active img {
  filter: none;
}

.accountCard .account p {
  font-family: "Graphik Regular";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-right: 10px;
  display: flex;
  align-self: center;
}

.accountCard .account p span {
  width: 13px;
  height: 13px;
  border-radius: 100px;
  background-color: #000;
  display: block;
  margin-right: 5px;
  margin-top: 2px;
}

.accountCard.active .account p .cyan {
  background-color: #0fb700;
}

.accountCard.active .account p .red {
  background-color: #ff0000;
}

.accountCard button {
  background-color: #0fb700;
  color: white;
  border: 1px solid #0fb700;
  border-radius: 7px;
  padding: 7px 35px 7px 10px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  gap: 5px;
  font-family: "Graphik Medium", sans-serif;
}

.accountCard button img {
  height: 20px !important;
  width: auto;
  margin-bottom: 0 !important;
  position: relative;
  top: -2.5px;
}

.accountCard button svg {
  height: 20px !important;
  width: auto;
  margin-bottom: 0 !important;
  /* position: relative; */
  /* top: -2.5px; */
}

.accountCard button:focus {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
  outline: 0;
}

.accountCard button:hover {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
}

.accountCard.active button {
  background-color: #ff0202;
  color: white;
  border: 1px solid #ff0202;
}

.accountCard.active button:focus {
  border: 1px solid #ff0202;
  color: #ff0202;
  background-color: white;
  outline: 0;
}

.accountCard.active button:hover {
  border: 1px solid #ff0202;
  color: #ff0202;
  background-color: white;
}

.accountCard.active button:hover svg path {
  fill: #ff0202;
}

.accountCard button.black {
  border-color: #000 !important;
  background-color: #000;
  color: #fff;
}

.accountCard button.black:hover {
  background-color: #fff;
  color: #000;
}

.accountCard button.black:hover svg path {
  fill: #000;
}

.apiInsted {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 72px;
}

.apiInsted h3 {
  font-family: "Graphik Bold";
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  font-style: normal;
  text-align: left;
  color: #000;
  cursor: pointer;
}

.apiInsted h3:hover {
  color: #0fb700;
}

.payment {
  padding: 0px 89px;
}

.onboardingUser {
  padding: 0px 89px;
}

.onboardingUser .title h3 {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.payment .title h3 {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.payment .title p {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.payment .paymentCard {
  border-color: #c6c6c66e;
  border-width: 0.699999988079071px;
  border-style: solid;
  border-radius: 10px;
  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));
  background: #ffffff;
  /* padding: 20px 35px 20px 52px; */
  padding: 20px 25px 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 32px;
  flex-wrap: wrap;
  gap: 10px;
}

.payment .paymentCard2 .saveEdit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 14px;
}

.payment .paymentCard2 {
  border-color: #c6c6c66e;
  border-width: 0.699999988079071px;
  border-style: solid;
  border-radius: 10px;
  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));
  background: #ffffff;
  padding: 20px 25px 20px 40px;
  margin-top: 31px;
}

.payment .account h4 {
  font-family: "Graphik Bold";
  font-size: 15px;
  font-weight: 700;
  font-style: normal;
  text-align: left;
  color: #000000;
  z-index: 999;
  position: relative;
}

.payment .account p {
  font-family: "Graphik Regular";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.payment .paymentCard img {
  width: 120px;
  margin-top: 12px;
}

.payment .btn {
  /* width: 90px; */
}

.payment .black-btn {
  background-color: #000;
  color: white;
  border: 1px solid #000;
  padding: 3px 20px;
  border-radius: 4px;
  width: 87px;
  font-size: 13px;
  height: 30px;
  font-family: "Graphik Medium", sans-serif;
}

.payment .black-btn:hover {
  background: #0fb700;
  border: 1px solid #0fb700;
  color: white;
}

.payment .inputDiv {
  display: block;
  align-items: center;
  justify-content: space-between;
  width: 71%;
  /* margin-top: 15px; */
}

.payment .inputDiv .account h4 {
  margin-bottom: 15px;
}

.payment .inputDiv input {
  border: 0;
  border-bottom: 2px solid #c6c6c6;
  outline: 0;
  width: 100%;
  border-radius: 0;
  padding-bottom: 0 !important;
  padding-left: 0;
  color: #000;
  font-size: 14px;
  padding-top: 10px !important;
  height: 15px;
  min-height: 30px;
}

.payment .inputDiv .form-floating {
  width: 100%;
  font-size: 14px;
}

.payment .inputDiv .form-floating>label {
  padding: 10px 0;
  min-height: auto;
  height: auto;
  background: transparent;
  color: gray;
}

.payment .inputDiv .form-floating>label::after {
  background-color: transparent;
}

.cyan-btn {
  background-color: #0fb700;
  color: white;
  border: 1px solid #0fb700;
  padding: 7px 25px;
  border-radius: 5px;
}

.cyan-btn:focus-visible {
  color: white;
  background-color: #000;
  border-color: #000;
  outline: 0;
  box-shadow: none;
}

.cyan-btn:hover {
  background-color: #000;
  border: 1px solid #000;
  color: #fff;
}

.cyan-btn1 {
  background-color: #0fb700;
  color: white;
  border: 1px solid #0fb700;
  padding: 7px 25px;
  border-radius: 5px;
}

.url {
  padding: 0px 89px;
}

.url .title h3 {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 3px;
}

.url .title p {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.url .copyBlock {
  border-color: #c6c6c66e;
  border-width: 0.699999988079071px;
  border-style: solid;
  border-radius: 10px;
  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));
  background: #ffffff;
  padding: 20px 20px 20px 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
  height: 96px;
}

.url .copyBlock .form-control {
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #000;
  font-family: "Graphik Regular", sans-serif;
}

.url .copyBlock span {
  background-color: white;
  padding: 10px 0 10px 10px;
}

.haveSeen {
  margin-top: 105px;
}

.haveSeenCards {
  display: flex;
  align-items: center;
  gap: 10px;
}

.haveSeen .card {
  border-color: #c6c6c66e;
  border-width: 0.699999988079071px;
  border-style: solid;
  border-radius: 10px;
  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));
  background: #ffffff;
  padding: 25px 18px 18px;
  margin: 10px 0;
  width: 278px;
  height: 160px;
  justify-content: center;
}

.url .card .blocks {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}

.url .card h4 {
  font-family: "Graphik Bold";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.url .card p {
  font-family: "Graphik Regular";
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #414141;
  margin: 5px 0;
}

.url .card .socialIcons {
  margin-left: -13px;
  margin-bottom: -27px;
}

.url .card .discountImg {
  margin-left: 10px;
  width: 70px;
  filter: invert(1);
}

.url .black-btn {
  background-color: #000;
  color: white;
  border: 1px solid #000;
  padding: 4px 10px;
  border-radius: 4px;
  margin-top: 10px;
  font-size: 13px;
  width: 88px;
  height: 30px;
}

.url .black-btn:hover {
  background: #0fb700;
  border: 1px solid #0fb700;
  color: white;
}

.inputDate {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.inputDate input {
  width: 35px !important;
  height: 35px !important;
  border: 0;
  border-radius: 5px;
  text-align: center;
  outline: 0;
  margin-right: 5px;
}

.launch {
  width: 100px;
  transform: rotate(45deg);
}

.emailDate {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emailDate button {
  border: 0;
  outline: 0;
  border-radius: 100px;
  margin-left: 15px;
}

.customCheck {
  margin-left: 10px;
}

.customCheck .custom-checkbox {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 3px solid #000;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.customCheck .custom-checkbox-white {
  border: 3px solid #fff;
  width: 35px;
  height: 35px;
}

.customCheck .custom-checkbox::before {
  content: "";
  width: 5px;
  height: 11px;
  border-bottom: 3px solid #000;
  border-right: 3px solid #000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.customCheck .custom-checkbox-white::before {
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}

.customCheck input[type="checkbox"] {
  display: none;
}

.customCheck input[type="checkbox"]:checked+.custom-checkbox::before {
  opacity: 1;
}

.tradeHistory .card {
  border-color: #c6c6c66e;
  border-width: 0.699999988079071px;
  border-style: solid;
  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));
  background: #ffffff;
  padding: 15px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 15px;
  border-radius: 12px;
  flex-wrap: wrap;
  gap: 5px;
}

.tradeHistory .card .number h4 {
  font-family: "Graphik Bold";
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  text-align: right;
  color: #000000;
}

.tradeHistory .card .active h4 {
  color: #f82d2d;
}

.tradeHistory .card .content {
  margin-right: 10px;
}

.tradeHistory .card .content span {
  font-size: 14px;
  color: #414141;
  text-transform: capitalize;
  display: block;
  margin-bottom: 10px;
}

.tradeHistory .card .content p {
  margin-right: 6px;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Graphik Regular", sans-serif;
  color: #414141;
  letter-spacing: 1px;
  line-height: normal;
}

.tradeHistory .card .content p:first-child {
  /* width: 90px; */
  line-height: normal;
  justify-content: end;
}

.tradeHistoryMbl {
  display: none;
}

.tradeHistoryMbl .card {
  border-color: #c6c6c66e;
  border-width: 0.699999988079071px;
  border-style: solid;
  background: #ffffff;
  padding: 10px 14px 13px 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 15px;
  border-radius: 10px;
  align-items: center;
}

.tradeHistoryMbl .card .number h4 {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-style: normal;
  text-align: right;
  color: #000000;
  display: flex;
  justify-content: end;
}

.tradeHistoryMbl .card .number {
  width: 65px;
}

.tradeHistoryMbl .card .active h4 {
  color: #f82d2d;
}

.tradeHistoryMbl .card .content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 60%;
}

.tradeHistoryMbl .card .content span {
  font-size: 12px;
  font-family: "Graphik Regular";
  color: #414141;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 4px;
}

.tradeHistoryMbl .card .content p {
  text-transform: capitalize;
  font-size: 12px;
  font-family: "Graphik Bold", sans-serif;
  color: #414141;
  letter-spacing: 1px;
  line-height: normal;
}

.tradeHistoryMbl .dateShort p {
  text-align: end;
}

.tradeHistoryMbl .borderLine {
  width: 1.5px;
  height: 30px;
  background-color: #c6c6c66e;
  margin: 0 20px;
}

.membersOverview .card {
  border-color: #c6c6c66e;
  border-width: 0.699999988079071px;
  border-style: solid;
  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));
  background: #ffffff;
  padding: 15px;
  display: flex;
  align-items: end;
  justify-content: space-between;
  flex-direction: row;
  margin-bottom: 15px;
  border-radius: 12px;
  flex-wrap: wrap;
  gap: 5px;
}

.membersOverview .card .number h4 {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  font-style: normal;
  text-align: right;
  color: #000000;
}

.membersOverview .card .active h4 {
  color: #f82d2d;
}

.membersOverview .card .content {
  margin-right: 10px;
}

.membersOverview .card .content span {
  font-size: 14px;
  color: #414141;
  text-transform: capitalize;
  display: block;
}

.membersOverview .card .content p {
  margin-right: 10px;
  text-transform: capitalize;
  font-size: 16px;
  font-family: "Graphik Regular", sans-serif;
  color: #414141;
  letter-spacing: 1px;
  line-height: normal;
}

.membersOverview .card .content p:first-child {
  width: 90px;
  line-height: normal;
  justify-content: end;
}

.border-right {
  border-right: 1px solid #414141;
  line-height: 0.8;
  padding-right: 8px;
}

.select {
  box-shadow: 0 6px 20px 3px #c6c6c642;
  border-radius: 10px;
}

.country-option {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 1px solid #70707033;
  padding: 15px 10px !important;
  background-color: #fff !important;
}

.riskSelect {
  position: relative;
}

.riskSelect .css-fdbcdi-singleValue {
  font-weight: bold;
  font-family: "Graphik Bold";
  margin-top: 15px;
  font-size: 18px;
}

.riskSelect .selectPercent {
  position: absolute;
  z-index: 999;
  font-size: 14px;
  font-family: "Graphik Regular";
  top: 12px;
  left: 20px;
}

.country-logo {
  width: auto;
  height: 20px;
  margin-right: 12px;
  border-radius: 100px;
  object-fit: cover;
}

.country-logo-big {
  width: 46px;
  height: auto;
  margin-right: 12px;
  border-radius: 100px;
  object-fit: cover;
}

.selected-logo {
  width: 46px;
  margin-right: 12px;
  border-radius: 100px;
  object-fit: cover;
}

.sendingPermissionselect {
  margin-bottom: 25px;
}

/* .sendingPermissionselect>div>div {
  border-color: #c6c6c63d !important;
  border-width: 0px;
  border-radius: 10px;
  z-index: 99;
  padding: 12px;
  box-shadow: none;
  cursor: pointer;
} */

.sendingPermissionselect .select>div>div {
  width: 100%;
}

.select {
  position: relative;
}

.select>div>div {
  border-color: #c6c6c63d !important;
  border-width: 0px;
  min-height: 79px;
  border-radius: 10px;
  z-index: 99;
  padding: 12px;
  box-shadow: none;
  cursor: pointer;
}

.country-option:hover {
  /* background-color: #0FB70042 !important; */
  background-color: #f0f0f0 !important;
  color: black !important;
}

.css-tr4s17-option {
  background-color: transparent !important;
  color: black !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.select svg {
  fill: #000;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 8px 8px 8px;
  border-color: transparent transparent #000000 transparent;
  transform: rotate(180deg);
}

.onBorading svg {
  border: none;
  width: auto;
  height: auto;
  fill: #ffff;
  background-color: #000;
  border-radius: 100px;
}

.css-1xc3v61-indicatorContainer svg {
  display: none !important;
}

.css-12a83d4-MultiValueRemove:hover {
  background-color: transparent !important;
  color: transparent !important;
}

.css-v7duua:hover {
  background-color: transparent !important;
  color: transparent !important;
}

.onBorading .css-1p3m7a8-multiValue {
  background-color: transparent !important;
  border: 2px solid #000 !important;
  padding: 2px;
  border-radius: 5px;
  font-size: 14px;
  font-family: "Graphik Regular";
}

.onBorading .css-1xc3v61-indicatorContainer {
  border: none;
  width: auto;
  height: auto;
  display: none;
}

.onBorading .css-15lsz6c-indicatorContainer {
  border: none;
  width: auto;
  height: auto;
  display: none;
}

.css-1n6sfyn-MenuList>div {
  border-top: 1px solid #e3e3e3;
}

.risk .css-1n6sfyn-MenuList {
  padding: 15px 20px 15px !important;
}

.onBorading::after {
  content: "";
  background: url(./assets/images/triangle.svg) no-repeat;
  background-size: 25px 10px;
  position: absolute;
  top: 20px;
  right: 7px;
  min-width: 30px;
  background-position: center;
  min-height: 28px;
  height: 40px;
  display: inline-block;
  width: 47px;
  z-index: 99;
}

.select .css-1nmdiq5-menu {
  margin: -10px 0 0;
  z-index: 99999;
  box-shadow: 0 24px 20px 3px #c6c6c642;
}

.select .css-fdbcdi-singleValue {
  display: block;
}

.select .css-1n6sfyn-MenuList {
  padding: 40px 20px 20px;
}

.select .checkCircle {
  width: 35px;
}

.select .logoDiv img {
  height: 20px;
  width: auto;
  border-radius: 0;
}

.select .css-13cymwt-control {
  z-index: 99;
}

.autoInput {
  margin: 45px 0;
}

.autoInput input {
  border: 1px solid #c6c6c63d;
  box-shadow: 0 6px 20px 3px #c6c6c642;
  outline: 0;
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  font-size: 14px;
  font-family: "Graphik Regular", sans-serif;
}

.autoInput span {
  background-color: #fafafa;
  padding: 10px 10px 10px 10px;
  margin-right: 10px;
}

.autoInput h3 {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 15px;
}

.autoInput .black-btn {
  padding: 7px 25px;
  border-radius: 6px;
  background: black;
  border: 1px solid black;
  color: white;
}

.autoInput .black-btn:hover {
  background: #0fb700;
  border: 1px solid #0fb700;
  color: white;
}

.redBtn .red-btn {
  background-color: #ff0202;
  color: white;
  border: 1px solid #ff0202;
  padding: 7px 25px;
  border-radius: 6px;
}

.redBtn .red-btn:focus {
  border: 1px solid #ff0202;
  color: #ff0202;
  background-color: white;
  outline: 0;
}

.redBtn .red-btn:hover {
  border: 1px solid #ff0202;
  color: #ff0202;
  background-color: white;
}

.authorizeBtn .authorize-btn {
  background-color: #fff;
  color: black;
  border: 1px solid #000;
  padding: 7px 25px 7px 10px;
  border-radius: 6px;
}

.authorizeBtn .authorize-btn:hover {
  border: 1px solid #000;
  color: #fff;
  background-color: #000;
}

.authorizeBtn .authorize-btn img {
  margin-right: 8px;
}

.discount {
  width: 100%;
  margin: 40px 0 70px;
}

.discount .discountInputs {
  display: flex;
  /* max-width: 407px; */
  max-width: 100%;
}

.discount .discountInputs .messageInput {
  margin: 0;
  padding: 10px 0;
}

.discount .discountInputs>*:not(:last-child) {
  margin-right: 20px;
}

.discount .discountInputs .messageInput input {
  border: 1px solid #c6c6c63d;
  outline: 0;
  font-size: 14px;
  border-radius: 10px;
}

.discount .discountInputs .messageInput label::after {
  background-color: transparent;
}

.discount .discountInputs .messageInput:first-child {
  width: 38%;
}

.discount .discountInputs .messageInput:last-child {
  width: 62%;
}

.discount .discountInputs .messageInputs:first-child {
  width: 38%;
}

.discount .discountInputs .messageInputs:last-child {
  width: 62%;
}

.discountBtn {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.discountBtn button {
  width: 89px;
  height: 31px;
  padding: 3px 20px;
}

.discount .card {
  padding: 30px;
  border-radius: 10px;
  border: 1px solid #c6c6c63d;
  box-shadow: 0 6px 20px 3px #c6c6c642;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.discount .card>*:not(:last-child) {
  margin-right: 80px;
}

.discount .card h3 {
  font-family: "Graphik Bold";
  /* font-size: 18px; */
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.discount .card img {
  width: 24px;
  cursor: pointer;
  margin-left: 1rem;
}

.discount .card .block:first-child {
  width: 35%;
}

.discount .card .block:last-child {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.position-unset {
  position: unset !important;
  z-index: unset !important;
}

.membersOverview .card .contentBlock {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.membersOverview .card .contentBlock .content h5 {
  font-size: 14px;
  font-family: "Graphik Bold";
}

.membersOverview .card .contentBlock p {
  font-size: 14px;
  font-family: "Graphik Regular";
  display: flex;
  width: 100% !important;
  letter-spacing: 0;
  text-transform: none;
}

.membersOverview .card .number {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 5px;
}

.membersOverview .card .number img {
  cursor: pointer;
}

.membersSearch {
  padding: 20px;
}

.membersSearch .tabs {
  padding-bottom: 10px;
  display: flex;
}

.membersSearch .tabs .tab {
  border: none;
  background: transparent;
  font-size: 14px;
  font-family: "Graphik Regular";
  font-weight: 400;
  margin-right: 15px;
  color: #000 !important;
}

.membersSearch .tabs .tab.active {
  font-family: "Graphik Bold";
  font-weight: 900;
}

.membersSearch .tab-contents input {
  width: 285px;
  padding: 10px 15px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: "Graphik Regular";
}

.memberMessage h2 {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.messageMemberInput {
  padding: 28px 22px;
  box-shadow: 0 6px 20px 3px #c6c6c642;
  width: 100%;
  border-radius: 10px;
  text-align: start;
  background: white;
  z-index: 99;
  border: 1px solid #c6c6c63d;
  outline: none;
  color: #959393;
}

.dropmenu {
  width: 100%;
  display: flex;
  position: absolute;
  top: 69px;
  flex-direction: column;
  padding: 0px;
  box-shadow: 0 6px 20px 3px #c6c6c642;
  border-radius: 10px;
  background-color: white;
  padding-top: 20px;
  border: 1px solid #c6c6c63d;
  z-index: 9;
}

.messageInput {
  background: white;
  padding: 10px;
  border-radius: 10px;
  margin: 20px 0;
  border: 1px solid #c6c6c63d !important;
  box-shadow: 0 6px 20px 3px #c6c6c642 !important;
}

.messageInput .form-control {
  border: none !important;
  color: #000;
  font-size: 14px;
  font-family: "Graphik Regular", sans-serif;
}

.messageInput label {
  color: hsl(0, 0%, 50%);
  font-size: 14px;
  font-family: "Graphik Regular", sans-serif;
}

/* .messageInput input {
    border: 1px solid #c6c6c63d !important;
    box-shadow: 0 6px 20px 3px #c6c6c642 !important;
    outline: 0;
    width: 100%;
    padding: 30px !important;
    border-radius: 10px;
    min-height: auto !important;
    height: auto !important;
}
.messageInput input:focus{
    box-shadow: 0 6px 20px 3px #c6c6c642 !important;
}
.messageInput input::placeholder {
    color: #959393;
} */
.messageInput textarea {
  border: none;
  outline: 0;
  width: 100%;
  padding: 14px 14px;
  border-radius: 10px;
  resize: none;
}

.messageInput textarea::placeholder {
  color: #959393;
}

.form-check-input:checked {
  background-color: #000 !important;
  border-color: #000 !important;
}

.app .block {
  margin: 50px 0;
}

.app ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.app h4 {
  font-family: "Graphik Bold";
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.app ul li {
  margin-top: 15px;
}

.app .more li {
  margin-top: 27px;
}

.app ul li img {
  margin-right: 7px;
}

.AppTab {
  justify-content: flex-start;
}

.AppTab>*:not(:last-child) {
  margin-right: 43px;
}

.appBtns {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 10px;
  position: absolute;
  margin: 0 auto;
  z-index: 9;
  width: 70%;
}

.appBtns button {
  width: 26.33%;
}

.PerformanceTab {
  justify-content: flex-start !important;
}

.PerformanceTab>*:not(:last-child) {
  margin-right: 30px !important;
}

.MemberTab {
  justify-content: start;
}

.MemberTab>*:not(:last-child) button {
  padding-right: 30px;
}

.pricing {
  padding: 0px 55px;
}

.pricing.pricingJs {
  padding: 0px 77px;
}

.pricing .title {
  margin-bottom: 0px;
}

.pricing .title h3 {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 15px;
}

.pricing .title ul {
  list-style: none;
  padding: 0;
  margin: 19px 0 0;
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
}

.pricing .title ul li {
  cursor: pointer;
  font-size: 13px;
}

.pricing .title ul li.active {
  font-weight: bold;
  font-family: "Graphik Bold";
}

.pricing .block .card {
  border-radius: 12px;
  border: 1px solid #c6c6c63d;
  box-shadow: 0 6px 20px 3px #c6c6c642;
  padding: 24px 32px;
  display: flex;
  margin-bottom: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cryptoBtn {
  display: flex;
}

.pricing .block .card h4 {
  font-family: "Graphik Bold";
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
}

.pricing .block .card p {
  font-family: "Graphik Regular";
  font-size: 12px;
  font-weight: normal;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 5px;
}

.pricing .block .card button {
  padding: 5px 25px;
}

.pricing .block h5 {
  font-family: "Graphik Bold";
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-top: 50px;
}

.promoteBtn {
  width: 87px;
  /* height: 30px; */
  padding: 5px 10px !important;
  font-family: "Graphik Medium", sans-serif;
  font-size: 13px;
}

.imgBlock h4 {
  font-family: "Graphik Bold";
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 33px;
  margin-top: 144px;
}

.imgBlock img {
  margin-bottom: 60px;
}

.introduction .block {
  margin: 30px 0;
}

.introduction .block h4 {
  font-family: "Graphik Bold";
  font-size: 16px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.introduction .block p {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.swiper {
  height: 130px;
}

.swiper-pagination-bullet {
  background: transparent;
  border: 1px solid #535353;
}

.swiper-pagination-bullet-active {
  background: #0fb700 !important;
  border: 1px solid #0fb700 !important;
}

.cardSlider {
  border-radius: 15px;
  border: 1px solid #c6c6c63d;
  box-shadow: 0 6px 20px 3px #c6c6c642;
  padding: 5px 15px;
}

.cardSlider .cardSlider__left h3 {
  font-family: "Graphik Bold";
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 0;
}

.cardSlider .cardSlider__left p {
  font-family: "Graphik Regular";
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 0;
}

.tradingList {
  padding: 0;
  list-style: none;
  display: flex;
  max-width: fit-content;
  align-items: baseline;
  flex-direction: column;
  margin: 35px auto 35px;
}

.tradingList li {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  font-size: 16px;
  font-family: "Graphik Bold";
}

.tradingList li:last-child {
  padding-bottom: 0;
}

.tradingList li img {
  margin-right: 5.6px;
}

.card_flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_flex>*:not(:last-child) {
  margin-right: 15px;
}

.stats_cards {
  width: fit-content;
  margin: 0 auto;
}

.white_card {
  padding: 20px 25px;
  border-radius: 10px;
  box-shadow: 0 0 21px 5px rgba(0, 0, 0, 0.05);
  width: 338px;
  height: 162px;
  background-color: white;
  margin-bottom: 15px;
  overflow: hidden;
}

.black_card {
  background-color: #000;
  padding: 20px 25px;
  border-radius: 10px;
  box-shadow: 0 0 21px 5px rgba(0, 0, 0, 0.05);
  width: 338px;
  height: 162px;
  margin-bottom: 15px;
  overflow: hidden;
}

.black_card p {
  color: white;
}

.black_card h4 {
  color: white;
}

.smallBlack_card {
  width: 161.52px;
  height: 82.5px;
  padding: 15px 5px 20px;
  background-color: #000;
  border-radius: 10px;
  margin-bottom: 15px;
  text-align: center;
  overflow: hidden;
}

.smallBlack_card2 {
  width: 220.52px;
  height: 82.5px;
  padding: 15px 5px 20px;
  background-color: #000;
  border-radius: 10px;
  margin-bottom: 15px;
  text-align: center;
  overflow: hidden;
}

.smallBlack_card3 {
  width: 100%;
  height: 82.5px;
  padding: 15px 5px 20px;
  background-color: #000;
  border-radius: 10px;
  margin-bottom: 15px;
  text-align: center;
  overflow: hidden;
}

.smallBlack_card p {
  color: white;
}

.smallBlack_card h4 {
  color: white;
}

.smallBlack_card2 p {
  color: white;
}

.smallBlack_card2 h4 {
  color: white;
}

.smallBlack_card3 p {
  color: white;
}

.smallBlack_card3 h4 {
  color: white;
}

.className1 {
  display: none;
}

.className2 {
  display: block;
}

@media only screen and (min-width: 576px) {
  .w-lg-200px {
    width: 150px !important;
  }
}

@media only screen and (min-width: 767px) {
  .className1 {
    display: block;
  }

  .className2 {
    display: none;
  }

  .w-lg-200px {
    width: 250px !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/*
==========================================
0.  footer css
==========================================
*/

.common_style footer .text-muted {
  color: rgba(0, 0, 0, 0.3) !important;
}

.min-vh-100.d-flex.flex-column footer {
  margin-top: auto;
}

.cursor-pointer {
  cursor: pointer !important;
}

.signup-text {
  color: #71c3ac;
  text-decoration: none;
}

.toggle-btn {
  display: none;
}

.payment-sub-header {
  font-size: 18px;
}

.bold {
  /* font-weight: bold; */
  font-family: "Graphik Bold" !important;
}

.medium {
  font-family: "Graphik Medium" !important;
}

.regular {
  font-family: "Graphik Regular" !important;
}

.bg-grey {
  background-color: #ededed;
}

.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.755);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.authLoader {
  height: 100vh;
  background: white;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
}

.authLoader>div {
  position: absolute;
  left: 0;
  top: 20%;
  width: 100%;
}

.connectLoader {
  height: calc(100vh - 460px);
  text-align: center;
}

body {
  height: 100vh;
  width: 100%;
  overflow-x: hidden !important;
}

.loader img {
  width: 60px;
}

.search-bar {
  box-shadow: 12px 12px 10px rgba(10, 10, 0, 0.3);
  border-radius: 15px;
  border: 1px solid #e1e1e1;
  color: #707070;
}

.search-bar-input {
  border: none;
  border-radius: 15px;
}

.referred {
  border: 4px solid #38c784;
  position: relative;
  background-color: #4b4b4b !important;
  padding-top: 20px !important;
}

.referred-text {
  position: absolute;
  top: -18px;
  right: 20px;
  font-size: 14px;
  background: #38c784;
  border-radius: 20px;
  padding: 5px 10px;
}

.no-subs {
  text-align: center;
  font-size: 20px;
}

.no-subs div {
  margin-top: 0px !important;
  margin-bottom: 30px !important;
}

.no-subs button {
  text-align: center;
}

.xmark {
  height: 20px;
  width: 20px;
}

.searchBox {
  border: none;
}

.search-wrapper {
  border: none;
  width: 100%;
}

.multiselect {
  display: flex !important;
  align-items: center !important;
  padding: 10px 14px;
}

.optionListContainer {
  top: 95%;
  left: 0;
  width: 100%;
}

.chip {
  background: #000 !important;
}

.multiSelectContainer input {
  width: 100%;
}

.sub-crossicon {
  right: 10px;
  top: 10px;
  width: 19.5px;
  height: 19.5px;
}

.searchWrapper {
  border: none !important;
  padding: 0 !important;
}

.searchWrapper input {
  margin: 0 !important;
}

.filter-icon {
  width: 22px;
  height: 22px;
  opacity: 0.4;
}

.dropdown-menu {
  width: 331px;
  display: flex;
  position: absolute;
  right: -10px !important;
  top: 30px;
  border: 1px solid #afafaf26;
  flex-direction: column;
  padding: 0px;
  box-shadow: 0px 9px 16px 1px #c8c8c8;
  border-radius: 10px;
  margin: 0 10px;
}

.accordion-button:not(.collapsed) {
  background-color: lightgrey;
  color: black;
  border-color: #000;
}

.accordion-button:not(.collapsed)::after {
  color: #000 !important;
  /* background-image: none; */
}

.custom-dropdown {
  position: relative;
}

.dropdown-toggle {
  background: transparent;
  border: none;
  position: relative;
  z-index: 99;
}

.dropdown-toggle::after {
  display: none;
}

.form-check-community :checked {
  background-color: #000000 !important;
  color: #ffffff !important;
  border: #000000;
}

.form-check-community :checked::before {
  color: #ffffff !important;
  border-color: #fff !important;
}

.form-check-community label {
  margin-top: 3px;
}

.b-none {
  border: none !important;
}

.search {
  width: 250px;
  border: none;
  border: 1px solid #6c757d;
  margin-right: 20px;
  border-radius: 10px;
}

.search button {
  background-color: transparent;
  border: none;
}

.search :hover {
  background-color: transparent;
}

.search input {
  border: none;
  border-radius: 10px;
}

.search svg {
  fill: grey;
  width: 20px;
  height: 20px;
}

.pagination {
  display: flex;
  align-items: center;
  gap: 15px;
}

.pagination a {
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #414141;
  border-radius: 5px;
  font-size: 14px;
  border: none;
  background-color: #eef2f5;
}

.pagination {
  --bs-pagination-disabled-bg: transparent;
  --bs-pagination-disabled-border-color: transparent;
}

.pagination .page-item.disabled {
  cursor: not-allowed;
}

.pagination .page-item.active {
  z-index: 0;
}

.pagination a:focus,
.pagination a:hover {
  box-shadow: none;
  background-color: transparent;
  outline: 0;
  color: black;
}

.custom-pagination .page-link span {
  font-size: 20px;
}

.page-item:last-child .page-link img,
.page-item:first-child .page-link img {
  /* border-radius: 50rem; */
  /* border: 1px solid #bdc1ca; */
  width: 21px;
  height: 21px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* background-color: #fff; */
}

.page-item:last-child .page-link,
.page-item:first-child .page-link {
  padding: 0;
  background-color: transparent;
}

/* .page-item:last-child .page-link {
  margin-left: 10px;
}

.page-item:first-child .page-link {
  margin-right: 10px;
} */

.page-item:last-child .page-link span {
  font-size: 25px;
  position: relative;
  right: -2px;
  color: #bdc1ca;
}

.page-item:first-child .page-link span {
  font-size: 25px;
  position: relative;
  left: -1px;
  color: #bdc1ca;
}

.w-915 {
  width: 915px;
}

.active>.page-link,
.page-link.active {
  background-color: #10b701;
  font-weight: bold;
  border-radius: 5px;
  border-color: #10b701;
  color: #414141;
  height: 36px;
  width: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 14px;
}

.pagination .activePage {
  background-color: #0fb700;
  font-weight: bold;
}

.pagination div {
  font-size: 20px;
  width: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-right {
  text-align: right;
}

.clear-all {
  border-bottom: 1px solid gray;
  display: flex;
  justify-content: space-between;
  margin: 5px 10px;
}

.clear-all text {
  font-size: 14px;
  font-weight: bold;
}

.clear-all span {
  cursor: pointer;
}

.first-visit-button img {
  height: 20px;
  width: 20px;
}

.first-visit-button {
  display: flex;
  justify-content: center;
}

.centered-table thead th {
  text-align: center;
}

.form-check {
  display: flex;
  align-items: center;
}

.addIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.text-left {
  text-align: left;
}

.trade_data {
  width: 100%;
  text-align: left;
  transform: scale(0.85);
}

.trade_data .trade_data_first_section {
  /* min-height: 100%; */
  width: 215px;
  height: 240px;
}

.trade_data .trade_data_first_section h4 {
  font-size: 40px;
}

.trade_data section {
  background: #000000;
  padding: 22px 30px;
  border-radius: 10px;
  width: 115px;
  height: 75px;
  position: relative;
  margin-bottom: 16px;
}

.trade_data .stats_section h4 {
  font-size: 30px;
  color: #ffffff;
  font-weight: bold;
  font-family: "Graphik Bold";
}

.plus {
  font-weight: bold;
  font-family: "Graphik Bold";
}

.trade_data section h4 {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  font-family: "Graphik Bold";
}

/* .trade_data ul {
  margin: 0 -4px;
} */
.trade_data ul li {
  padding: 0 4px;
}

.d_stats {
  display: flex;
  flex-wrap: wrap;
}

.trade_data ul li.col-4 section h4 {
  font-size: 20px;
}

.trade_data section p {
  margin: 0;
  color: #fff;
  font-size: 14px;
  font-family: "Graphik Regular";
}

.mobile_version_screen {
  margin: 0 auto;
  max-width: 400px;
  padding-top: 35px;
  overflow-x: hidden;
  padding: 20px 20px;
  list-style: none;
}

.mobile_version_screen>section {
  width: 100%;
  text-align: center;
}

.mobile_version_screen>section h1 {
  font-family: "Graphik Bold";
  font-size: 40px;
  line-height: 1;
  margin: 0 0 10px;
}

/* .mobile_version_screen > section img {
    margin: 0 0 2px;
} */
.mobile_version_screen>section span {
  display: inline-block;
  font-size: 16px;
  font-family: "Graphik Bold", sans-serif;
  margin-bottom: 21px;
}

.center {
  text-align: center;
}

.mobile_version_screen .common_style small,
.mobile_version_screen .common_style span {
  display: block;
  color: #000;
  opacity: 0.3;
  text-align: center;
  font-size: 14px;
}

.mobile_version_screen .common_style small {
  margin: 20px 0 0;
}

.m-subscription {
  width: 340px;
}

.text-box-wrapper {
  width: 340px;
  height: 370px;
  margin: 0 auto;
}

.text-box-wrapper>div {
  flex-direction: column;
}

.text-box-wrapper .text-box {
  text-align: center;
}

.text-box-wrapper .text-box h5 span {
  display: block;
  font-weight: 900;
  font-size: 45px;
  letter-spacing: 2px;
}

.table-responsive table {
  text-align: left;
}

.table-responsive table tr {
  border-bottom: 1px solid #eaeaea;
}

.table-responsive table tr td:nth-child(even),
.table-responsive table tr th:nth-child(even) {
  background: #f8fcfd;
}

.table-responsive table tr th,
.table-responsive table tr td {
  padding: 16px 6px;
  background: #fcfefe;
}

.safe-checkout {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.safe-checkout img {
  max-width: 5.5rem;
  width: 50%;
}

.safe-checkout .payment-img {
  width: 100%;
}

.safe-checkout strong {
  cursor: pointer;
  text-decoration: underline;
}

.safe-checkout .big-icons {
  width: 100% !important;
}

.active-sub {
  background-color: #0fb700 !important;
}

.active-sub button {
  background-color: #ff0000 !important;
  border-width: 0px !important;
  color: white !important;
}

.apply-coupon-button {
  height: 40px;
  border-radius: 100px;
  padding: 0px 30px;
  background-color: #59b293;
  border: none;
}

.discount-field {
  height: 40px;
  min-height: 40px;
}

.logo {
  width: 110px;
}

.hover-bold:hover>p {
  font-weight: 900 !important;
  font-family: Graphik Bold, sans-serif;
}

.pb-20 {
  padding-bottom: 20px !important;
}

.pb-13 {
  padding-bottom: 13px;
}

.pt-14 {
  padding-top: 14px;
}

.py-40 {
  padding: 40px 0;
}

.stripe-icon {
  width: 120px;
}

.color-black {
  color: black !important;
}

.community-logo-uploading {
  object-fit: cover;
  object-position: center center;
  width: 100px;
}

.text-underline {
  text-decoration: underline;
}

.w-30px {
  width: 30px;
}

.closeBtn {
  width: 153px;
  height: 30px;
  padding: 5px !important;
}

.font-bold {
  font-family: Graphik Black, sans-serif;
}

.community-logo {
  width: 62px;
  /* height: 63px; */
}

.settingDropdown {
  position: relative;
}

.settingDropdown .descriptive {
  position: absolute;
  z-index: 100;
  top: 15px;
  left: 20px;
  font-size: 14px;
  font-family: "Graphik Regular";
  color: #707070;
}

.settingDropdown .css-fdbcdi-singleValue {
  color: #000;
  /* margin-top: 20px; */
  font-family: "Graphik Bold";
}

.setting-page-mt-80 {
  margin-top: 20px;
}

.subscription-price {
  display: inline-block;
}

.subscription-price.strike-through {
  text-decoration: line-through;
}

.password-hint {
  font-size: 14px;
  color: #888;
}

.line {
  border-bottom: 1px solid #ccc;
  margin: 10px 0;
  width: 100%;
  /* Equal width for both sections */
}

.tag-input {
  display: flex;
  flex-wrap: wrap;
  min-height: 48px;
  padding: 14px 20px;
  border: 1px solid #000;
  border-radius: 4px;
}

.tag-input input {
  flex: 1;
  border: none;
  /* height: 46px; */
  font-size: 14px;
  padding: 4px 0 0;
}

.tag-input input:focus {
  outline: transparent;
}

.h-162 {
  height: 162px !important;
}

.h-82 {
  height: 82px !important;
}

.w-692 {
  width: 692px !important;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 0 0 0;
}

.tag {
  width: auto;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 0 8px 0 12px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  background: #808080;
  margin-right: 21px;
  margin-top: 7px;
  margin-bottom: 10px;
}

.tag-title {
  margin-top: 3px !important;
  font-family: "Graphik Medium";
  font-size: 14px;
}

.tag-close-icon {
  display: block;
  width: 21px;
  height: 21px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  display: flex;
  margin-left: 12px;
  color: #000000;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-top: 0 !important;
}

.view-icon {
  position: absolute;
  top: 50%;
  right: 45px;
  transform: translateY(-50%);
  cursor: pointer;
}

.view-icon img {
  height: 28px;
  opacity: 0.4;
  width: 28px;
}

.trade_active {
  margin: 0 auto;
  max-width: 70%;
  /* max-width: 375px; */
  text-align: center;
  padding-top: 35px;
  overflow-x: hidden;
  padding: 20px 20px;
  margin-bottom: 8rem;
}

.trade_active>section h1 {
  font-family: "Graphik Bold", sans-serif;
  font-size: 40px;
  line-height: 1;
  margin: 0 0 10px;
}

.trial_section {
  margin: 0 auto;
  max-width: 600px;
  text-align: center;
  padding-top: 35px;
  overflow-x: hidden;
  padding: 20px 20px;
  margin-bottom: 8rem;
}

.trial_section>section h1 {
  font-family: "Graphik Black", sans-serif;
  font-size: 40px;
  line-height: 1;
  margin: 0 0 10px;
}

.business_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background: lightgray; */
}

.business_data {
  width: 50%;
  /* Adjust as needed */
  /* border: 1px solid #ccc; */
  padding: 10px 20px;
}

.business_data section {
  color: #000000;
  /* background: lightgray !important; */
}

.community_name {
  font-size: 20px;
  letter-spacing: 0px;
  text-transform: uppercase;
  color: #000000;
  font-weight: bold;
  font-family: "Graphik Regular";
  text-align: center;
}

.tickicon {
  width: 25px;
  height: 25px;
}

.crossicon {
  width: 25px;
  height: 25px;
}

.permission_options {
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 30px;
  color: #000000;
  font-weight: 500;
  font-family: "Graphik Regular";
}

.add-heading-border .page_title h4 {
  display: inline-block;
  position: relative;
}

.add-heading-border .page_title h4:before {
  content: "";
  position: absolute;
  left: 100%;
  top: 50%;
  transform: translate(15px, -50%);
  width: 78px;
  height: 1px;
  background: #707070;
}

.span-text {
  font-size: 14px;
  font-family: "Graphik Regular";
  font-weight: normal;
}

.trade_heading {
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #000000;
  font-family: "Graphik Bold";
  text-align: start;
}

.trade_heading_center {
  font-size: 16px;
  letter-spacing: 0px;
  line-height: 22px;
  color: #000000;
  font-family: "Graphik Bold";
  text-align: center;
}

.gray-background {
  background-color: #f2f2f2 !important;
}

.dark-gray-background {
  background-color: #4a4a4a !important;
}

.dark-gray-background p {
  color: white !important;
}

.gray-background * {
  color: #000 !important;
}

.red-background {
  background-color: #ff0000 !important;
}

.green-background {
  background-color: #0fb700 !important;
}

.red-text {
  color: red;
}

.atention_sign {
  position: relative;
}

.stats_section {
  position: relative;
  font-family: "Graphik";
}

.stats_section img {
  position: absolute;
  left: -10px;
  top: 10%;
}

.atention_sign img {
  position: absolute;
  left: -23px;
  top: 3px;
  max-width: 18px;
  margin-right: 1rem;
}

.member_info {
  width: 480px;
  height: 118px;
  border-radius: 16px;
  filter: drop-shadow(0px 27px 14px rgba(0, 0, 0, 0.09));
  background-color: #f2f2f2;
  border: 0.1px solid #c6c6c6;
  font-family: "Graphik Regular";
}

.stats_content {
  border-radius: 16px;
  /* filter: drop-shadow(0px 27px 14px rgba(0, 0, 0, 0.05)); */
  box-shadow: 0px 0px 17px 8px rgba(0, 0, 0, 0.05);
}

.cyan-background {
  background-color: #0fb700 !important;
}

.white-background {
  background-color: #ffffff !important;
}

.grey-background {
  background-color: #ededed !important;
}

.white_card.mbl img {
  width: 160px;
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.grey-background.mbl img {
  width: 145px;
}

.lh-27 {
  line-height: 27px;
}

.lh-25 {
  line-height: 25px;
}

.lh-24 {
  line-height: 24px;
}

.m-6863 {
  margin: 68px 0 63px !important;
}

.mt-27 {
  margin-top: 27px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-9 {
  margin-top: 9px;
}

.mt-19 {
  margin-top: 19px;
}

.mt-123 {
  margin-top: 106px;
}

.mt-128 {
  margin-top: 120px;
}

.mt-33 {
  margin-top: 33px;
}

.mb-37 {
  margin-bottom: 37px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-38 {
  margin-bottom: 38px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-206 {
  margin-bottom: 206px;
}

.mt-23 {
  margin-top: 23px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-51 {
  margin-top: 51px;
}

.mt-28 {
  margin-top: 28px;
}

.mt-41 {
  margin-top: 41px !important;
}

.mt-36 {
  margin-top: 36px;
}

.mb-62 {
  margin-bottom: 62px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-26 {
  margin-bottom: 26px;
}

.mb-41 {
  margin-bottom: 41px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-27 {
  margin-bottom: 27px;
}

.mb-21 {
  margin-bottom: 21px;
}

.mb-17 {
  margin-bottom: 17px;
}

.mb-19 {
  margin-bottom: 19px !important;
}

.mb-9 {
  margin-bottom: 5px !important;
}

.nextSign {
  width: 98px;
  height: 34px;
  padding: 5px 20px;
  border-radius: 4px;
  font-size: 15px;
}

.mb-49 {
  margin-bottom: 49px;
}

.mb-51 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mt-93 {
  margin-top: 93px;
}

.mx-527 {
  max-width: 527px !important;
}

.mx-554 {
  max-width: 554px;
}

.mx-469 {
  max-width: 469px;
}

.mb-46 {
  margin-bottom: 46px;
}

.mx-536 {
  max-width: 536px;
}

.mx-407 {
  max-width: 407px;
}

.mx-562 {
  max-width: 562px;
}

.mx-556 {
  max-width: 556px;
}

.mx-691 {
  max-width: 691px;
}

.mt-slider {
  margin-top: 55px;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 70px;
  padding-bottom: 40px;
}

.riskModal {
  padding: 51px 10px 71px !important;
}

.mt-41 {
  margin: 41px 0;
}

.mb-56 {
  margin-bottom: 56px;
}

.outletLayout {
  margin-top: 5px !important;
  position: absolute;
  right: 0;
  top: 5px;
  transform: scale(0.9) translate(0, 0);
  height: 100%;
}

.wrapper {
  /* transform: scale(0.9); */
  margin-top: 1.5rem;
}

.grey-background.mbl button {
  padding: 5px 8px;
  border-radius: 6px;
  background: black;
  color: white !important;
  margin-top: 5px;
  border: none;
}

.grey-background.mbl button:focus {
  color: white !important;
  background-color: #0fb700;
  outline: none;
}

.grey-background.mbl button:hover {
  color: white !important;
  background-color: #0fb700;
}

.cyanCircle img {
  margin-top: 35px;
}

.cyan-color {
  color: #0fb700 !important;
}

.gray-color {
  color: #434343 !important;
}

.light-gray-color {
  color: #707070 !important;
}

.white-color {
  color: #ffffff !important;
}

.border-right-wrap {
  position: relative;
}

.border-right-wrap::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translate(10px);
  top: 15px;
  width: 1px;
  height: calc(100% - 35px);
  background-color: #fff;
}

.main_heading {
  font-size: 40px;
  letter-spacing: 1px;
  line-height: 40px;
  text-transform: uppercase;
  color: #000000;
  font-weight: 800;
  font-family: "Graphik Bold";
}

.get_paid {
  margin: 0 auto;
  width: 400px;
  padding-top: 35px;
  overflow-x: hidden;
  padding: 0 30px;
  margin-bottom: 8rem;
  text-align: start;
}

/* .checkmark_sign {
    position: relative;
}
.checkmark_sign input {
    position: absolute;
    top: 5%;
    left: -30px;
} */
.note {
  width: 339px;
  height: 156px;
  border-radius: 16px;
  filter: drop-shadow(0px 27px 14px rgba(0, 0, 0, 0.05));
  background-color: #f2f2f2;
  border: 0.30000001192092896px solid #c6c6c6;
  text-align: start;
  padding-top: 1rem;
  padding-left: 1rem;
}

.note_msg {
  opacity: 0.5;
  font-size: 16px;
  letter-spacing: 0px;
  /* line-height: 22px; */
  color: #000000;
}

.community_link {
  background: #ffffff;
  color: #000;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 17px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 20px;
}

.community_link button {
  padding: 6px 18px;
  border-radius: 6px;
  background: black;
  color: white !important;
  font-size: 13px;
  border: 0;
  font-family: "Graphik Medium", sans-serif;
}

.community_link button:focus {
  color: white !important;
  background-color: #0fb700;
  border: none;
  outline: none;
}

.community_link button:hover {
  color: white !important;
  background-color: #0fb700;
  border: none;
}

.business_community {
  background: #ffffff;
  color: #000;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 0px 17px 8px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
}

.business_community button {
  border-radius: 6px;
  background: black;
  color: white !important;
  font-size: 13px;
  border: 0;
  padding: 6px 8px;
  font-family: "Graphik Medium", sans-serif;
}

.business_community button:hover {
  color: white !important;
  background-color: #0fb700;
  border: none;
}

.trade_data section p.community_heading {
  font-size: 18px;
  font-family: "Graphik Bold";
}

.community_heading {
  font-size: 18px;
  font-family: "Graphik Bold";
}

.community_icon {
  position: absolute;
  right: 30px;
  top: 30%;
}

.empty_space {
  height: 240px;
  width: 215px;
}

.form-control {
  appearance: auto;
}

.form-control.focused {
  border-color: #707070;
  border-width: 3px;
  border-style: solid;
  border-radius: 5px;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

/* For browsers that don't support the :-webkit-autofill pseudo-element */
input:-internal-autofill-selected {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  transition: background-color 5000s ease-in-out 0s;
}

.less_content {
  min-height: calc(100vh - 100px);
}

.custom-tooltip {
  background: none !important;
  border: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  /* Inherit the font size from the parent */
  color: inherit;
  /* Inherit the text color from the parent */
}

.newCreateButton {
  width: 100%;
  height: 48.72px;
  background-color: #0fb700;
  border-radius: 8px;
  color: white;
  font-family: "Graphik Medium";
  font-size: 17px;
  outline: 0;
  border: none;
  box-shadow: 0px 6px 11px -3px #80808070;
}

.newCreateButton:hover {
  background-color: #000;
}

.newCreateButtonWhite {
  width: 100%;
  height: 48.72px;
  background-color: #fff;
  border-radius: 8px;
  color: #000;
  font-family: "Graphik Regular";
  font-size: 17px;
  outline: 0;
  border: 0.5px solid #c6c6c6;
  box-shadow: 0px 6px 11px -3px #80808070;
  position: relative;
}

/* .newCreateButtonWhite:hover{
    background-color: #0FB700;
} */

.newCreateButtonWhite img {
  margin-left: 5px;
}

.newCreateButtonWhite span {
  background-color: #000;
  color: #fff;
  position: absolute;
  top: -10px;
  left: 5px;
  font-family: "Graphik Regular" !important;
  font-weight: 400;
  font-size: 11px !important;
  padding: 2px 7px 1px 7px;
  border-radius: 4px;
  text-transform: uppercase;
}

.newSignLink {
  font-family: "Graphik Medium";
  font-size: 14px;
  color: #707070;
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newSignLink:focus,
.newSignLink:active {
  display: flex;
}

.newSignLink:focus-visible {
  outline: 0;
}

.newSignLink:hover {
  text-decoration: underline;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.estimate_amount {
  font-size: 40px;
  text-align: center;
}

.line-above-below {
  position: relative;
}

.line-above-below::before,
.line-above-below::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 2px;
  background-color: #ffffff;
  left: 50%;
  transform: translateX(-50%);
}

.line-above-below::before {
  top: 0;
}

.line-above-below::after {
  bottom: 0;
}

.invest_amount {
  background: #000000;
  color: #fff;
  border-radius: 16px;
  position: relative;
  width: 340px;
  margin-left: 10px;
}

.toast-message {
  font-size: 16px;
  text-align: center;
  background-color: #000;
  color: #38c784;
  width: 230px;
  margin: 5px auto;
  font-family: "Graphik Medium";
}

.toast-message .Toastify__toast-icon {
  display: none;
}

.toast-message .Toastify__close-button {
  display: none;
}

/* .main_body.common_style {
    height: 800px;
} */
.login_card {
  max-width: 444px;
  min-width: 444px;
  margin: 0 auto;
  border: 2px solid #d6d6d6;
  padding: 97px 57px 73px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.verify_card {
  padding: 97px 57px 76.6px !important;
}

.verify_card_name {
  padding: 55px 57px 76.5px !important;
}

.account_card {
  padding: 97px 57px 92px !important;
}

.signup_card {
  padding: 55px 57px 73px !important;
}

.signin_card {
  padding: 50px 25px 70px;
}

input[type="password"]::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
}

.tradeScreen {
  padding: 55px 50px 76px;
}

.code-incorrect input {
  border: 1px solid red !important;
  color: red !important;
}

.code-incorrect label {
  color: red !important;
}

.login_card h2 {
  text-align: center;
  margin-bottom: 5px;
}

.login_card .custom-btn {
  border-radius: 5px;
  color: white;
  font-size: 16px;
  padding: 7px 30px;
  width: 130px;
}

.login_card .custom-btn:hover {
  color: white;
  background-color: black;
  border-color: black;
}

.login_footer {
  max-width: 440px;
  margin: 0 auto;
  padding: 25px 0 0;
  font-size: 12px;
}

.login_footer ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.login_footer ul li {
  margin-left: 31px;
}

.login_footer ul li a {
  color: #707070 !important;
  font-size: 12px;
  opacity: 70%;
}

.login_footer p {
  color: #707070 !important;
  opacity: 70%;
}

.login_footer p a {
  color: #707070 !important;
  font-weight: bold;
}

.h-85 {
  margin-top: 85px;
}

.sidebar {
  background-color: #ffffff;
  /* border-radius: 20px 0 0 20px; */
  position: fixed;
  height: 100%;
  top: 50%;
  /* padding-right: 10px; */
  width: 247px;
  display: flex;
  /* justify-content: space-between; */
  flex-direction: column;
  transform: translateY(-50%);
  overflow: scroll;
}

.sidebar {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

.sidebar::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.adminSidebar {
  overflow-y: scroll;
  overflow-x: hidden;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
}

.sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}

.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

.sidebar .business_name {
  /* margin: 0 30px 25px; */
  padding: 65px 0 65px;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  opacity: 0.8;
  font-family: "Graphik Bold";
  /* border-bottom: 1px solid #e2e2e2; */
}

.height-set-for-nav {
  height: 85vh;
  overflow-y: scroll;
}

.height-set-for-nav::-webkit-scrollbar {
  width: 0;
}

.height-set-for-nav::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 20px;
}

.height-set-for-nav::-webkit-scrollbar-track {
  background-color: transparent;
}

.height-set-for-nav::-webkit-scrollbar-thumb:hover {
  background-color: transparent;
}

.sidebar .cm_business_name {
  font-size: 16px;
  /* margin: 0 30px 25px; */
  padding: 65px 0 65px;
}

.sidebar .img_margin {
  width: 20px;
  height: 20px;
  margin-right: 20px;
}

.sidebar .lines {
  background: #e2e2e2;
  margin: 15px auto 10px;
  width: 79%;
  height: 1px;
  display: block;
}

.sidebar .powered {
  margin: 0px 30px 15px;
  padding: 15px 0;
  text-align: left;
  font-size: 10px;
  font-weight: 100;
  opacity: 0.3;
  line-height: 12px;
  position: absolute;
  bottom: 0%;
  left: 37%;
  transform: translate(-50%, -0%);
}

.sidebar .powered span {
  font-weight: bold;
  font-family: "Graphik Bold", sans-serif;
  font-size: 14px;
}

.statusTrading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1.03px solid #e2e2e2;
  padding-bottom: 17px;
  margin-bottom: 33px;
}

.statusTrading p {
  font-size: 14px;
  font-family: "Graphik Regular";
}

/* div.reactour__popover {
    width: 500px;
} */

.form-input {
  position: relative;
  width: 100%;
}

.form-input input::placeholder {
  opacity: 0;
}

.form-input input {
  width: 100%;
  height: 54px;
  font-size: 14px;
  padding: 12px 15px;
  outline: none;
  border: 1px solid black;
  box-sizing: border-box;
  border-radius: 6px;
  font-family: "Graphik Regular";
}

.form-input label {
  position: absolute;
  left: 14px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 14px;
  color: #7b7777;
  letter-spacing: 1px;
  transition: 0.2s;
  cursor: pointer !important;
  /* font-family: "Roboto", sans-serif; */
  /* font-weight: 600; */
}

.form-input input:focus+label,
.form-input input:not(:placeholder-shown)+label {
  top: 0;
  font-size: 0.8rem;
  color: gray;
  background: #fff;
  padding: 0 7px;
  line-height: normal;
}

.form-input input:focus {
  border: 2px solid black;
}

.customInput {
  border-radius: 10px;
  position: relative;
  background-color: white;
  box-shadow: 0 6px 20px 3px #c6c6c642;
  padding: 25px 15px 15px;
}

.customInput label {
  font-size: 14px;
  font-family: "Graphik Regular";
  color: #707070;
  padding-top: 0;
  padding-left: 0 !important;
}

.customInput input {
  border: none;
  font-family: "Graphik Bold";
  border-radius: 0;
  width: 100%;
  font-size: 15px !important;
  height: auto !important;
  min-height: auto !important;
  color: #000 !important;
  outline: none;
  padding-top: 12px !important;
  padding-bottom: 5px !important;
  padding-left: 2px !important;
  font-weight: 800;
  background-color: transparent;
}

.customInput .form-floating>.form-control-plaintext~label,
.customInput .form-floating>.form-control:focus~label,
.customInput .form-floating>.form-control:not(:placeholder-shown)~label,
.customInput .form-floating>.form-select~label {
  color: #000;
  transform: scale(1) translateY(-0.89rem) translate(0);
}

.payment .inputDiv .form-floating>.form-control-plaintext~label,
.payment .inputDiv .form-floating>.form-control:focus~label,
.payment .inputDiv .form-floating>.form-control:not(:placeholder-shown)~label,
.payment .inputDiv .form-floating>.form-select~label {
  transform: scale(0.8) translateY(-1.5rem) translateX(0);
}

.customInput .form-floating label::after {
  content: none !important;
}

.customInput .editIcon {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 99;
}

.customInput .editIcon img {
  width: 26px;
  height: 26px;
}

.common_style .fs15 {
  font-size: 15px !important;
}

.securitytitle h3 {
  font-size: 20px;
  font-family: "Graphik Bold";
  color: #000000;
  margin-bottom: 20px;
}

.closeTrade {
  /* margin: 30px 0; */
  padding: 41px 90px 0 90px;
  max-width: 691px;
}

.closeTrade h3 {
  font-size: 20px;
  font-family: "Graphik Bold";
  color: red;
  margin-bottom: 10px;
}

.closeTrade p {
  font-size: 14px;
  font-family: "Graphik Regular";
  color: red;
  margin-bottom: 28px;
}

.closeTrade button {
  color: white;
  border: 1px solid red;
  background-color: red;
  border-radius: 5px;
  padding: 5px 10px;
  width: 189px;
  height: 31px;
  font-size: 13px;
  font-family: "Graphik Medium", sans-serif;
}

.closeTrade button:focus {
  background-color: black;
  border: 1px solid black;
  color: white;
  outline: 0;
}

.closeTrade button:hover {
  background-color: black;
  border: 1px solid black;
  color: white;
}

.info-icon {
  cursor: pointer;
}

/* Styles for the tooltip */
.tooltip {
  position: absolute;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  z-index: 1;
  display: none;
  /* Initially hide the tooltip */
}

.tooltip.visible {
  display: block;
}

.arrowBtn {
  cursor: pointer;
  border-radius: 100px;
  background: #0fb700;
  padding: 10px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.arrowBtn:hover {
  background-color: white;
  filter: none;
  transform: rotate(45deg);
}

.arrowBtn:hover img {
  filter: none;
}

.arrowBtn img {
  filter: brightness(2);
  width: 18px;
  margin-top: -4px;
  margin-left: 3px;
}

.moreButtonIcon {
  position: absolute;
  bottom: 20px;
  right: 20px;
}

.socialIcons {
  display: flex;
  align-items: center;
}

.socialIcons .block {
  display: flex;
  flex-direction: column;
}

.socialIcons .block img {
  width: 27px;
  height: 27px;
  cursor: pointer;
  margin: 2px;
}

.paginationOf {
  font-size: 14px;
  font-weight: 500;
  color: #414141;
}

.topLines {
  width: 100%;
  height: 1px;
  /* background-color: #e2e2e2; */
  background-color: transparent;
  display: block;
  margin: 40px 0;
}

.cursorNotAllowed {
  cursor: not-allowed !important;
}

.payment-complete {
  -webkit-box-shadow: inset 0px 0px 0px 5px #0fb700;
  -moz-box-shadow: inset 0px 0px 0px 5px #0fb700;
  box-shadow: inset 0px 0px 0px 5px #0fb700;
}

.payment-complete-border {
  -webkit-box-shadow: inset 0px 0px 0px 5px red;
  -moz-box-shadow: inset 0px 0px 0px 5px red;
  box-shadow: inset 0px 0px 0px 5px red;
}

.color-white {
  color: white !important;
}

.color-transparent {
  color: transparent !important;
}

.fs19 {
  font-size: 19px !important;
}

.letter-spacing-16 {
  letter-spacing: 0.16px;
}

.letter-spacing-150 {
  letter-spacing: 1.5px;
}

.graph_info {
  position: relative;
}

.articleRelative>div {
  position: unset;
  padding: 30px 0;
}

.articleRelatives>div {
  position: unset;
  padding: 30px 55px;
}

.deleteMember button {
  padding: 3px 25px !important;
  height: 31px;
  width: 87px;
  border-radius: 4px !important;
}

.deleteMember .redBtn .red-btn:hover {
  border: 1px solid #000;
  color: #fff;
  background-color: #000;
}

.form-control.multiselect {
  width: 285px;
  padding: 11px 15px;
  outline: none;
  border-radius: 10px;
  border: 1px solid #ccc;
  font-size: 14px;
  font-family: "Graphik Regular";
}

.closeService .red-btn:hover {
  border: 1px solid #000;
  color: #fff;
  background-color: #000;
}

.connectionBlock {
  max-width: 537px;
}

.tradeArticle>div {
  padding-bottom: 0;
}

.graphWidth {
  width: 100%;
}

.cryptoBtn button {
  height: 30px !important;
  padding: 5px 7px !important;
}

.centered-up {
  width: 100%;
  height: calc(100vh - 43px);
  position: relative;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  width: 100%;
  text-align: center;
  margin: 0 auto;
}

.active-trade-fix-width {
  width: 295px;
  margin: 0 auto;
}

.custom-trade-list {
  /* list-style-type: disc; */
}

.custom-trade-list li::marker {
  color: #0fb700;
}

.custom-trade-list .trade_heading_center,
.custom-trade-list p {
  text-align: center;
}

.custom-trade-list .trade_heading_center {
  display: flex;
  align-items: start;
  justify-content: center;
}

.custom-trade-list .circle-dot {
  display: flex;
  width: 5px;
  height: 5px;
  border-radius: 50rem;
  background-color: #0fb700;
  margin-right: 6px;
  margin-top: 8px;
}

.custom-trade-list .trade_heading_center .text {
  width: fit-content;
  font-size: 15px;
  letter-spacing: 0px;
  line-height: normal;
  color: #000000;
  font-family: "Graphik Bold";
  text-align: center;
  margin-bottom: 10px;
}

/* Trade History Filter button */
.filterAccordian {
  border: 0;
}

.filterAccordian button,
.filterAccordian input {
  font-size: 14px;
}

.filterAccordian button:focus {
  box-shadow: none;
  border: none;
}

.react-datepicker__tab-loop {
  position: absolute;
}

/* Plan Card CSS */
.plan-card {
  /* border: 1px solid #ebebeb; */
  filter: drop-shadow(0px 6px 6px rgba(175, 175, 175, 0.16));
  background: #ffffff;
  border-radius: 8px;
  position: relative;
  padding: 20px 50px;
  /* width: 275px; */
  margin: 0 auto;
  /* margin-top: 20px; */
}

.plan-card .line-for-web {
  display: block;
  width: 70%;
  height: 0.5px;
  background-color: #ebebeb;
  margin: 15px auto;
}

.toggle-month-box {
  background-color: #ededed;
  width: fit-content;
  /* margin: 0 auto; */
  border-radius: 50rem;
  padding: 6px;
  margin-bottom: 40px;
}

.toggle-month-box button {
  background-color: #ededed;
  border: none;
  padding: 6px 20px;
  border-radius: 50rem;
  width: 100px;
  line-height: 14px;
  font-size: 12px;
  color: #000;
}

.sub-info-end {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 2px;
}

.sub-head-text {
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
}

.sub-head-light-text {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  color: #000000;
  opacity: 0.4;
  white-space: nowrap;
}

.sub-head-text.margin {
  margin-left: 35px;
}

.sub-info-btn img {
  height: 22px;
  position: relative;
  top: -1px;
}

.custom-radio-item {
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}

.custom-radio-item.last-item {
  border-bottom: none;
  margin-bottom: 50px;
}

.custom-radio-item input[type="radio"]:checked,
.custom-radio-item input[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}

.custom-radio-item input[type="radio"]:checked+label,
.custom-radio-item input[type="radio"]:not(:checked)+label {
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  line-height: 15px;
  display: inline-block;
  color: #666;
  width: 100%;
}

.custom-radio-item input[type="radio"]:checked+label:before,
.custom-radio-item input[type="radio"]:not(:checked)+label:before {
  content: "";
  position: absolute;
  left: 0;
  top: -2px;
  width: 23px;
  height: 23px;
  border: 2px solid #9a9cb8;
  border-radius: 100%;
  background: #fff;
}

.custom-radio-item input[type="radio"]:checked+label:before {
  background-color: #11b700;
  border-color: #11b700;
}

.custom-radio-item input[type="radio"]:checked+label .check-icon {
  display: block;
  position: absolute;
  top: -1px;
  left: 0px;
  font-size: 22px;
  color: #fff;
}

.custom-radio-item input[type="radio"]:not(:checked)+label .check-icon {
  display: none;
}

.custom-radio-item input[type="radio"]:checked+label:after,
.custom-radio-item input[type="radio"]:not(:checked)+label:after {
  content: "";
  width: 23px;
  height: 23px;
  position: absolute;
  top: 1px;
  left: 1px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.custom-radio-item input[type="radio"]:not(:checked)+label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.custom-radio-item input[type="radio"]:checked+label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.custom-radio-item .content-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.custom-radio-item .content-box .main-price {
  font-size: 18px;
  color: #000;
  /* font-weight: 700; */
  font-family: "Graphik Bold", sans-serif;
  /* font-family: "Graphik Regular"; */
  width: 60%;
  white-space: nowrap;
}

.custom-radio-item .content-box .divider {
  font-size: 18px;
  color: #000;
  /* font-weight: 700; */
  font-family: "Graphik Bold", sans-serif;
  width: 1%;
}

.custom-radio-item .content-box .sub-price {
  font-size: 18px;
  color: #000;
  /* font-weight: 400; */
  width: 39%;
  font-family: "Graphik Bold", sans-serif;
}

.desktop-plan .custom-radio-item .content-box .main-price {
  width: 15%;
}

.desktop-plan .custom-radio-item .content-box .divider {
  width: 1%;
}

.desktop-plan .custom-radio-item .content-box .sub-price {
  width: 20%;
  padding-left: 0px;
}

.desktop-plan .custom-radio-item .content-box .button-side {
  width: 45%;
}

/* .desktop-plan .custom-radio-item .content-box .button-side button {
  width: 180px;
} */

.sub-del-modal .deleteMember button {
  width: 87px !important;
}

.desktop-plan .custom-radio-item input[type="radio"]:checked+label:before,
.desktop-plan .custom-radio-item input[type="radio"]:not(:checked)+label:before {
  top: 7px;
}

.desktop-plan .custom-radio-item input[type="radio"]:checked+label .check-icon {
  top: 7px;
}

.desktop-plan .custom-radio-item:last-of-type {
  border-bottom: 0;
}

.top-tag {
  background-color: #0fb700;
  width: auto;
  padding: 1px 25px 1px 25px;
  margin: 0 auto;
  border-radius: 50rem;
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.top-tag p {
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
}

.title-box {
  text-align: center;
  /* padding-bottom: 20px; */
}

.title-box .title {
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top: 5px;
}

.title-box .price {
  font-size: 32px;
  font-weight: 700 !important;
  margin-bottom: 0;
  /* font-family: "Graphik Medium"; */
  font-family: "Graphik Bold";
  letter-spacing: 0px;
  line-height: normal;
}

.title-box .price span {
  font-size: 12px;
  letter-spacing: normal;
}

.list-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 20px; */
}

.list-box ul li {
  font-size: 14px;
  font-weight: 400;
}

.bottom-light-text {
  text-align: center;
  font-size: 10px;
  color: #707070 !important;
  opacity: 70%;
  margin-bottom: 10px;
  margin-top: 20px;
}

.plan-card .button {
  background-color: #0fb700;
  color: #fff;
  text-align: center;
  /* padding: 7px 25px;
  border-radius: 8px; */
  /* height: 48.72px; */
  border: none;
  /* width: 80%; */
  border-radius: 4px;
  padding: 11.5px 12px;
  width: 130px;
  transition: 0.3s all ease;
  font-family: "Graphik Medium";
  font-size: 14px;
}

.plan-card .button:focus {
  background-color: #000;
  outline: 0;
}

.plan-card .button:hover {
  background-color: #000;
}

.payment-option-img {
  width: 200px;
  margin: 0 auto;
  display: block;
  margin-bottom: 20px;
}

.secure-payment-text {
  text-align: center;
  font-size: 12px;
  color: #707070 !important;
  opacity: 70%;
  margin-bottom: 0px;
}

.tier-info-box {
  filter: drop-shadow(-5px 15px 10px rgba(0, 0, 0, 0.2));
  border-radius: 5px;
}

.tier-info-box .cls-btn {
  background-color: transparent;
  border: none;
  margin-right: 5px;
}

.payment-method-images {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-method-img {
  width: auto;
  height: 30px;
  margin: 0 5px;
}

.payment-method-img.td-secure {
  width: 21px;
  aspect-ratio: auto 36 / 30;
  height: 16px;
}

.payment-method-img.master-cerd {
  width: 55px;
  aspect-ratio: auto 78 / 58;
  height: 39px;
}

.payment-method-img.visa-verified {
  height: 27px;
  aspect-ratio: auto 57 / 43;
  width: 38px;
}

.payment-method-img.stripe {
  width: 37px;
  aspect-ratio: auto 51 / 43;
  height: 16px;
  opacity: 0.4;
}

/* .left-adjustment-copyTrade {
    position: relative;
    left: -75px;
} */

.success-loader {
  height: 120px !important;
  max-height: 120px !important;
}

.error-loader {
  height: 248px !important;
  max-height: 248px !important;
}

.connecting-loader {
  height: 60px !important;
  max-height: 60px !important;
}

.billingBox {
  padding: 20px 0 0 0;
  border-top: 1px solid #f5f5f5;
}

.billingHeding {
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  line-height: 18px;
  font-style: normal;
  text-align: left;
  color: #000000;
  margin-bottom: 30px;
}

.billingTable.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-bg-type: rgb(238 242 245 / 40%) !important;
}

.billingTable tbody,
.billingTable td,
.billingTable tfoot,
.billingTable th,
.billingTable thead,
.billingTable tr {
  border-style: none;
}

.billingTable.table td:first-child,
.billingTable.table td:last-child {
  color: #414141;
}

.billingTable td {
  padding: 20px 0;
  font-family: "Graphik Regular", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  /* letter-spacing: 25px; */
  color: #414141;
  vertical-align: middle;
}

.billingTable th {
  padding: 15px 0;
  font-family: "Graphik Medium", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #000;
  width: 500px;
}

.billingTable .download-btn {
  border: none;
  background: transparent;
}

.billingTable .download-btn:focus {
  box-shadow: none;
  outline: none;
}

.billingTable .text-green {
  color: #00ca39;
}

.TradeHistoryTable th {
  text-align: left;
  color: #000;
  font-size: 14px;
  font-family: "Graphik Bold", sans-serif;
}

.TradeHistoryTable th span {
  padding: 15px 0;
  font-family: "Graphik Bold", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #000;
  width: 500px;
}

.TradeHistoryTable td span {
  padding: 20px 0;
  font-family: "Graphik Regular", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  /* letter-spacing: 25px; */
  color: #414141;
  vertical-align: middle;
}

.TradeHistoryTable td {
  padding: 0;
  text-align: left;
}

.TradeHistoryTable .dark-text p {
  color: #000;
}

.TradeHistoryTable .dark-text p span {
  color: #000;
}

.TradeHistoryTable td p {
  padding: 20px 0;
  font-family: "Graphik Regular", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  /* letter-spacing: 25px; */
  color: #414141;
  vertical-align: middle;
  border: 0;
  line-height: normal;
}

.TradeHistoryTable .number h4 {
  font-family: "Graphik Regular", sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  text-align: left;
  /* letter-spacing: 25px; */
  color: #10b701;
  vertical-align: middle;
  border: 0;
  line-height: normal;
}

.TradeHistoryTable .number span {
  color: #10b701;
}

.TradeHistoryTable .number.active h4 {
  color: #fc0303;
}

.TradeHistoryTable .number.active span {
  color: #fc0303;
}

.membersOverview th {
  color: #000;
  font-weight: 500;
}

.membersOverview td {
  color: #000;
}

.membersOverview td.light {
  color: #414141;
}

.membersOverview td .download-btn.gapleft {
  margin-right: 7px;
}

.membersOverview td .download-btn.gapleft.msj-btn img {
  width: 19px;
  height: 19px;
}

.membersOverview td .download-btn.gapright {
  margin-left: 7px;
}

.membersOverview td .download-btn.gapright.cros-btn img {
  width: 15px;
  height: 15px;
}

.connection .title.member-heaidng {
  margin-bottom: 0;
}

.connection .title.member-heaidng h3 {
  font-size: 14px;
  margin-bottom: 0;
}

.text-limit {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.subscriptionMember-topStats {
  padding-bottom: 40px;
  border-bottom: 1px solid #e2e2e2;
  margin-bottom: 31px;
}

.subscriptionMember-topStats .title {
  font-family: "Graphik Medium", sans-serif;
  font-size: 20px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #06152b;
}

.subscriptionMember-topStats .desc {
  font-family: "Graphik Regular", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.subscriptionMember-topStats .desc .green-text {
  font-family: "Graphik Medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  color: #00ca39;
}

.subscriptionMember-topStats .numbers {
  font-family: "Graphik Medium", sans-serif;
  font-size: 28px;
  font-weight: normal;
  line-height: 50px;
  font-style: normal;
  text-align: center;
  color: #06152b;
}

@media only screen and (max-width: 370px) {
  .membersOverview .d-display {
    display: flex;
    gap: 3px;
  }

  .membersOverview td .download-btn.gapleft {
    margin-right: 0px;
  }

  .membersOverview td .download-btn.gapright {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 362px) {
  .subscriberCard {
    padding: 20px 24px;
  }

  .subscriptionMember-topStats .title {
    font-size: 16px;
  }

  .subscriptionMember-topStats .desc {
    font-size: 12px;
  }
}

@media only screen and (max-width: 767px) {
  .tier .form-control.mb-0-set {
    margin-bottom: 0;
  }

  .tier-bottom-btn-box {
    margin-bottom: 41px;
  }

  .tier-save-btn.next {
    width: 294px;
    height: 48.7px;
    font-size: 15px !important;
  }

  .tier .form-control {
    margin-bottom: 7px !important;
  }

  .cl-tier-heading {
    margin-bottom: 19px;
  }

  .text-limit {
    max-width: 75px;
  }

  .membersOverview th {
    padding-left: 5px;
    padding-right: 5px;
  }

  .membersOverview td {
    padding-left: 5px;
    padding-right: 5px;
  }

  .TradeHistoryTable .dark-text p span {
    display: block;
    padding-bottom: 0px;
  }

  .TradeHistoryTable .dark-text p span {
    display: block;
    padding-top: 0px;
    text-align: left;
  }

  .TradeHistoryTable td .pb-set {
    padding-bottom: 0;
  }

  .TradeHistoryTable .d-set {
    display: none;
  }

  .plan-card {
    padding: 20px 20px;
  }

  /* .left-adjustment-copyTrade {
        position: unset;
        left: unset;
    } */

  .discountBtn button {
    margin-left: auto;
  }

  .sub-head-text {
    font-size: 10px;
  }

  .sub-head-text.margin {
    margin-left: 37px;
  }

  .custom-radio-item .content-box .main-price {
    font-size: 16px;
  }

  .custom-radio-item .content-box .sub-price {
    font-size: 14px;
  }

  .cl-iter-action-box {
    right: 1px;
    top: -15px !important;
  }

  .billingTable th {
    font-size: 12px;
    width: auto;
  }

  .TradeHistoryTable th span {
    font-size: 12px;
    width: auto;
  }

  .billingTable td {
    font-size: 10px;
  }
}

@media (max-width: 1450px) {
  /* .copyTradeTab {
        padding: 0 22px;
    } */
  /* .connection {
        padding: 0 45px;
    } */
}

@media screen and (max-width: 1200px) {
  .discount {
    width: 100%;
  }

  .onboard-modal {
    transform: translate(-57%, -35%) !important;
  }
}

@media screen and (max-width: 1366px) {
  .sidebar .business_name {
    margin: 0px 30px 10px;
  }

  .sidebar .cm_business_name {
    font-size: 16px;
    /* margin: 0 30px 25px; */
    padding: 65px 0 65px;
  }

  .site_nav>ul>li a {
    min-height: 40px;
  }
}

@media (min-width: 480px) {
  .trade_data {
    margin-top: 10px;
    transform: scale(1);
  }
}

@media (max-width: 600px) {
  .billinPageMargin {
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
  }

  .custom-radio-item input[type="radio"]:checked+label,
  .custom-radio-item input[type="radio"]:not(:checked)+label {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    line-height: 18px;
    display: inline-block;
    color: #666;
    width: 100%;
  }

  .stats_li {
    flex-direction: column;
    width: 100%;
  }

  .membersOverview .card .contentBlock {
    display: block;
  }

  .membersOverview .card .content p:first-child {
    justify-content: flex-start;
    margin-top: 0.5rem;
  }

  .accountCard .account p:first-child {
    width: 100%;
  }

  .accountCard .account>div {
    flex-wrap: wrap;
    gap: 5px;
  }

  .tradeHistory .card .content span {
    margin-bottom: 5px;
  }

  .membersOverview .card .content span {
    margin-bottom: 5px;
  }

  .stats_li section {
    margin-bottom: 1rem;
    height: 110px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .stats_li section h4 {
    font-size: 40px;
    font-weight: 500;
    font-family: "Graphik Regular";
  }

  .haveSeenCards {
    flex-wrap: wrap;
  }

  .haveSeen .card {
    width: 100%;
    height: auto;
  }

  .url .card .socialIcons {
    margin-bottom: 0;
  }

  .tier .card .block .form-control {
    width: 100%;
  }

  .title h3 {
    line-height: 27px !important;
  }

  .membersOverview .card {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .membersOverview .card .number {
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }

  .tier-info-box {
    right: 41px !important;
    top: 120px !important;
    width: 12rem !important;
  }

  .trade_active {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .trade_active .newCreateButton {
    width: 295px !important;
  }

  .toggle-month-box button {
    width: 75px;
    padding: 5px 0;
    font-size: 12px;
  }

  .custom-radio-item .content-box .main-price {
    font-size: 14px;
    /* font-weight: 900; */
    font-family: "Graphik Bold", sans-serif;
    /* font-family: "Graphik Regular"; */
  }

  .custom-radio-item .content-box .sub-price {
    font-size: 14px;
    font-weight: 500;
    padding-left: 10px;
  }

  .plan-card .mb-25 {
    margin-bottom: 5px;
  }

  .custom-radio-item.last-item {
    margin-bottom: 80px;
  }

  .plan-card .button {
    width: 100%;
    font-size: 17px;
    font-weight: 500;
    margin-left: 0 !important;
  }

  .bottom-light-text {
    margin-bottom: 9px;
  }

  .secure-payment-text {
    margin-top: 15px;
    font-size: 8px;
  }

  .tier-info-box p {
    font-size: 12px;
  }
}

@media screen and (max-width: 525px) {
  .tier .card.subsCard .block {
    width: 33%;
  }

  .tier .card.subsCard .edit-button-block {
    width: 10%;
  }

  .appBtns button {
    width: 27% !important;
  }

  .tradeHistory {
    /* display: none; */
  }

  .tradeHistoryMbl {
    display: block;
  }

  .site_nav>ul>li.submenu {
    margin-bottom: 0;
  }

  .site_nav>ul>li a {
    min-height: 60px;
  }
}

@media (max-width: 480px) {
  .wrapper {
    transform: none;
  }

  .membersSearch .tab-contents input {
    width: 100%;
  }

  .form-control.multiselect {
    width: 100%;
  }

  .cryptoBtn {
    display: block;
    text-align: end;
  }

  .cryptoBtn button {
    margin: 10px 0 0px 10px;
    height: 30px;
  }

  .cryptoBtn>*:not(:last-child) {
    margin-top: 0px;
  }

  .outletLayout {
    position: unset;
    right: 0;
    top: 5px;
    transform: none;
    height: 100%;
  }

  .graphWidth {
    width: 100% !important;
  }

  .trade_data {
    margin-top: -20px;
  }

  .verify_card {
    height: 472.5px !important;
  }

  .graph_info {
    margin-top: 0;
    padding-top: 0;
  }

  .trade_data {
    transform: none !important;
    margin-top: 20px;
    padding-bottom: 30px;
  }

  .community_link {
    flex-wrap: wrap;
    gap: 0px;
    flex-direction: row-reverse;
    height: 162px;
  }

  .community_link .socialIcons {
    margin-top: -18px;
  }

  .business_community {
    flex-wrap: wrap;
    gap: 7px;
    height: 162px;
  }

  .grey-background>div {
    flex-wrap: wrap;
    /* justify-content: center !important; */
  }

  .w_261 {
    width: 100%;
  }

  .custom-btn {
    width: 100%;
  }

  .payment .inputDiv {
    width: 100%;
  }

  .payment .paymentCard2 {
    padding: 20px 25px 20px 25px;
  }

  .payment .paymentCard {
    padding: 20px 25px 20px 25px;
  }

  /* .custom-dropdown {
    z-index: 99;
  } */

  .border-right-wrap::before {
    left: 55% !important;
    transform: translate(10px);
  }

  .discount .card img {
    margin-left: 10px;
  }

  .dropdown-menu {
    width: 280px;
  }
}

@media (max-width: 440px) {

  /* .trade_data {
        width: 360px !important;
        min-width: 120px;
    } */
  .trade_data .stats_section h4 {
    font-size: 18px !important;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .trade_data .stats_col p {
    overflow: auto;
    white-space: nowrap;
  }

  .trade_data .stats_section .main_content {
    padding: 5px !important;
  }

  .trade_data .stats_section .leader_content {
    margin-bottom: 30px !important;
    margin-left: 20px !important;
  }

  .trade_data .stats_section .heading {
    margin-bottom: 1.5rem !important;
    align-items: center;
  }

  .trade_data .stats_section .heading h4 {
    margin-right: 10px;
  }

  .trade_data .stats_section .main_content .second-col {
    margin-right: 1rem;
  }

  .note {
    height: 180px !important;
    width: 280px !important;
  }
}

@media (max-width: 400px) {
  .get_paid {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px !important;
  }

  .setting-select-box .descriptive {
    padding-right: 10px;
  }
}

@media (min-width: 380px) and (max-width: 390px) {
  .invest_amount {
    margin-left: 5px !important;
  }
}

@media (max-width: 380px) {
  .invest_amount {
    margin-left: 0px !important;
  }

  .w-60 {
    width: 60px;
  }

  .grey-background.mbl img {
    width: 120px;
  }

  .launch {
    width: 70px;
  }

  .tier .card {
    flex-wrap: wrap;
  }

  .tier .card .block .form-control {
    width: 100px;
  }

  .apiInsted {
    display: block;
    text-align: right;
  }

  .apiInsted button {
    margin-top: 20px;
  }

  .login_footer ul li {
    margin-left: 15px;
  }

  .dollar {
    margin-left: -17px;
    margin-top: 21px;
  }

  .appBtns button {
    width: 28% !important;
  }

  .mobile-arrow-btn-fix {
    position: relative;
    bottom: -14px;
  }
}

@media (min-width: 360px) and (max-width: 370px) {
  .invest_amount {
    margin-left: -8px !important;
  }
}

@media (max-width: 600px) {
  /* .sub-info-btn img {
    height: 18px;
  } */

  .overflow-unset {
    overflow: unset !important;
  }

  .cryptoMb {
    margin-bottom: 10px;
  }

  .tradeHistory .card .content p:first-child {
    justify-content: start;
  }

  .membersOverview .card .contentBlock .content h5 {
    font-size: 14px;
    font-family: "Graphik Bold";
    text-align: start !important;
  }

  .login_card {
    max-width: none !important;
    min-width: auto !important;
    padding: 35px 0 50px !important;
    margin: 0 auto !important;
    width: 294px !important;
    border: none !important;
  }

  .verify_card {
    width: 294px !important;
    margin: 0 auto !important;
  }

  .verify_card_name {
    width: 294px !important;
    margin: 0 auto !important;
  }

  .account_card {
    width: 294px !important;
    margin: 0 auto !important;
  }

  .signup_card {
    width: 294px !important;
    margin: 0 auto !important;
  }

  .signin_card {
    width: 294px !important;
    margin: 0 auto !important;
  }

  .tradeScreen {
    width: 305px !important;
    margin: 0 auto !important;
  }

  .login_footer {
    padding: 10px 0 0;
  }

  .custom-btn {
    width: 100%;
  }

  .discount .discountInputs {
    flex-direction: column;
  }

  .discount .discountInputs>*:not(:last-child) {
    margin-bottom: 20px;
  }

  .discount .discountInputs>*:not(:last-child) {
    margin-right: 0px;
  }

  .discount .discountInputs .messageInput {
    width: 100% !important;
  }

  .discount .discountInputs .messageInputs {
    width: 100% !important;
  }

  .discount .card>*:not(:last-child) {
    margin-right: 5px;
  }

  .trade_active {
    max-width: 375px;
  }

  .custom-trade-list .trade_heading_center,
  .custom-trade-list p {
    text-align: left;
  }

  .custom-trade-list .trade_heading_center {
    justify-content: start;
  }
}

@media (min-width: 992px) {
  .border-right-wrap::before {
    transform: translate(30px);
  }
}

@media only screen and (max-width: 945px) {
  .sidebar .powered {
    /* left: 50%;
        bottom: 8%;
        transform: translate(-50%, -0%); */
    position: relative;
    text-align: center;
    left: unset;
    bottom: unset;
    transform: unset;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .safari_only {
    bottom: 5%;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .safari_only.forBrave {
    bottom: 0 !important;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .sidebar .powered.cm-for-mobile {
    bottom: 6.5%;
  }

  .sidebar .powered.cm-for-mobile {
    position: absolute;
    text-align: center;
    left: 50%;
    bottom: 5%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-height: 620px) {
  .sidebar {
    position: fixed !important;
    top: 0 !important;
    left: -100%;
    width: 100% !important;
    min-height: 100vh !important;
    overflow: unset !important;
    background: #ffffff;
    padding: 60px 0px;
    transition: all 0.2s ease-in-out;
    z-index: 100 !important;
    transform: none;
  }

  .topLines {
    display: none;
  }

  .sidebar .business_name {
    text-align: left;
  }

  .site_nav li.active::after {
    content: none;
  }

  .sidebar .lines {
    margin: 20px auto 20px !important;
  }

  .sidebar .powered {
    margin: 0 30px !important;
    padding: 40px 0 0 !important;
    /* min-width: 100%; */
    transform: unset;
    left: unset;
    bottom: unset;
  }

  .sidebar.active {
    left: 0;
    z-index: 9999 !important;
    overflow-y: scroll !important;
    border-radius: 0;
  }

  .sidebar .toggle-btn {
    position: absolute;
    right: -65px;
    top: 26px;
    width: 30px;
    height: 17px;
    border: none;
    transition: all 0.2s ease-in-out;
    background: transparent;
    display: block;
  }

  .toggle-btn.active {
    right: 15px !important;
    top: 20px !important;
  }

  .toggle-btn.active:before {
    transform: rotate(45deg);
    top: 6px !important;
  }

  .toggle-btn.active span {
    transform: rotate(-45deg);
    /*top: 6px !important;*/
  }

  .toggle-btn.active:after {
    display: none;
  }

  .sidebar .toggle-btn:before {
    transition: all 0.4s ease-in-out;
    content: "";
    width: 100%;
    top: 0;
    height: 2px;
    background: #000;
    position: absolute;
    right: 0;
  }

  .sidebar .toggle-btn:after {
    transition: all 0.4s ease-in-out;
    content: "";
    width: 100%;
    bottom: 0;
    height: 2px;
    background: #000;
    position: absolute;
    right: 0;
  }

  .sidebar .toggle-btn span {
    transition: all 0.4s ease-in-out;
    width: 100%;
    top: 7px;
    height: 2px;
    background: #000;
    position: absolute;
    right: 0;
  }

  .site_nav {
    z-index: 10;
  }

  .layoutTop {
    width: 100%;
    margin-top: 70px;
  }

  .MemberTab {
    justify-content: start !important;
  }

  /* .MemberTab>*:not(:last-child) {
        margin-right: 25px !important;
    } */

  .copyTradeTab {
    justify-content: space-between;
  }

  .copyTradeTab .nav-link {
    padding: 8px 10px 17px;
  }
}

.for-mobile {
  display: none;
}

.for-desktop {
  display: block;
}

@media screen and (max-width: 945px) {
  .sidebar {
    position: fixed !important;
    top: 0 !important;
    left: -100%;
    width: 100% !important;
    height: 100vh !important;
    overflow: unset !important;
    background: #ffffff;
    padding: 0px 0px;
    transition: all 0.2s ease-in-out;
    z-index: 100 !important;
    transform: none;
  }

  .height-set-for-nav {
    /* height: 90vh; */
    height: auto;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .height-set-for-nav.for-safari {
    min-height: 110% !important;
    padding-top: 100px;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .sidebar.active {
    top: -100px !important;
    height: calc(100vh + 100px) !important;
  }

  _::-webkit-full-page-media,
  _:future,
  :root .toggle-btn.active {
    top: 115px !important;
  }

  .for-mobile {
    display: block;
  }

  .for-desktop {
    display: none;
  }

  .height-set-for-nav.cm-for-mobile {
    height: auto;
  }

  .topLines {
    display: none;
  }

  .sidebar .business_name {
    text-align: left;
  }

  .site_nav li.active::after {
    content: none;
  }

  .sidebar .lines {
    margin: 20px auto 20px !important;
  }

  .sidebar .powered {
    margin: 0 0px !important;
    padding: 40px 0 0 !important;
    margin-bottom: 20px !important;
  }

  .sidebar.active {
    left: 0;
    z-index: 9999 !important;
    overflow-y: scroll !important;
    border-radius: 0;
  }

  .sidebar .toggle-btn {
    position: absolute;
    right: -65px;
    top: 26px;
    width: 30px;
    height: 17px;
    border: none;
    transition: all 0.2s ease-in-out;
    background: transparent;
    display: block;
    z-index: 9;
  }

  .toggle-btn.active {
    right: 15px !important;
    top: 20px !important;
  }

  .toggle-btn.active:before {
    transform: rotate(45deg);
    top: 6px !important;
  }

  .toggle-btn.active span {
    transform: rotate(-45deg);
    /*top: 6px !important;*/
  }

  .toggle-btn.active:after {
    display: none;
  }

  .sidebar .toggle-btn:before {
    transition: all 0.4s ease-in-out;
    content: "";
    width: 100%;
    top: 0;
    height: 2px;
    background: #000;
    position: absolute;
    right: 0;
  }

  .sidebar .toggle-btn:after {
    transition: all 0.4s ease-in-out;
    content: "";
    width: 100%;
    bottom: 0;
    height: 2px;
    background: #000;
    position: absolute;
    right: 0;
  }

  .sidebar .toggle-btn span {
    transition: all 0.4s ease-in-out;
    width: 100%;
    top: 7px;
    height: 2px;
    background: #000;
    position: absolute;
    right: 0;
  }

  .site_nav {
    z-index: 10;
  }

  .layoutTop {
    width: 100%;
    margin-top: 0px;
  }

  .MemberTab {
    justify-content: start !important;
  }

  /* .MemberTab>*:not(:last-child) {
        margin-right: 25px !important;
    } */

  .copyTradeTab {
    justify-content: space-between;
  }

  .copyTradeTab .nav-link {
    padding: 8px 10px 17px;
  }

  .smallBlack_card {
    width: 100%;
  }

  .flex-no {
    flex-wrap: nowrap !important;
  }

  .stats_cards {
    width: auto;
  }

  .reactour__popover {
    top: -175px !important;
    left: 37px !important;
  }

  .reactour__popover img {
    transform: rotate(300deg);
  }

  .onboard-modal {
    position: fixed;
    transform: translate(-50%, -31%) !important;
  }
}

/* .maxAdjust {
  position: relative;
  top: -26px;
} */

@media screen and (max-width: 767px) {
  .maxAdjust {
    margin-top: 35px;
    top: unset !important;
  }

  .card_flex {
    flex-wrap: wrap;
  }

  .graphWidth {
    width: auto;
  }

  .statusTrading {
    flex-direction: column;
    justify-content: end;
    align-items: end;
    margin-top: 20px;
  }

  .mbl-100 {
    width: 100%;
    margin-right: 0 !important;
  }

  .black_card {
    width: 100%;
    height: auto;
  }

  .white_card {
    width: 100%;
    margin-right: 0 !important;
  }

  .marginMbl {
    margin-right: 0 !important;
  }

  .download_height {
    height: 162px !important;
  }

  .w-692 {
    width: 100% !important;
  }

  .smallBlack_card {
    width: 100%;
  }

  .flex-no {
    flex-wrap: nowrap !important;
  }

  .copyTrade .tab-content {
    padding: 60px 0px 30px;
  }

  .pt-sm-30 {
    padding-top: 30px;
  }

  .mt-sm-30 {
    margin-top: 30px;
  }

  .article {
    padding: 30px 0;
  }

  .main_body.common_style {
    height: auto;
  }

  .copyTradeTab {
    padding: 0;
    flex-wrap: nowrap;
    overflow-y: hidden;
    height: auto;
    overflow-x: scroll;
  }

  .dropdown-toggle {
    position: unset;
  }

  .connection {
    padding: 0;
  }

  .connection.about-app-page {
    padding: 0;
  }

  .articleRelatives>div {
    padding: 30px 16px;
  }

  .payment {
    padding: 0;
  }

  .onboardingUser {
    padding: 0;
  }

  .url {
    padding: 0;
  }

  .subscription {
    padding: 0;
  }

  .subscription.subscriptionJs {
    padding: 0;
  }

  .subscription.introJs {
    padding: 0;
    padding-bottom: 50px;
  }

  .pb-sm-50px {
    padding-bottom: 50px !important;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .connection.connectionJs {
    padding: 0;
  }

  .connection.autoshareJs {
    padding: 30px 0 0 0;
  }

  .connection.reportsJs {
    padding: 30px 0 0 0;
  }

  .pricing.pricingJs {
    padding: 0;
  }

  .closeTrade {
    padding: 30px 35px 0 35px;
  }

  .p-top-on-mobile {
    padding-top: 17px !important;
  }

  .tier .card {
    justify-content: space-between;
  }

  .pricing {
    padding: 0;
  }

  .appBtns {
    width: 90%;
    justify-content: start;
  }

  .appBtns button {
    width: 30%;
  }

  .trading-vol-group-card {
    display: none;
  }

  .last-trade-card {
    margin-right: 0 !important;
  }

  .border-bottom-ontab {
    margin: 0 !important;
  }

  .top-tag {
    top: -1px;
    padding-top: 2px;
  }

  .popup-text-for-mobile {
    font-size: 13px !important;
  }

  .white_card.mbl img {
    right: 30px;
    top: 50%;
  }

  .grey-background.mbl img {
    width: 135px;
  }
}

@media screen and (max-width: 630px) {
  .onboard-modal {
    transform: translate(-52%, -32%) !important;
    margin: 0;
  }
}

.remember-me {
  color: black;
  opacity: 50%;
  font-size: 14px;
}

.card-container {
  position: relative;
}

.subclose-icon {
  position: absolute;
  top: 5px;
  right: -5px;
  transform: translate(-50%);
}

.testbuttonview {
  display: block;
  height: 10px;
  width: 10%;
  position: absolute;
  left: 10px;
  bottom: 10px;
}

.testclass {
  display: none;
}

.testbuttonview:hover .testclass {
  display: block;
  cursor: pointer;
  text-align: left !important;
}

.w-auto {
  width: auto !important;
}

.ml-auto {
  margin-left: auto;
}

.ml-20 {
  margin-left: 20px;
}

.SubmitButton {
  background-color: rgb(15 183 0) !important;
  color: white !important;
}

.d-none {
  display: none;
}

.p-top {
  padding-top: 41px;
}

#chat-widget-container {
  bottom: -10px !important;
}

.margin-3 {
  margin-top: -3px !important;
}

.border-bottom-ontab {
  border-bottom: 1px solid #e2e2e2;
  padding: unset;
  margin: 0 77px;
}

@media only screen and (max-width: 360px) {
  .setting-select-box .select {
    padding-top: 23px;
  }

  .grey-background.mbl img {
    width: 100px;
  }
}

@media only screen and (max-height: 700px) {
  .sidebar .powered {
    /* position: relative;
        text-align: left;
        margin: 0 auto !important;
        left: unset;
        transform: unset; */
    position: relative;
    text-align: center;
    left: unset;
    bottom: unset;
    transform: unset;
  }
}

.cancel-btn {
  background-color: red !important;
}

.delete-member-text {
  line-height: normal;
}

.delete-member-subtext {
  font-size: 12px;
  line-height: 15px;
  margin-bottom: 15px;
  margin-top: 10px;
}

.mt-18-rev {
  margin-top: -18px !important;
}

.me-30 {
  margin-right: 30px;
}

.bottom-light-text span {
  color: #707070 !important;
}

.me-n-1 {
  margin-right: -7px;
}

.select-user-cross-icon {
  height: 15px;
}

.mail-page-logo {
  font-size: 19px;
  font-family: "Graphik Bold";
  font-weight: 700 !important;
  line-height: 0;
  opacity: 50%;
}

.mb-38-for-heaidng {
  margin-bottom: 38px !important;
}

.height-14px {
  height: 13px;
}

.set-postion-tamname {
  position: absolute;
  top: 40px;
}

@media only screen and (max-width: 600px) {
  .set-postion-tamname {
    /* position: relative; */
    top: 0px;
  }
}

.button-on-red {
  background-color: #ff0202 !important;
  color: white !important;
  border: 1px solid #ff0202 !important;
}

.button-on-red:hover {
  background-color: #fff !important;
  color: #ff0202 !important;
  border: 1px solid #ff0202 !important;
}

.py-comp-only-red-border {
  box-shadow: none;
  border: 1px solid #ff0202 !important;
}

.width-60 {
  width: 60%;
}

.width-90 {
  width: 90%;
}

@media only screen and (max-width: 600px) {
  .width-60 {
    width: 100%;
  }

  .width-90 {
    width: 100%;
  }

  .tradeHistoryMbl .card .number h4 {
    font-size: 16px;
  }

  .tier-save-btn {
    width: 100%;
  }

  .cl-tier-label {
    font-size: 12px !important;
  }
}

.hidden-input {
  outline: 0 !important;
  border: 0 !important;
  caret-color: #fff !important;
}

/* .brave-class {
    height: 130vh!important;
} */

/* .brave-sidebar {
    height: 15000px !important;
} */

.custom-white-text {
  color: white !important;
  font-size: 1px;
}

.cl-tier-heading {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 17px;
  font-family: "Graphik Bold";
}

.subscription-heading-margin {
  margin-bottom: 27px !important;
}

@media only screen and (max-width: 767px) {
  .subscription-heading-margin {
    margin-bottom: 13px !important;
  }
}

.cl-tier-modal {
  position: absolute;
  top: 25px;
  left: 65px;
  border-radius: 5px;
  filter: drop-shadow(-5px 15px 10px rgba(0, 0, 0, 0.2));
}

.cl-tier-modal .cls-btn {
  border: none;
  background-color: transparent;
  margin-right: 5px;
}

.cl-tier-modal p span {
  font-family: "Graphik Medium", sans-serif;
  color: #fff;
  font-size: 14px;
}

.placeholder-black::placeholder {
  color: #000;
  font-weight: 500;
}

.cl-iter-action-box {
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 10px;
  position: relative;
  top: -22px;
  height: 0px;
}

.cl-iter-action-box button {
  border: none;
  background-color: transparent;
}

.cl-tier-label {
  font-family: "Graphik Medium", sans-serif;
  font-size: 12px;
  color: #a5a5a5;
  gap: 7px;
}

.cl-tier-label span {
  font-family: "Graphik Medium", sans-serif;
  font-size: 12px;
  color: #a5a5a5;
}

.tier .form-control {
  border: 1px solid #f0f0f0;
  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));
  font-family: "Graphik Bold";
  margin-bottom: 16px;
  min-height: 50px;
  font-size: 14px;
  border-radius: 7px;
  font-family: "Graphik Bold";
}

.tier .form-control::placeholder {
  font-family: "Graphik Regular";
  color: hsl(0deg 0% 0% / 50%);
  font-size: 13px;
}

.info-tier-icon svg {
  fill: #000;
}

.activetrade-text-item {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 10px;
  text-align: left;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #d3d3d3;
}

.activetrade-text-item .activetrade-header {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 0px;
}

.activetrade-text-item .img-box {
  width: 40px;
}

.activetrade-text-item .img-box img {
  width: 27px;
  height: 27px;
}

.activetrade-text-item .content-box {
  text-align: left;
}

.activetrade-text-item .content-box h4 {
  width: fit-content;
  font-size: 13px;
  letter-spacing: 0px;
  line-height: normal;
  color: #000000;
  font-family: "Graphik Bold";
  text-align: center;
  margin-bottom: 0px;
}

.activetrade-text-item .content-box p {
  text-align: left !important;
  margin-left: 40px;
  font-size: 13px;
}

.trade-active-samll-text {
  color: #7b7777;
  font-size: 10px;
  margin-bottom: 5px;
}

.margeintop-negtive {
  margin-top: -1px;
}

.subscribe-tab {
  position: relative;
  top: -45px;
}

.option-padding {
  padding-top: 277px;
}

.tierActionDropdown .dropdown-menu {
  width: 145px;
  border-color: #c6c6c6;
  border-width: 0.30000001192092896px;
  border-style: solid;

  filter: drop-shadow(0px 27px 12px rgba(0, 0, 0, 0.05));

  background: #000000;
  padding: 10px 0;
}

.tierActionDropdown .dropdown-menu li button {
  color: #fff;
  font-size: 12px;
  font-family: "Graphik Medium";
  transition: 0.3s all ease;
}

.tierActionDropdown .dropdown-menu li button:hover,
.tierActionDropdown .dropdown-menu li button:focus,
.tierActionDropdown .dropdown-menu li button:active {
  color: #fff;
  font-size: 12px;
  font-family: "Graphik Bold";
  background-color: transparent;
}

.freeTrailBox hr {
  width: 100%;
  border: 1px solid rgb(193, 193, 193);
  margin: 30px 0px 40px;
}

.freeTrailDropdown {
  width: fit-content;
}

.freeTrailDropdown .select>div>div {
  min-width: 217px;
  min-height: 50px;
}

.TierPositionSetup {
  position: relative;
}

.my-select__option:active {
  background-color: #f7f7f7 !important;
}

.freeTrailDropdown .select svg {
  border-width: 0 5px 5px 5px;
}

/* .activetrade-text-item .img-box img{
  margin-top: -10px;
} */

#otp-popup .modal-dialog {
  left: 7.5%;
}

#otp-popup .modal-content {
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
  box-shadow: none;
  border-color: #d6d6d6;
  border-width: 0.5px;
  border-style: solid;
}

#otp-popup .modal-dialog {
  max-width: 400px;
  /* min-width: 444px; */
}

#otp-popup .innerDiv {
  width: 100%;
  margin: 0 auto;
  padding: 57px 57px 52px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

#otp-popup .modal-content {
  filter: none !important;
  /* Ensure no blur is applied */
}

#otp-popup .modal:focus-within {
  filter: none;
  /* Remove blur from modal when anything inside it gets focused */
}

#otp-popup .form-input label {
  filter: none !important;
}

#otp-popup .closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 1;
}

/* #otp-popup input {
  width: 326px;
} */

/* #otp-popup .newCreateButton {
  width: 326px;
} */

@media only screen and (max-width: 576px) {
  #otp-popup .innerDiv {
    padding: 40px 20px !important;
  }

  #otp-popup .modal-dialog {
    max-width: 90%;
    /* width: 90%; */
    margin: 0 30px;
  }
}

@media only screen and (max-width: 393px) {
  .cl-iter-action-box {
    right: 1px;
  }

  .tier .form-control {
    font-size: 14px;
    padding-top: 15px;
  }

  .cl-tier-label {
    font-size: 12px !important;
  }
}

@media only screen and (max-width: 360px) {
  .cl-tier-label {
    font-size: 10px !important;
  }
}

@media only screen and (min-width: 344px) and (max-width: 410px) {
  .custom-radio-item .content-box .sub-price {
    font-size: 14px;
  }

  .option-padding {
    padding-top: 222px;
  }
}

@media only screen and (min-width: 1440px) {
  .sidebar {
    width: 253px;
  }
}

@media only screen and (max-width: 991px) {
  #otp-popup .modal-dialog {
    left: unset;
  }
}

@media only screen and (max-width: 600px) {
  /* .TierPositionSetup {
    position: unset;
  } */

  .cl-tier-modal {
    position: absolute;
    top: 50%;
    right: unset;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    filter: drop-shadow(-5px 15px 10px rgba(0, 0, 0, 0.2));
  }

  .TierPositionSetup .cl-tier-modal {
    position: absolute !important;
    top: 25px !important;
    right: 15px !important;
    left: unset !important;
    transform: unset;
    border-radius: 5px;
    filter: drop-shadow(-5px 15px 10px rgba(0, 0, 0, 0.2));
    width: auto !important;
  }

  .TierPositionSetup .cl-tier-modal.innerTier {
    left: 65px !important;
  }
}

@media only screen and (max-width: 375px) {
  .community_link .socialIcons {
    margin-top: 0;
    /* margin-bottom: 20px; */
  }

  .tier .form-control::placeholder {
    font-family: "Graphik Regular";
    color: hsl(0deg 0% 0% / 50%);
    font-size: 11px;
  }

  .community_link {
    height: auto;
  }
}

.otpModal .modal-dialog {
  left: 8.5%;
  top: 15%;
}

.otpModal .modal-dialog .modal-content {
  border: 2px solid #d6d6d6;
  border-radius: 5px;
  margin: 0 auto;
  max-width: 444px;
  min-width: 444px;
  overflow: hidden;
  padding: 90px 57px 85px !important;
  position: relative;
}

.otpModal .modal-body {
  position: unset;
  padding: 0;
}

.otpModal .OtpCloseBtn,
.otpModal .OtpCloseBtn:active {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 15px;
  background-color: transparent;
  border: none;
  z-index: 1;
}

/* Floating Textarea Label of Support Screen */
.messageInput.supportTextAreaCode {
  position: relative;
}

.supportTextAreaCode .floating-label {
  position: relative;
}

.supportTextAreaCode textarea {
  width: 100%;
  padding: 1rem 0.75rem 1rem 0.75rem !important;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  resize: none;
  border: 0;
}

.supportTextAreaCode textarea:focus {
  border-color: #007bff;
}

.supportTextAreaCode label {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
  color: #aaa;
  transition: all 0.2s ease;
  pointer-events: none;
}

.supportTextAreaCode textarea:focus+label,
.supportTextAreaCode label.active {
  top: -0px;
  /* left: 5px; */
  font-size: 12px;
  color: hsl(0, 0%, 50%);
}

@media only screen and (max-width: 600px) {
  .otpModal .modal-dialog .modal-content {
    min-width: 90%;
    max-width: unset;
    padding: 35px 20px 50px !important;
  }

  .otpModal .modal-dialog {
    left: unset;
    top: 15%;
  }

  .connection .accountCard {
    align-items: start;
  }

  .connection .accountCard .accountButtonBox {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-direction: column;
    gap: 10px;
  }
}

@media only screen and (max-width: 576px) {
  .otpModal .modal-dialog {
    margin: 30px;
  }

  .accountCard button {
    width: 150px;
    padding: 7px 25px 7px 15px;
  }
}

@media only screen and (max-width: 400px) {
  .otpModal .modal-dialog .modal-content {
    min-width: 95%;
    max-width: unset;
    padding: 35px 20px 50px !important;
  }

  .otpModal .modal-dialog {
    margin: 30px 10px;
  }

  @media only screen and (max-height: 620px) {
    .otpModal .modal-dialog {
      left: unset;
      top: 15%;
    }
  }
}