.CopyItCustomTextArea {
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 6px 20px 3px #c6c6c642;
  min-height: auto;
  overflow: hidden;
  padding: 15px 15px 15px;
  position: relative;
}

.CopyItCustomTextArea label {
  border: var(--bs-border-width) solid transparent;
  color: #9a9b9b;
  font-family: "Graphik Regular";
  font-size: 14px;
  height: 100%;
  left: 0;
  overflow: hidden;
  padding: 0 !important;
  pointer-events: none;
  position: absolute;
  text-align: start;
  text-overflow: ellipsis;
  top: 3px;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  white-space: nowrap;
  z-index: 2;
}

.CopyItCustomTextArea label::after {
  content: none !important;
}

.CopyItCustomTextArea textarea {
  background-color: transparent;
  border-radius: 0;
  border: none;
  color: #000 !important;
  font-family: "Graphik Regular";
  font-size: 14px !important;
  height: auto !important;
  min-height: auto !important;
  outline: none;
  padding-bottom: 5px !important;
  padding-left: 2px !important;
  padding-top: 12px !important;
  resize: none;
  width: 100%;
}

.CopyItCustomTextArea .form-floating > .form-control-plaintext ~ label,
.CopyItCustomTextArea .form-floating > .form-control:focus ~ label,
.CopyItCustomTextArea
  .form-floating
  > .form-control:not(:placeholder-shown)
  ~ label,
.CopyItCustomTextArea .form-floating > .form-select ~ label {
  color: #9a9b9b;
  transform: scale(0.8) translateY(-0.89rem) translate(0);
}

.CopyItCustomTextArea:focus-within {
  background-color: #fff;
}

.CopyItCustomTextArea.invalid {
  border: 3px solid red !important;
}
