.newSidebarBox {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  padding: 0 17px;
  transition: left 0.3s ease;
  z-index: 99;
}

.newSidebarBox .businessNameBox {
  padding: 63px 45px;
}

.newSidebarBox .businessNameBox .businessName {
  font-family: "Graphik Medium";
  font-size: 17px;
  line-height: 19px;
  font-style: normal;
  text-align: left;
  color: #000000;
}

.newSidebarBox .NewsidebarLinksBox {
  max-height: 65vh;
  overflow-y: auto;
  padding-bottom: 12px;
}

.newSidebarBox .NewsidebarLinksBox {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.newSidebarBox .NewsidebarLinksBox::-webkit-scrollbar {
  display: none;
}

.newSidebarBox .newSidebarLink {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 22px;
  padding: 19px 30px;
  width: 100%;
  border-radius: 10px;
  border: none;
  font-family: "Graphik Regular";
  font-size: 14px;
  text-align: left;
  color: #000000;
  transition: 0.3s all ease;
  margin-bottom: 10px;
}

.newSidebarBox .newSidebarLink svg {
  transition: 0.3s all ease;
  position: relative;
  top: -3px;
}

.newSidebarBox .newSidebarLink:hover,
.newSidebarBox .newSidebarLink.active {
  background-color: #10b700;
  color: #fff;
  display: flex;
}

.newSidebarBox .newSidebarLink:hover svg,
.newSidebarBox .newSidebarLink.active svg {
  fill: #fff;
}

.newSidebarBox .newSidebarLink:hover .memberSvg path,
.newSidebarBox .newSidebarLink.active .memberSvg path {
  stroke: #fff;
}

.newSidebarBox .newSidebarLink svg .memberInnerBody {
  transition: 0.1s all ease;
}

.newSidebarBox .newSidebarLink:hover svg .memberInnerBody,
.newSidebarBox .newSidebarLink.active svg .memberInnerBody {
  fill: #10b700;
  transition: 0.1s all ease;
}

.newSidebarBox .newSidebarLink:hover .onboardingSVG path,
.newSidebarBox .newSidebarLink.active .onboardingSVG path {
  fill: #fff;
}

.newSidebarBox .dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.newSidebarBox .dropdownToggle {
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 22px;
  padding: 19px 30px;
  width: 100%;
  border-radius: 10px;
  font-family: "Graphik Regular";
  font-size: 14px;
  text-align: left;
  color: #000000;
  transition: 0.3s all ease;
  margin-bottom: 10px;
  border: 0;
  position: relative;
}

.newSidebarBox .dropdownToggle svg {
  transition: 0.3s all ease;
  position: relative;
  top: -3px;
}

.newSidebarBox .dropdownToggle:hover,
.newSidebarBox .dropdownToggle.active {
  background-color: #10b700;
  color: #fff;
  display: flex;
}

.newSidebarBox .dropdownToggle:hover svg,
.newSidebarBox .dropdownToggle.active svg,
.newSidebarBox .dropdownToggle:hover svg g path,
.newSidebarBox .dropdownToggle.active svg g path {
  fill: #fff;
}

.newSidebarBox .dropdownToggle .dropdownArrow path {
  transition: 0.3s all ease;
}

.newSidebarBox .dropdownToggle:hover .dropdownArrow path,
.newSidebarBox .dropdownToggle.active .dropdownArrow path {
  stroke: #fff;
}

.newSidebarBox .dropdownArrow {
  position: absolute !important;
  top: 50% !important;
  right: 28px;
  transform: translate(-50%, -50%);
}

.newSidebarBox .dropdownArrow.open {
  transform: rotate(180deg);
  /* transform: scaleY(-1); */
  position: absolute !important;
  top: 46% !important;
  right: 30px;
}

.newSidebarBox .dropdownMenu {
  /* position: absolute; */
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-top: 8px;
  z-index: 1000;
  min-width: 200px;
  padding: 8px 0;
}

.newSidebarBox .dropdownItem {
  padding: 8px 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
  font-size: 14px;
  transition: background-color 0.2s;
  border: none;
  width: 100%;
  background-color: transparent;
}

.newSidebarBox .dropdownItem svg {
  margin-right: 8px;
  width: 16px;
  height: 16px;
  fill: currentColor;
}

.newSidebarBox .dropdownItem:hover {
  background-color: #f0f0f0;
}

.newSidebarBox .newSidebarBottomBox {
  position: absolute;
  bottom: 28px;
  text-align: center;
  width: calc(100% - 34px);
}

.newSidebarBox .newSidebarBottomBox .divider {
  width: 135px;
  height: 0.8px;
  background-color: #e2e2e2;
  display: block;
  margin: 0 auto;
  margin-bottom: 33px;
}

.newSidebarBox .newSidebarBottomBox .PoweredBy {
  font-family: "Graphik Regular";
  font-size: 9px;
  color: #000000;
  opacity: 0.25;
  margin-bottom: 0;
}

.newSidebarBox .newSidebarBottomBox img {
  width: 59px;
  opacity: 0.25;
}

.ToggleBtn {
  display: none;
}

@media only screen and (max-width: 945px) {
  .newSidebarBox {
    position: fixed;
    width: 100%;
    left: -100%;
    transition: left 0.3s ease;
  }

  .newSidebarBox.open {
    left: 0;
  }

  .ToggleBtn {
    position: absolute;
    top: 50px;
    left: 50px;
    background-color: transparent;
    border: none;
    display: block;
    width: 30px;
    height: 18px;
  }

  .ToggleBtn.inner {
    position: absolute;
    left: unset;
    height: 22px;
    right: 15px;
    top: 20px;
  }

  .ToggleBtn.inner::before,
  .ToggleBtn.inner::after {
    content: none;
  }

  .ToggleBtn span {
    background: #000;
    height: 2px;
    position: absolute;
    right: 0;
    top: 8px;
    transition: all 0.4s ease-in-out;
    width: 100%;
  }

  .ToggleBtn::before,
  .ToggleBtn::after {
    background: #000;
    content: "";
    height: 2px;
    position: absolute;
    right: 0;
    transition: all 0.4s ease-in-out;
    width: 100%;
  }

  .ToggleBtn::before {
    top: 0;
  }

  .ToggleBtn::after {
    bottom: 0;
  }

  .newSidebarBox .newSidebarLink:hover {
    background-color: unset;
    color: unset;
  }

  .newSidebarBox .newSidebarLink:hover svg {
    fill: unset;
  }

  .newSidebarBox .dropdownToggle:hover {
    background-color: unset;
    color: unset;
  }

  .newSidebarBox .dropdownToggle:hover svg,
  .newSidebarBox .dropdownToggle:hover svg g path {
    fill: unset;
  }

  .newSidebarBox .dropdownToggle:hover .dropdownArrow path {
    stroke: unset;
  }
}

@media only screen and (max-width: 576px) {
  .ToggleBtn {
    top: 16px;
    left: 16px;
  }
}

@media only screen and (max-height: 620px) {
  .newSidebarBox {
    position: fixed;
    width: 253px;
    left: -100%;
  }

  .newSidebarBox.open {
    left: 0;
  }

  .ToggleBtn {
    position: fixed;
    top: 15px;
    left: 50px;
    background-color: transparent;
    border: none;
    display: block;
  }

  .ToggleBtn.inner {
    position: absolute;
    left: unset;
    right: 15px;
  }

  .newSidebarBox .NewsidebarLinksBox {
    max-height: 55vh;
  }
}