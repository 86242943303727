.newLayoutBox {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* height: 100vh; */
}

.mainContentBox {
    /* width: 100%;
    padding: 50px; */
    margin-left: 247px;
    /* padding: 50px; */
    padding: 45px 70px 50px 67px;
    flex: 1;
    background-color: #f8f9fa;
    overflow-y: auto;
}

.sideBarBox {
    width: 247px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 999;
    background-color: #f4f4f4;
    /* display: flex; */
    /* unicode-bidi: unset; */
}

.m-left-0 {
    margin-left: 0 !important;
}

.settingProfileBox {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 8px;
    margin-bottom: 15px;
}

@media only screen and (min-width: 1440px) {
    .sideBarBox {
        width: 253px;
    }

    .mainContentBox {
        margin-left: 253px;
    }

    .mainContentBox .container-fluid {
        max-width: 1440px;
    }
}


@media only screen and (max-width: 945px) {
    .sideBarBox {
        width: 0;
    }

    .mainContentBox {
        margin-left: 0;
    }
}

@media only screen and (max-width: 576px) {
    .mainContentBox {
        padding: 16px;
    }

    .mt-smLayout-30 {
        margin-top: 30px;
    }
}


@media only screen and (max-height: 620px) {
    .sideBarBox {
        width: 0;
    }

    .mainContentBox {
        margin-left: 0;
    }
}


/* .newLayoutBox {
    display: flex;
    height: 100vh;
}

.sideBarBox {
    width: 247px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 9999;
    background-color: #f4f4f4;
}

.mainContentBox {
    margin-left: 247px;
    padding: 50px;
    flex: 1;
    background-color: #f8f9fa;
    overflow-y: auto;
}

.m-left-0 {
    margin-left: 0 !important;
}

.settingProfileBox {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 12px;
    margin-bottom: 15px;
}


@media only screen and (min-width: 1440px) {
    .sideBarBox {
        width: 253px;
    }

    .mainContentBox {
        margin-left: 253px;
    }

    .mainContentBox .container-fluid {
        max-width: 1440px;
    }
}


@media only screen and (max-width: 945px) {
    .sideBarBox {
        width: 0;
    }

    .mainContentBox {
        margin-left: 0;
    }
}

@media only screen and (max-width: 576px) {
    .mainContentBox {
        padding: 16px;
    }

    .mt-smLayout-30 {
        margin-top: 30px;
    }
}


@media only screen and (max-height: 620px) {
    .sideBarBox {
        width: 0;
    }

    .mainContentBox {
        margin-left: 0;
    }
} */