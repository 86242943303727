.MemberLisitingPage .divider {
    opacity: 0.07;
    border-color: #000000;
    border-width: 1px;
    border-style: solid;
    margin: 44px 0;
}

.MemberLisitingPage .PageTitle {
    padding-left: 35.5px;
    margin-bottom: 37.6px;
    font-size: 35px;
    font-family: "Graphik Bold";
}

@media only screen and (max-width: 576px) {
    .MemberLisitingPage .PageTitle {
        padding-left: 20px;
    }

    .MemberLisitingPage .PageTitle {
        font-size: 20px;
        margin-bottom: 37.4px;
    }
}