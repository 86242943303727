.AutoShareSection {
    background-color: #fff;
    padding: 28.4px 41px 34.5px 46px;
    border-radius: 10px;
    margin-bottom: 54px;
    min-height: 300px;
}

.AutoShareSection .mb-20px {
    margin-bottom: 20.5px;
}

.AutoShareSection .title {
    font-family: "Graphik Bold";
    font-size: 20px;
    line-height: normal;
    font-style: normal;
    text-align: left;
    color: #06152b;
    margin-bottom: 8.2px;

}

.AutoShareSection .subTitle {
    font-family: "Graphik Medium";
    font-size: 16px;
    color: #06152b;
    margin-bottom: 29.9px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
}

.AutoShareSection .connectedChannelBox {
    display: flex;
    align-items: center;
    justify-content: end;
    gap: 5px;
}

.AutoShareSection .connectedChannelBox p {
    margin-bottom: 0;
    margin-right: 6px;
    font-family: "Graphik Regular";
    font-size: 12px;
}

.AutoShareSection .connectedChannelBox img {
    height: 21px;
    width: auto;
    position: relative;
    top: -1px;
}

.AutoShareSection .connectedBtn {
    background-color: #000;
    font-family: "Graphik Medium";
    font-size: 14px;
    font-style: normal;
    text-align: center;
    color: #ffffff;
    width: 100%;
    height: 55px;
    border-radius: 12px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}


.AutoShareSection .connectedBtn img {
    width: 21px;
    height: 21px;
    object-fit: contain;
    object-position: center center;
    position: relative;
    top: -2px;
}

.AutoShareSection .connectedBtn.desconnected {
    background-color: #ff0000;
}

.AutoShareSection .connectedBtn.desconnected img {
    display: none;
}



.AutoShareSection .SaveBtn {
    background-color: #11b700;
    font-family: "Graphik Medium";
    font-size: 14px;
    font-style: normal;
    text-align: center;
    color: #ffffff;
    width: 100%;
    height: 55px;
    border-radius: 12px;
    border: none;
}

.AutoShareSection .SaveBtn:hover {
    background-color: #000;
}


/* .AutoShareSection .CopyItCustomInput{
    box-shadow: none;
} */


.textFielRemoveShadow .CopyItCustomInput {
    box-shadow: none;
    border: 1px solid transparent;
}

.textFielRemoveShadow .CopyItCustomInput:focus-within {
    border: 1px solid #11b700;
}

@media only screen and (max-width: 576px) {
    .AutoShareSection {
        padding: 32px 20px 50px 20px;
    }
}