.setting-dropdown {
  display: inline-block;
  position: relative;
}

.setting-dropdown .dropdown-toggle {
  align-items: center;
  background-color: transparent;
  border-radius: 50rem;
  border: none;
  cursor: pointer;
  display: flex;
  height: 38px;
  align-items: center;
  justify-content: center;
  transition: 0.3s all ease;
  width: 38px;
}

.setting-dropdown .dropdown-toggle svg path {
  transition: 0.3s all ease;
}

.setting-dropdown .dropdown-toggle:hover,
.setting-dropdown .dropdown-toggle.active {
  background-color: #11b700;
}

.setting-dropdown .dropdown-toggle:hover svg path,
.setting-dropdown .dropdown-toggle.active svg path {
  fill: #fff !important;
}

.setting-dropdown .dropdown-menu {
  background-color: white;
  background: #ffffff;
  border-color: #d6d6d6;
  border-style: solid;
  border-width: 0.5px;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
  box-shadow: none;
  list-style: none;
  margin: 0;
  padding: 22px 0 30px 0;
  position: absolute;
  right: 0;
  top: 48px;
  width: 250px;
  z-index: 1000;
}

.setting-dropdown .dropdown-menu li {
  align-items: center;
  color: #06152b;
  cursor: pointer;
  display: flex;
  font-family: "Graphik Medium";
  font-size: 14px;
  justify-content: space-between;
  padding: 11px 48.1px 11px 36px;
  text-align: left;
  transition: 0.3s all ease;
}

.setting-dropdown .dropdown-menu li:hover {
  background-color: #fff;
  font-family: "Graphik Bold";
  padding: 11px 28.1px 11px 36px;
}

@media screen and (max-width: 600px) {
  .setting-dropdown .dropdown-toggle:hover {
    background-color: transparent;
  }

  .setting-dropdown .dropdown-toggle.active {
    background-color: #11b700;
  }

  .setting-dropdown .dropdown-toggle:hover svg path {
    fill: #06152b !important;
  }

  .setting-dropdown .dropdown-toggle.active svg path {
    fill: #fff !important;
  }
}