.OpenTradeBox {
  background-color: #fff;
  width: 100%;
  margin-left: auto;
  filter: drop-shadow(0px 3px 3px rgba(39, 45, 59, 0.16));
  border-radius: 10px;
  padding: 42px 22px;
  max-height: 547px;
}

.OpenTradeBox .headingBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;
}

.OpenTradeBox a {
  font-size: 12px;
  color: #000;
}

.OpenTradeBox .openTradeItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f0f2f2;
  padding-bottom: 15px;
  margin-bottom: 21px;
}

.OpenTradeBox .openTradeItem:last-of-type {
  margin-bottom: 0;
  border: 0;
  padding-bottom: 0;
}

.OpenTradeBox .openTradeItem .ContentSide .title {
  font-size: 14px;
  font-family: "Graphik Medium";
  color: #000;
}

.OpenTradeBox .openTradeItem .ContentSide {
  font-size: 12px;
  color: #8b8e90;
}

.OpenTradeBox .openTradeItem .ContentSide .quantityBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.OpenTradeBox .openTradeItem .numberSide .numbers {
  font-size: 14px;
  font-family: "Graphik Bold", sans-serif;
}

.OpenTradeBox .openTradeItem .numberSide .numbers.red {
  color: #ff0000;
}

.bold-hover {
  transition: font-weight 0.3s ease-in-out; /* Smooth transition for font-weight */
}

.bold-hover:hover {
  font-weight: bold; /* Make text bold when hovered */
}
