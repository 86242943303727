/* Container for dropdown */
.floating-dropdown {
    position: relative;
    width: 100%;
}

/* Dropdown button styles */
.dropdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    padding: 12px 14px;
    background-color: #f7f7f7;
    border: 1px solid transparent;
    border-radius: 12px;
    height: 55px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.dropdown-wrapper.open {
    background-color: #fff;
    border: 1px solid #11b700;
}

.dropdown-wrapper.has-value {
    background-color: #f7f7f7;

}

/* Placeholder styling */
.dropdown-selected.placeholder {
    color: #9a9b9b;
    visibility: hidden;
}

.dropdown-selected {
    font-family: "Graphik Regular";
    font-size: 14px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: 3px;
}

.dropdown-arrow {
    font-size: 12px;
    color: #999;
    transition: transform 0.3s ease-in-out;
}

.dropdown-wrapper.open .dropdown-arrow {
    transform: rotate(180deg);
}

/* Dropdown options */
.dropdown-options {
    /* position: absolute; */
    top: 100%;
    left: 0;
    width: 100%;
    /* background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10; */

    display: block;
    position: absolute;
    background-color: #fff;
    width: 250px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 10;
    border: 1px solid #ccc;
    padding: 5px 0;

    border-color: #d6d6d6;
    border-width: 0.5px;
    border-style: solid;

    filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));

    background: #ffffff;
    border-radius: 10px;
}

.dropdown-option {
    padding: 10px;
    font-size: 14px;
    font-family: "Graphik Regular";
    cursor: pointer;
    transition: background-color 0.2s;
}

.dropdown-option:hover {
    background-color: #f1f1f1;
}

/* Floating label */
.floating-label {
    position: absolute !important;
    top: 50% !important;
    left: 0px !important;
    transform: translateY(-50%);
    background-color: transparent;
    font-size: 14px;
    color: #9a9b9b;
    transition: all 0.3s ease-in-out !important;
    pointer-events: none !important;
    font-family: "Graphik Regular";
}

.floating-label.active {
    top: 13px !important;
    left: 0px !important;
    font-size: 10px;
    color: #9a9b9b;
}