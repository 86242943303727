.tierBoxesDiv .tierBoxesDivRow {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 25px;
    flex-wrap: wrap;
    margin-bottom: 30.5px;
}

.tierBoxesDiv .tierBoxesDivRow .tierBoxShall {
    width: 200px;
    height: 56px;
}

.tierBoxesDiv .tierBox {
    background-color: #000;
    border-radius: 10px;
    /* padding: 6px 19px 18px 19px; */
    padding: 4.1px 19px 18.5px 19px;
    height: 56px;
}

.tierBoxesDiv .tierBox .tierTitle {
    font-family: "Graphik Medium";
    font-size: 10px;
    text-align: left;
    color: #9a9b9b;
    margin-bottom: 0;
}

.tierBoxesDiv .tierBox .tierPrice {
    font-family: "Graphik Medium";
    font-size: 14px;
    text-align: left;
    color: #ffffff;
}

.tierBoxesDiv .tierBox button {
    background-color: transparent;
    border: none;
    height: auto;
    padding: 0;
    position: relative;
    top: -1px;
}

.tierBoxesDiv .tierBox button img {
    filter: invert(1);
}


/* Container to hold button and dropdown */
.tierBoxesDiv .dropdown-container {
    position: relative;
    display: inline-block;
}

/* Dropdown menu styling */
.tierBoxesDiv .custom-dropdown {
    position: absolute;
    top: 100%;
    right: 0;

    width: 150px;

    border-color: #c6c6c6;
    border-width: 0.20000000298023224px;
    border-style: solid;
    border-radius: 8px;

    filter: drop-shadow(-5px 15px 10px rgba(0, 0, 0, 0.2));

    background: #000000;
    z-index: 100;
}

.tierBoxesDiv .custom-dropdown ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.tierBoxesDiv .custom-dropdown li {
    padding: 8px;
    cursor: pointer;
    transition: color 0.3s;
    color: #fff;
}

.tierBoxesDiv .custom-dropdown li:hover {
    color: #11b700;
}

.tierBoxesDiv .custom-dropdown li:first-of-type {
    padding-bottom: 3px;
}

.tierBoxesDiv .custom-dropdown li:last-of-type {
    padding-top: 3px;
}

/* Button styling */
.tierBoxesDiv button {
    background: none;
    border: none;
    cursor: pointer;
}

.tierBoxesDiv button img {
    width: 18px;
    /* Adjust the size as needed */
    height: 13.52px;
    /* Adjust the size as needed */
}



@media only screen and (max-width: 576px) {
    .tierBoxesDiv .tierBoxesDivRow .tierBoxShall {
        width: calc(50% - 25px);
        height: 56px;
    }
}

@media only screen and (max-width: 390px) {
    .tierBoxesDiv .tierBoxesDivRow .tierBoxShall {
        width: 100%;
        height: 56px;
    }
}