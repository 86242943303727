/* .NewCopyTradePageCM .divider {
  margin: 64px 0;
} */

.NewCopyTradePageCM .spacer {
  display: block;
  height: 30.8px;
}

.NewCopyTradePageCM .PageTitle {
  padding-left: 35.5px;
  margin-bottom: 37.6px;
  font-size: 35px;
  font-family: "Graphik Bold";
}

@media only screen and (max-width: 576px) {
  .NewCopyTradePageCM .PageTitle {
    padding-left: 20px;
  }
}