.dashboardStatusBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainDashboardPage .minddleBorder {
  width: 1px;
  height: 100%;
  background-color: #f0f2f2;
}

.padingTop {
  padding-top: 65px;
}

.dashboardStatusBox {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mainDashboardPage .minddleBorder {
  width: 1px;
  height: 100%;
  background-color: #f0f2f2;
}

.padingTop {
  padding-top: 65px;
}

.balance-warning {
  background-color: #fff1f1;
  border-left: 4px solid #ff4646;
  padding: 8px 12px;
  margin: 8px 0;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
}

.warning-icon {
  color: #ff4646;
  font-size: 16px;
}

.warning-text {
  color: #dc3545;
  font-size: 14px;
  font-weight: 500;
}
