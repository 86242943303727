.messageModal .modal-content {
  border-radius: 10px;
}

.messageModal .modal-body {
  height: 647px;
  padding: 34px 27.6px 54px 27.4px;
}

.messageModal .close-btn {
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 26.5px;
  top: 26.6px;
}

.messageModal .ModalTitle {
  color: #06152b;
  font-family: "Graphik Medium";
  font-size: 16px;
  margin-bottom: 25px;
  text-align: left;
}

.messageModal .mb-30px {
  margin-bottom: 30px;
}

.messageModal .selectedEmail {
  background-color: #f7f7f7;
  border-radius: 10px;
  border: none;
  color: #9a9b9b;
  height: 55px;
  padding: 0 1rem;
  width: 50%;
}

.selectMembers {
  margin-bottom: 27px;
  width: 34%;
}

.selectMembers .dropdown .dropdown-btn {
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 10px;
  border: 1px solid transparent;
  display: flex;
  font-family: "Graphik Medium";
  justify-content: space-between;
  min-height: 55px;
  padding: 10px 20px;
  width: 100%;
}

.selectMembers .dropdown .dropdown-btn:focus {
  background-color: #fff;
  border-color: #11b700;
}

.selectMembers .dropdown .dropdown-btn .arrow {
  opacity: 0.4;
  transition: transform 0.3s ease-in-out;
}

.selectMembers .dropdown .dropdown-btn .arrow.rotate {
  opacity: 1;
}

.selectMembers .dropdown .dropdown-menu {
  background: #ffffff;
  border-color: #d6d6d6;
  border-style: solid;
  border-width: 0.5px;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
  left: -10px;
  padding: 20px 36px 20px 36px;
  right: unset;
  top: 60px;
  width: 250px;
}

.selectMembers .dropdown .dropdown-menu li {
  transition: 0.3s all ease;
}

.selectMembers .dropdown .dropdown-menu li button {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: #06152b;
  display: flex;
  font-family: "Graphik Regular";
  font-size: 14px;
  justify-content: space-between;
  padding: 15px 12px 15px 0;
  transition: 0.3s all ease;
  width: 100%;
}

.selectMembers .dropdown .dropdown-menu li button:hover {
  font-family: "Graphik Medium";
  padding: 15px 0 15px 0;
  transition: 0.3s all ease;
}

.messageModal .messageInput {
  background-color: #f7f7f7;
  border-radius: 10px;
  border: none !important;
  box-shadow: none !important;
  min-height: 256px;
  padding: 0;
  resize: none;
}

.messageModal .messageInput textarea {
  background-color: #f7f7f7;
  font-family: "Graphik Medium";
  min-height: 265px;
  resize: none;
}

.messageModal .messageInput label::after {
  background-color: #f7f7f7 !important;
  color: #9a9b9b;
  font-family: "Graphik Medium";
}

.messageModal .messageInput label {
  color: #9a9b9b;
}

.messageModal .subjectInput input {
  background-color: #f7f7f7;
}

.messageModal .subjectInput {
  background-color: #f7f7f7;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  height: 55px;
  margin-bottom: 28px;
  width: 50%;
}

.messageModal .SendBtn {
  background-color: #000;
  border-radius: 12px;
  border: 0;
  color: #fff;
  float: right;
  font-family: "Graphik Medium";
  font-size: 14px;
  font-style: normal;
  height: 55px;
  padding: 10px 30px;
  width: 215px;
}

.messageModal .SendBtn:active,
.messageModal .SendBtn:hover {
  background-color: #10b700 !important;
}

@media only screen and (min-width: 1440px) {
  .selectMembers,
  .messageModal .selectedEmail {
    width: 215px;
  }

  .messageModal .subjectInput {
    width: 325px;
  }

  .messageModal .modal-dialog {
    max-width: 632px;
  }

  .messageModal .modal-content {
    min-height: 647px;
  }
}

@media only screen and (min-width: 992px) {
  .messageModal .modal-dialog {
    max-width: 632px;
  }
}

@media only screen and (max-width: 992px) {
  .selectMembers,
  .messageModal .selectedEmail {
    width: 50%;
  }

  .messageModal .subjectInput {
    width: 70%;
  }
}

@media only screen and (max-width: 768px) {
  .selectMembers,
  .messageModal .selectedEmail {
    width: 100%;
  }
}
