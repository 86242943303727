.exchangeBox {
  min-height: 300px;
  background-color: #fff;
  /* padding: 24px 299.3px 49.1px 46px; */
  padding: 28.4px 41px 39.1px 46px;
  width: 100%;
  border-radius: 10px;
  /* background-image: url("../../../../assets//images/exchnageBall.png"); */
  background-repeat: no-repeat;
  background-position: 105% 32px;
  background-size: 420px;
  margin-bottom: 29.2px;
}

.exchangeBox .mb-16px {
  margin-bottom: 16px;
}

.exchangeBox .title {
  font-family: "Graphik Bold";
  font-size: 20px;
  line-height: normal;
  font-style: normal;
  text-align: left;
  color: #06152b;
  margin-bottom: 8.2px;
}

.exchangeBox .subTitle {
  font-family: "Graphik Medium";
  font-size: 16px;
  color: #06152b;
  margin-bottom: 40.3px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
}

.exchangeBox .CopyItCustomInput {
  box-shadow: none;
}

.exchangeBox .connectionBtn {
  width: 100%;
  padding: 10px 30px;
  height: 55px;
  background-color: #10b700;
  color: #fff;
  font-family: "Graphik Medium";
  font-size: 14px;
  font-style: normal;
  text-align: center;
  color: #ffffff;
  border-radius: 12px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.exchangeBox .connectionBtn:hover {
  background-color: #000;
}

.exchangeBox .connectionBtn.dissconnect {
  background-color: #ff0000;
}

.exchangeBox .connectionBtn.DelApi {
  background-color: #000;
}

.exchangeBox .connectionBtn.DelApi:hover {
  background-color: #000;
}

.exchangeBox .disabledInput {
  background-color: #f7f7f7 !important;
}

.customDropdown {
  position: relative;
  display: inline-block;
  width: 100%;
}

.dropdownButton {
  min-height: 55px;
  border-radius: 12px;
  background-color: #f7f7f7;
  border: 2px solid;
  border-color: transparent;
  padding: 18px 14.3px 18px 19.8px;
  font-size: 14px;
  cursor: pointer;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Graphik Medium";
}

.dropdownButton p .btnSpanOne {
  font-size: 14px;
  font-family: "Graphik Medium";
  line-height: 14px;
  width: 60px;
  color: #000;
  opacity: 1;
  white-space: nowrap;
  margin: 0;
}

.dropdownButton.active {
  border: 2px solid #11b700;
}

.dropdownButton .btnSpan {
  font-size: 10px;
  font-family: "Graphik Medium";
  margin-left: 20.7px;
}

.dropdownButton p {
  display: flex;
  align-items: center;
  justify-content: start;
}

.dropdownButton p span {
  font-family: "Graphik Medium";
  text-align: left;
  color: #06152b;
  margin-left: 20px;
  opacity: 0.5;
}

.dropdownButton p .span1 {
  font-family: "Graphik Medium";
  font-size: 14px;
  text-align: left;
  color: #06152b;
  width: 60px;
  display: block;
  margin-left: 0;
  opacity: 1;
}

.dropdownArrow {
  opacity: 0.4;
  transform: rotate(0deg);
  transition: transform 0.3s ease;
}

.rotate {
  opacity: 1;
  transform: rotate(180deg);
}

.dropdownContent {
  background-color: #fff;
  background: #ffffff;
  border-color: #d6d6d6;
  border-radius: 10px;
  border-style: solid;
  border-width: 0.5px;
  border: 1px solid #ccc;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  display: block;
  filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.1));
  padding: 31px 0 31px 0;
  position: absolute;
  width: 250px;
  z-index: 3;
}

.dropdownContent p {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: start;
  margin: 0;
  padding: 11px 48.1px 11px 31.1px;
  transition: 0.3s all ease;
  width: 100%;
}

.dropdownContent p span {
  color: #06152b;
  font-family: "Graphik Medium";
  margin-left: 14px;
  opacity: 0.5;
  text-align: left;
}

.dropdownContent p .span1 {
  color: #06152b;
  display: block;
  font-family: "Graphik Medium";
  font-size: 14px;
  margin-left: 0;
  opacity: 1;
  text-align: left;
  width: 60px;
}

.dropdownContent p .arrowspan {
  margin-left: auto;
  opacity: 1;
}

.dropdownContent p:hover {
  padding: 11px 28.1px 11px 31.1px;
}

.dropdownContent p:hover .span1 {
  font-family: "Graphik Bold";
}

.copyIpBox {
  position: relative;
}

.copyIpBox .ipFiled input {
  opacity: 0.4;
}

.copyIpBox .copyIp {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

.opacity40 {
  opacity: 0.4 !important;
}

/* @media only screen and (min-width: 1440px) {
  .exchangeBox {
    background-position: 106% 32px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1439px) {
  .exchangeBox {
    background-position: 111% 32px;
    padding-right: 240px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1199px) {
  .exchangeBox {
    background-position: 111% 32px;
    padding-right: 140px;
  }
} */

/* @media only screen and (min-width: 945px) and (max-width: 999px) {
  .exchangeBox {
    background-position: 115% 32px;
    padding-right: 46px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 944px) {
  .exchangeBox {
    background-position: 120% 32px;
    padding-right: 46px;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .exchangeBox {
    background-position: 140% 32px;
    padding-right: 46px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 575px) {
  .exchangeBox {
    background-position: 180% 32px;
    padding-right: 40px;
  }
} */

/* @media only screen and (min-width: 440px) and (max-width: 479px) {
  .exchangeBox {
    background-position: 154% 96px;
    background-size: 315px;
    padding-right: 40px;
  }
}

@media only screen and (max-width: 440px) {
  .exchangeBox {
    background-position: 150px 108px;
    background-size: 300px;
    padding-right: 40px;
  }
} */

@media only screen and (max-width: 576px) {
  .exchangeBox {
    padding: 32px 20px 40px 20px;
  }
}
