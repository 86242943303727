.profile-dropdown {
  display: inline-block;
  position: relative;
}

.profile-dropdown .dropdown-toggle {
  align-items: center;
  background-color: transparent;
  border-radius: 50rem;
  border: none;
  cursor: pointer;
  display: flex;
  height: 38px;
  justify-content: center;
  transition: 0.3s all ease;
  width: 38px;
}

.profile-dropdown .dropdown-toggle svg g path {
  transition: 0.3s all ease;
}

.profile-dropdown .dropdown-toggle:hover,
.profile-dropdown .dropdown-toggle.active {
  background-color: #11b700;
}

.profile-dropdown .dropdown-toggle:hover svg g path,
.profile-dropdown .dropdown-toggle.active svg g path {
  fill: #fff !important;
}

.profile-dropdown .dropdown-menu {
  background-color: white;
  background: #ffffff;
  border-color: #d6d6d6;
  border-style: solid;
  border-width: 0.5px;
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
  box-shadow: none;
  list-style: none;
  margin: 0;
  padding: 23px 0 26px 0;
  position: absolute;
  right: 0;
  top: 48px;
  width: 250px;
  z-index: 1000;
}

.profile-dropdown .dropdown-menu li {
  align-items: center;
  color: #06152b;
  cursor: pointer;
  display: flex;
  font-family: "Graphik Medium";
  font-size: 14px;
  justify-content: space-between;
  padding: 11px 48.1px 11px 36px;
  text-align: left;
  transition: 0.3s all ease;
}

.profile-dropdown .dropdown-menu li.signoutLink {
  color: #f50202;
}

.profile-dropdown .dropdown-menu li.copuurlLink {
  gap: 20px;
  justify-content: start;
}

.profile-dropdown .dropdown-menu li.copuurlLink img {
  width: 20px;
  height: 20px;
}

.profile-dropdown .dropdown-menu li:hover {
  background-color: #fff;
  font-family: "Graphik Bold";
  padding: 11px 28.1px 11px 36px;
}

@media screen and (max-width: 600px) {
  .profile-dropdown .dropdown-toggle:hover {
    background-color: transparent;
  }

  .profile-dropdown .dropdown-toggle.active {
    background-color: #11b700;
  }

  .profile-dropdown .dropdown-toggle:hover svg g path {
    fill: #06152b !important;
  }

  .profile-dropdown .dropdown-toggle.active svg g path {
    fill: #fff !important;
  }
}