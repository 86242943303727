.SearchContainer div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  animation: ani cubic-bezier(0.68, -0.55, 0.27, 1.55) 800ms forwards;
  /* overflow: hidden; */
}

.SearchContainer div svg {
  position: absolute;
  display: flex;
}

.SearchContainer div svg.hidden {
  display: none;
}

.SearchContainer div input {
  max-width: 500px;
  width: 40px;
  max-height: 55px;
  height: 55px;
  min-height: 55px;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  color: #232323;
  font-family: "Graphik Medium";
  font-size: 14px;
  cursor: pointer;
  transition: all cubic-bezier(0.68, -0.55, 0.27, 1.55) 520ms;
  /* Moved here */
  outline: none;
  /* Moved here */
  background-color: #f7f7f7;
}

.SearchContainer div input::placeholder {
  color: rgb(154, 155, 155);
  font-size: 14px;
}

.SearchContainer div input:not(:focus) {
  width: 55px;
  border-radius: 50px;
  background-color: #f7f7f7;
}

.SearchContainer div svg {
  background-color: #f7f7f7;
  transition: all cubic-bezier(0.68, -0.55, 0.27, 1.55) 520ms;
  /* Added for SVG animation */
  cursor: pointer;
}

@keyframes ani {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}