.accountDetailBox {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 28.4px 41px 44.6px 46px;
  min-height: 300px;
}

.accountDetailBox .headingBox .title {
  color: #000000;
  font-family: "Graphik Bold";
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 8.2px;
}

.accountDetailBox .headingBox .subtitle {
  align-items: center;
  color: #06152b;
  display: flex;
  font-family: "Graphik Regular";
  font-size: 16px;
  gap: 10px;
  margin-bottom: 30px;
  width: fit-content;
}

.accountDetailBox .headingBox .subtitle .infoIcon {
  cursor: pointer;
}

.accountDetailBox .headingBox .subtitle img {
  height: 20.9px;
  object-fit: contain;
  object-position: center center;
  width: 20.9px;
}

.accountDetailBox .infoPopupContainer .infoPopup button img {
  height: 9px;
  width: 9px;
}

.accountDetailBox .editable-field {
  align-items: center;
  display: flex;
  margin-bottom: 30px;
  position: relative;
}

.accountDetailBox .editable-field .accdetailinputbox {
  background-color: #f7f7f7;
  box-shadow: none;
  width: 100%;
}

.accountDetailBox .editable-field .accdetailinputbox input {
  background-color: transparent;
}

.accountDetailBox .editable-field .edit-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.accountDetailBox .editable-field .edit-icon img {
  height: 14px;
  object-fit: contain;
  width: 14px;
}

.accountDetailBox .editable-field .eye-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translate(-50%, -50%);
}

.accountDetailBox .editable-field .eye-icon img {
  height: 16px;
  object-fit: contain;
  width: 20px;
}

.accountDetailBox .accdetailinputbox:has(input:disabled) .form-floating,
.accountDetailBox:has(input:disabled) .edit-icon,
.accountDetailBox:has(input:disabled) .eye-icon {
  opacity: 0.4;
}

.accountDetailBox:has(input:focus) .edit-icon,
.accountDetailBox:has(input:focus) .eye-icon {
  opacity: 1;
}

.accountDetailBox .accdetailinputbox:has(input:disabled) .form-floating>.form-control:not(:placeholder-shown)~label {
  color: #000;
}

.accountDetailBox .editable-field.copyField .accdetailinputbox {
  background: transparent;
}

.accountDetailBox .editable-field.copyField .edit-icon img {
  height: 18px;
  width: 18px;
}

.accountDetailBox .editable-field.copyField .CopyItCustomInput .form-floating>.form-control:not(:placeholder-shown)~label {
  color: #9a9b9b;
}

.accountDetailBox .editable-field.copyField label {
  color: #000 !important;
}

.accountDetailBox .editable-field.copyField input {
  padding-right: 24px;
}

.accountDetailBox .editable-field.copyField .edit-icon {
  opacity: 0.4;
  transition: 0.3s all ease;
}

.accountDetailBox .editable-field.copyField .edit-icon:hover {
  opacity: 1;
}

.accountDetailBox .submitbtn {
  align-items: center;
  background-color: #11b700;
  border-radius: 12px;
  border: 1px solid #11b700;
  color: #fff;
  display: flex;
  font-family: "Graphik Medium";
  font-size: 14px;
  height: 55px;
  justify-content: center;
  padding: 10px 30px;
  width: 215px;
  transition: 0.3s all ease;
  /* margin-bottom: 30px; */
}

.accountDetailBox .submitbtn:hover {
  background-color: #000;
  border-color: #000;
}

@media only screen and (min-width: 1440px) {
  .fixedWidth1440-298 {
    width: 298px !important;
  }
}

@media only screen and (max-width: 768px) {
  .accountDetailBox .submitbtn {
    margin-bottom: 30px;
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .accountDetailBox {
    padding: 32px 20px 32px 20px;
  }

  .accountDetailBox .headingBox .title {
    font-size: 16px;
    font-family: "Graphik Medium";
  }

  .accountDetailBox .headingBox .subtitle {
    font-size: 14px;
  }

  .accountDetailBox .editable-field {
    margin-bottom: 23px;
  }
}