.pagination-container {
    display: flex;
    justify-content: end;
    align-items: center;
    background: transparent;
}

.pagination {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 10px;
    /* Spacing between items */
}

.pagination-item {
    width: 42px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    color: #000;
    font-family: Arial, sans-serif;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    user-select: none;
}

.pagination-item:hover:not(.disabled) {
    background: #f1f5f9;
}

.pagination-item.active {
    background: #f1f5f9;
}

.pagination-item.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

/* Arrow styling */
.pagination-item span {
    font-size: 20px;
}

.pagination-item:first-child,
.pagination-item:last-child {
    background-color: #f1f5f9;
    border-radius: 50rem;
    width: 42px;
    height: 42px;
}

.angleLeft {
    rotate: 180deg;
}




.pagination-item.ellipsis {
    cursor: default;
    background: none;
    transform: none;
}

@media (max-width: 768px) {
    .pagination {
        gap: 5px;
        /* Reduce gap for smaller screens */
    }

    .pagination-item {
        width: 35px;
        /* Slightly smaller buttons */
        height: 35px;
        font-size: 14px;
    }

    .pagination-item span {
        font-size: 18px;
        /* Slightly smaller arrows */
    }

    /* Hide all page numbers except active, ellipsis, and last page */
    .pagination-item:not(.active):not(.ellipsis):not(.disabled) {
        display: none;
    }

    /* Ensure the last page is visible when it follows the ellipsis */
    .pagination-item.ellipsis+.pagination-item {
        display: flex;
    }
}