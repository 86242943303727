.subscriptionBox {
    background-color: #dfe3ea;
    padding: 28.4px 41px 39.1px 46px;
    border-radius: 10px;
    margin-bottom: 29.8px;
    min-height: 300px;
}

.subscriptionBox .mb-30px {
    margin-bottom: 30px;
}

.subscriptionBox .title {
    font-family: "Graphik Bold";
    font-size: 20px;
    line-height: normal;
    font-style: normal;
    text-align: left;
    color: #06152b;
    margin-bottom: 8.2px;

}

.subscriptionBox .subTitle {
    font-family: "Graphik Medium";
    font-size: 16px;
    color: #06152b;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
}

.subscriptionBox .subTitle .infoIcon {
    cursor: pointer;
}

.subscriptionBox .subTitle img {
    width: 20.9px;
    height: 20.9px;
    object-fit: contain;
    object-position: center center;
}


.subscriptionBox .AddTierBtn {
    width: 100%;
    padding: 10px 30px;
    height: 55px;
    background-color: #10b700;
    color: #fff;
    font-family: "Graphik Medium";
    font-size: 14px;
    font-style: normal;
    text-align: center;
    color: #ffffff;
    border-radius: 12px;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.subscriptionBox .AddTierBtn:hover,
.subscriptionBox .AddTierBtn:active,
.subscriptionBox .AddTierBtn:focus-visible {
    background-color: #000 !important;
    color: #fff !important;
    box-shadow: none;
}

.subscriptionBox .inputBottomText {
    font-family: "Graphik Regular";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #06152b;
    margin-bottom: 0;
    margin-top: 40.5px;
}

.subscriptionBox .inputBottomText .BoldText {
    font-family: "Graphik Bold";
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    text-align: left;
    color: #06152b;
}


.select-trial-dropdown-container {
    margin-left: auto;
    width: fit-content;
    cursor: pointer;
    position: relative;
    margin-top: 40.5px;
}

.select-trial-dropdown-container .select-trial-button {
    /* padding: 0 10px; */
    background-color: transparent;
    border: none;
    gap: 11px;
    display: flex;
    align-items: center;
    justify-content: start;
}

.select-trial-dropdown-container .select-trial-button span {
    font-family: "Graphik Medium";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    text-align: left;
    color: #06152b;
}

.select-trial-dropdown-container .select-trial-button .dropdown-arrow {
    position: relative;
    top: -1px;
    transition: transform 0.3s ease;
}

/* .select-trial-dropdown-container .select-trial-button.open .dropdown-arrow {
    transform: rotate(180deg);
} */

.select-trial-dropdown-container .custom-dropdown {
    background-color: #fff;
    width: 250px;
    height: 236px;
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid #ccc;
    filter: drop-shadow(0 10px 10px rgba(0, 0, 0, 0.1));
    z-index: 10;
    padding: 22px 0 30px 0;
    border-radius: 10px;
}

.select-trial-dropdown-container .custom-dropdown ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.select-trial-dropdown-container .custom-dropdown li {
    cursor: pointer;
}

.select-trial-dropdown-container .custom-dropdown li button {
    font-family: "Graphik Medium";
    font-size: 14px;
    font-weight: 500;
    font-style: normal;
    text-align: left;
    color: #06152b;
    width: 100%;
    background-color: transparent;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 11px 48.1px 11px 36px;
    transition: 0.3s all ease;
}

.select-trial-dropdown-container .custom-dropdown li button:hover,
.select-trial-dropdown-container .custom-dropdown li button.active {
    font-family: "Graphik Bold";
    padding: 11px 28.1px 11px 36px;
}

.select-trial-dropdown-container-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.select-trial-dropdown-container .infoSpan {
    position: relative;
    top: -1px;
}

.select-trial-dropdown-container .infoSpan div {
    left: unset;
    right: 0;
}

@media only screen and (min-width: 992px) and (max-width: 1160px) {
    .subscriptionBox .form-floating>label {
        font-size: 10px;
    }
}

@media only screen and (max-width: 992px) {
    .addMembeBox {
        padding: 60px 40px 88px 40px;
    }
}

@media only screen and (max-width: 768px) {
    .select-trial-dropdown-container {
        margin-bottom: 20px;
    }
}

@media only screen and (max-width: 576px) {
    .subscriptionBox {
        padding: 32px 20px 50px 20px;
    }

    .addMembeBox {
        padding: 60px 20px 60px 20px;
    }

    .infoPopup {
        left: 0;
    }

    .addMembeBox {
        margin-bottom: 30px;
    }

    #addMemberModal .modalContent {
        padding: 40px 10px;
        gap: 0;
    }

    #addMemberModal .modalContent .content-box p {
        font-size: 12px;
    }

    #addMemberModal .modalContent .img-box img {
        width: 80px;
    }

    /* .subscriptionBox .subTitle {
        gap: 5px;
        font-size: 15px;
    } */

    /* Main Page  Heading */
    .PageHeading h2 {
        padding-left: 20px;
    }
}