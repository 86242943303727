.addVipMemberBox {
  background-color: #dfe3ea;
  border-radius: 10px;
  margin-bottom: 30.6px;
  min-height: 220px;
  padding: 29.9px 37.3px 53px 46px;
}

.addVipMemberBox .title,
.addVipMemberBox .title span {
  align-items: center;
  color: #06152b;
  display: flex;
  font-family: "Graphik Medium";
  font-size: 16px;
  font-style: normal;
  gap: 5px;
  line-height: normal;
  text-align: left;
  width: fit-content;
}

.addVipMemberBox .title span {
  width: fit-content;
}

.addVipMemberBox .mb-31px {
  margin-bottom: 31px;
}

.VipToggleBox {
  margin-left: auto;
  width: fit-content;
}

.VipToggleBox .VipToggle {
  align-items: center;
  background-color: #ededed;
  border-radius: 50rem;
  display: flex;
  gap: 4px;
  min-height: 31.11px;
  min-width: 168.97px;
  padding: 4px 7px;
}

.VipToggleBox .VipToggle button {
  align-items: center;
  background-color: #000;
  border-radius: 50rem;
  border: none;
  color: #fff;
  display: flex;
  font-size: 12px;
  height: 23px;
  justify-content: center;
  padding: 3px 3px 0px 3px;
  text-align: center;
  width: 75px;
}

.VipToggleBox .VipToggle button.on {
  background-color: transparent;
  color: #000;
}

.VipToggleBox .VipToggle button.on.active {
  background-color: #10b700;
  color: #fff;
}

.VipToggleBox .VipToggle button.off.inactive {
  background-color: transparent;
  color: #000;
}

.accessTimeDropDown .dropdownContent {
  padding: 22px 0 35px 0 !important;
}

.addVipMemberBox .memberDropDown {
  align-items: center !important;
  background-color: #f7f7f7;
  border-color: transparent;
  border-radius: 12px;
  border: 1px solid transparent;
  color: #000 !important;
  display: flex !important;
  font-family: "Graphik Medium" !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  justify-content: start !important;
  line-height: 22px !important;
  min-height: 55px;
  overflow: hidden;
  padding: 12px 14px;
  text-align: left !important;
  width: 100% !important;
}

.addVipMemberBox .memberDropDown:hover {
  background-color: #f7f7f7;
}

.addVipMemberBox .memberDropDown:active,
.addVipMemberBox .memberDropDown:focus,
.addVipMemberBox .memberDropDown:focus-visible {
  background-color: white !important;
  border-color: #10b700 !important;
  outline: 0;
}

.addVipMemberBox .dropdown .memberDropDown {
  align-items: center !important;
  color: #9a9b9b !important;
  display: flex !important;
  justify-content: space-between !important;
  width: 100% !important;
  z-index: 0;
}

.addVipMemberBox .dropdown .memberDropDown:hover {
  border-color: transparent;
}

.addVipMemberBox .dropdown .memberDropDown:disabled,
.addVipMemberBox .dropdown .memberDropDown.show {
  background-color: #f7f7f7 !important;
  border-color: transparent;
}

.addVipMemberBox .dropdown .memberDropDown:focus-visible {
  box-shadow: none;
}

.addVipMemberBox .dropdown .memberDropDown.selected {
  color: #000 !important;
}

.addVipMemberBox .dropdown .dropdown-menu {
  left: -10px;
  padding: 10px 0;
  right: unset;
  top: 60px;
  width: 250px;
}

.addVipMemberBox .dropdown .dropdown-item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px 30px 20px 20px;
}

.addVipMemberBox .dropdown .dropdown-item span {
  color: #06152b;
  font-family: "Graphik Regular";
  font-size: 14px;
  font-style: normal;
  line-height: normal;
  text-align: left;
}

.mamberDateSelectBox .react-datepicker-wrapper {
  width: 100%;
}

.addVipMemberBox .AddMemberButton {
  align-items: center;
  background-color: #000;
  border-radius: 12px;
  border: 0;
  color: #fff;
  color: #ffffff;
  display: flex;
  font-family: "Graphik Medium";
  font-size: 14px;
  font-style: normal;
  height: 55px;
  justify-content: center;
  padding: 10px 30px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
}

.addVipMemberBox .AddMemberButton:focus-visible {
  box-shadow: 0;
}

.addVipMemberBox .AddMemberButton:hover,
.addVipMemberBox .AddMemberButton:active {
  background-color: #10b700 !important;
}

#addMemberModall {
  filter: drop-shadow(0px 10px 5px rgba(0, 0, 0, 0.1));
  width: 342px;
}

#addMemberModall .modal-content {
  background: #ffffff;
  border-color: #d6d6d6;
  border-style: solid;
  border-width: 0.5px;
  margin-top: 100px;
}

#addMemberModall .modal-body {
  padding: 0;
}

#addMemberModall .modalContent {
  align-items: center;
  display: block;
  justify-content: start;
  padding: 0;
  position: relative;
}

#addMemberModall .modalContent .regBox {
  align-items: center;
  display: flex;
  justify-content: start;
  padding: 42px 30px 36px 5px;
}

#addMemberModall .modalContent .regBox.success {
  align-items: center;
  display: flex;
  justify-content: start;
  overflow: hidden;
  padding: 42px 30px 36px 0px !important;
}

#addMemberModall .modalContent .regBox.success .img-box {
  width: 100px !important;
}

#addMemberModall .modalContent .regBox.success .img-box img {
  height: 100px !important;
  left: -15px !important;
  object-fit: contain !important;
  position: relative !important;
}

#addMemberModall .modalContent .closeBtn {
  background-color: transparent;
  border: none;
  height: auto;
  padding: 0 !important;
  position: absolute;
  right: 10px;
  top: 10px;
  width: fit-content;
}

#addMemberModall .modalContent .closeBtn img {
  height: 15px;
  width: 15px;
}

#addMemberModall .modalContent .content-box p {
  color: #06152b;
  font-family: "Graphik Regular";
  font-size: 14px;
  font-style: normal;
  text-align: left;
}

#addMemberModall .modalContent .content-box p span {
  color: #06152b;
  font-family: "Graphik Medium";
  font-size: 14px;
  font-style: normal;
  text-align: left;
}

#addMemberModall .modalContent .LoadingBox {
  align-items: center;
  display: flex;
  height: 176px;
  justify-content: center;
  text-align: center;
}

#addMemberModall .modalContent .LoadingBox img {
  height: 80px;
}

.addVipMemberBox .mb-20px {
  margin-bottom: 20px;
}

.mamberDateSelectBox .react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 12.4px !important;
}

.mamberDateSelectBox .react-datepicker-popper .calendarContainer {
  background: #fff;
  border-radius: 10px;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.1));
  padding: 12.4px 11px 16.6px;
}

.mamberDateSelectBox
  .react-datepicker-popper
  .calendarContainer
  .react-datepicker {
  border: none !important;
}

.mamberDateSelectBox
  .react-datepicker-popper
  .calendarContainer
  .react-datepicker
  .react-datepicker__month-container {
  width: 234px;
}

.mamberDateSelectBox
  .react-datepicker__input-container
  .react-datepicker__calendar-icon {
  height: unset;
  opacity: 0.4;
  padding: 0;
  right: 14px;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: transform 0.3s ease;
  width: unset;
}

.mamberDateSelectBox
  .react-datepicker__input-container:focus-within
  .react-datepicker__calendar-icon {
  opacity: 1;
  transform: translate(-50%, -50%) rotate(180deg);
}

@media only screen and (min-width: 1440px) {
  .fixedWidth1440 {
    width: 215px !important;
  }
}

@media only screen and (max-width: 992px) {
  .VipMemberEmail,
  .addVipMemberBox .dropdown .memberDropDown,
  .addVipMemberBox .mamberDateSelectBox {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .VipMemberEmail,
  .addVipMemberBox .dropdown .memberDropDown,
  .addVipMemberBox .mamberDateSelectBox {
    margin-bottom: 20px;
  }

  .VipToggleBox {
    margin-top: 30px;
    /* margin: 0 auto; */
  }

  .VipToggleBox .VipToggle {
    min-width: 108.97px;
  }

  .VipToggleBox .VipToggle button {
    width: 55.03px;
  }
}

@media only screen and (max-width: 576px) {
  #addMemberModall {
    margin: 0 auto;
  }

  #addMemberModall .modalContent {
    gap: 0;
  }

  #addMemberModall .modalContent .regBox {
    padding: 40px 10px;
  }

  #addMemberModall .modalContent .content-box p {
    font-size: 12px;
  }

  #addMemberModall .modalContent .img-box img {
    width: 80px;
  }

  .VipToggleBox .VipToggle {
    width: 108.97px;
  }

  .VipToggleBox .VipToggle button {
    padding: 4px 20px 3px 20px;
    /* width: auto; */
  }

  .addVipMemberBox {
    padding: 30px 20px 50px 20px;
  }

  .addVipMemberBox .title span {
    font-size: 16px;
  }

  .memberStatBox {
    padding-left: 30px !important;
    padding-right: 20px !important;
  }
}

@media only screen and (max-width: 385px) {
  .VipToggleBox .VipToggle button {
    padding: 4px 10px 3px 10px;
    /* width: auto; */
  }
}

@media only screen and (max-width: 360px) {
  #addMemberModall {
    margin: 0 auto;
    width: 95%;
  }
}

@media only screen and (max-width: 458px) {
  .addVipMemberBox .title span {
    width: min-content;
  }
}
